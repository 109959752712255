import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import Button from "reactstrap/lib/Button";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";
import { ISelectOption } from "../../FormFields/SelectField";
import IPropertyConfigProps from "../../Property/IPropertyConfigProps";

const initialOption: ISelectOption = { label: '', value: '' };

const OptionsField = (props: IPropertyConfigProps) => {

    const dispatch = useFormDispatch();
    const [options, setOptions] = useState(props.value);

    useEffect(() => {
        dispatch({
            type  : actionTypes.propertyChange,
            params: {
                propertyId   : props.id,
                propertyGroup: props.group,
                value        : options
            }
        });
    }, [options]);

    const onChange = (optionName, index, newValue) => {
        const optionsCopy = cloneDeep(options);
        optionsCopy[index][optionName] = newValue;

        setOptions(optionsCopy);
    };

    const onRemoveOption = (index) => {
        const optionsCopy = cloneDeep(options);
        optionsCopy.splice(index, 1);

        setOptions(optionsCopy);
    };

    const onAddOption = () => {
        const optionsCopy = cloneDeep(options);
        optionsCopy.push(initialOption);

        setOptions(optionsCopy);
    };

    return (
        <>
            <FormGroup className="d-flex align-items-center">
                <label>Options</label>
                <Button onClick={() => onAddOption()}
                        className="ml-auto"
                        title="Add option"
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </FormGroup>

            {
                map(options, (option, index) => (
                    <div className="row mb-2" key={index}>
                        <div className="col col-sm-4">
                            <Input type="text"
                                   className="ml-1"
                                   name="value"
                                   width={50}
                                // placeholder="Value"
                                   onChange={(event) => onChange('value', index, event.target.value)}
                                   defaultValue={option.value}
                                   title="Value"
                            />
                        </div>
                        <div className="col col-sm-6">
                            <Input type="text"
                                   className="ml-1"
                                   name="label"
                                   placeholder="Label"
                                   onChange={(event) => onChange('label', index, event.target.value)}
                                   defaultValue={option.label}
                                   title="Label"
                            />
                        </div>
                        <div className="col col-sm-2 text-right">
                            <Button onClick={() => onRemoveOption(index)}
                                    className=" ml-1"
                                    title="Remove option"
                            >
                                <FontAwesomeIcon icon={faMinus} />
                            </Button>
                        </div>
                    </div>
                ))
            }
        </>

    );
};

export default OptionsField;
