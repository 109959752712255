import map from 'lodash/map';
import * as React from 'react';
import Input from 'reactstrap/lib/Input';

interface IFilterDropdownOption {
    id: string,
    name: any
}

interface IFilterDropdownProps {
    options: Array<IFilterDropdownOption>,
    value: string,
    onChange: Function
}

const FilterDropdown = (props: IFilterDropdownProps) => {
    const options = map(props.options, (status, index) =>
        <option key={index} value={status.id}>{status.name}</option>
    );

    return (
        <Input
            bsSize={'sm'}
            type={'select'}
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}>
            {options}
        </Input>
    );
};

export default FilterDropdown;
