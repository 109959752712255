import * as Yup from 'yup';
import { addMethod, StringSchema } from 'yup';
import zxcvbn from 'zxcvbn';

export function strengthChecker(this: StringSchema, message: string) {
    return this.test({
        message: message,
        name   : 'strength',
        test   : (password: string) => {
            if (!password) {
                return true;
            }

            return zxcvbn(password).score > 2;
        },
    });
}

addMethod(Yup.string, 'strength', strengthChecker);

export default Yup;
