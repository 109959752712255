import forIn from 'lodash/forIn';

const apiRoutes = {
    get_configs  : '/config/list',
    get_config   : '/config/{name}',
    save_config  : '/config/store',
    delete_config: '/config/{name}',

    get_all_pages: '/page/list',
    page_ds      : '/page/data-source',
    save_page    : '/page/store',
    get_page     : '/page/{id}',

    save_user           : '/user/store',
    user_ds             : '/user/data-source',
    get_user            : '/user/{id}',
    delete_user         : '/user/{id}',
    update_user         : '/user/{id}/update',
    user_change_password: '/user/{id}/set-password',

    app_user_ds             : '/app-user/data-source',
    get_app_user            : '/app-user/{id}',
    delete_app_user         : '/app-user/{id}',
    app_user_change_password: '/app-user/{id}/set-password',

    get_all_offices: '/office/list',
    office_ds      : '/office/data-source',
    save_office    : '/office/store',
    delete_office  : '/office/{id}',

    login_user: '/accounts/login',

    media_object_ds    : '/media-object/data-source',
    upload_media_object: '/media-object/store',
    delete_media_object: '/media-object/{id}',

    get_forms  : '/form/list',
    form_ds    : '/form/data-source',
    get_form   : '/form/{id}',
    create_form: '/form/store',
    delete_form: '/form/{id}',

    search_app_users : '/app-user/search',
    save_notification: '/notification-order/store',

    private_files_ds      : '/app-user/{id}/file/data-source',
    private_files_download: '/app-user/{userId}/file/{fileId}/download',
    private_files_upload  : '/file/store',
    private_files_delete  : '/file/{id}',
};

class HttpRouter {
    private url: string;

    public init(url: string) {
        this.url = url;
    }

    /**
     * Generates the URL for a route.
     *
     * @param {string} routeName
     * @param {Object.<string, string>} params
     * @return {string}
     */
    public generate(routeName: string, params?: {}) {
        const route = HttpRouter.getRoute(routeName);

        if (params !== undefined) {
            return this.url + HttpRouter.replaceParams(route, params);
        }

        if (route.indexOf('{', 0) > -1 || route.indexOf('}', 0) > -1) {
            throw new Error('Replacements for mandatory params not provided.');
        }

        return this.url + route;
    }

    /**
     * Returns a raw route object.
     *
     * @param {string} routeName
     * @return {string}
     */
    private static getRoute = (routeName: string) => {
        if (!apiRoutes.hasOwnProperty(routeName)) {
            throw new Error('HttpRouter.getRoute: route [' + routeName + '] not found!');
        }

        return (apiRoutes[routeName]);
    };

    private static replaceParams = (route: string, params?: {}) => {
        forIn(params, (value, index) => {
            route = route.replace(new RegExp('{' + index + '}', 'g'), value);
        });

        return route;
    };
}

export default new HttpRouter();
