import axios from 'axios';
import { IGridRequestParams } from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';

class MediaUploaderService {
    deleteMediaFile(fileId: number): Promise<any> {
        const url = HttpRouter.generate('delete_media_object', { id: fileId });

        return axios.delete(url);
    }

    mediaObjectDs(params: IGridRequestParams): Promise<any> {
        const url = HttpRouter.generate('media_object_ds');

        return axios.post(url, params);
    }

    uploadMediaFiles(data: FormData): Promise<any> {
        const url = HttpRouter.generate('upload_media_object');

        return axios.post(url, data);
    }
}

export default new MediaUploaderService();
