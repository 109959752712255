import React from 'react';
import LoginForm from '../Components/LoginForm';
import logo from '../images/logo.png';

const Login = () => {
    return (
        <div className="wrapper wrapper--login d-flex flex-column align-items-center justify-content-center">
            <figure className="d-flex flex-column align-items-center justify-content-center">
                <img src={logo}
                     alt="App-Constructor"
                     className="mr-2"
                     height="60"
                />
                <figcaption className="text-uppercase text-white">App-Constructor</figcaption>
            </figure>
            <LoginForm />
        </div>
    );
};

export default Login;
