import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

function buildRequest(notification) {
    const criteria = map(notification.filters, (filterValue, filterName) => {
        return {
            name: filterName, value: map(filterValue, 'id')
        };
    }).filter((item) => !isEmpty(item.value)); // filter falsy values

    const today = new Date();

    return {
        message  : notification.message,
        criteria : !isEmpty(criteria) ? criteria : null,
        sent     : false,
        dateAdded: today,
        dateSent : null
    };
}

export {buildRequest}
