import React from 'react';
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    WithGoogleMapProps,
    withScriptjs,
    WithScriptjsProps
} from 'react-google-maps';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

interface IProps extends WithGoogleMapProps, WithScriptjsProps {
    center: google.maps.LatLng;
    zoom: number;
}

const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

const MapComponent = withScriptjs(withGoogleMap((props: IProps) => {
    return (
        <GoogleMap
            center={props.center}
            zoom={props.zoom}
        >
            <Marker position={props.center} />
        </GoogleMap>
    );
}));

const GoogleMaps = (props: IProps) => {
    return (
        <MapComponent
            googleMapURL={googleMapURL}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            center={props.center}
            zoom={props.zoom}
        >
        </MapComponent>
    );
};

const Widget = DraggableWidget(GoogleMaps);

const GoogleMapWidget = (props) => {
    const coordinates = {
        lat: parseFloat(getValueForProperty(props.values, 'coordinates', 'latitude')),
        lng: parseFloat(getValueForProperty(props.values, 'coordinates', 'longitude'))
    };

    const zoom = parseInt(getValueForProperty(props.values, 'coordinates', 'zoom'));

    return (
        <Widget
            {...props.draggableWidgetProps}
            center={coordinates}
            zoom={zoom}
        />
    );
};

export default GoogleMapWidget;
