import React, { Dispatch, useReducer } from 'react';
import { reducer } from './FormReducer';
import { getWidgetDefaultValues } from './widgets';
import uuid from 'uuid/v4';

const buildSubmitButton = () => {
    const widgets = [];
    const defaultValues = getWidgetDefaultValues('button');
    widgets.push({ id: uuid(), type: 'button', values: defaultValues });

    return widgets;
};

export interface IStateContext {
    formWidgets: [],
    selectedValues: [],
    title: '',
    selectedWidget: null,
    selectedFieldset: null,
    selectedWidgetType: null,
    selectedFieldsetType: null,
    configPanelVisible: false
}

export const defaultState = {
    formWidgets         : buildSubmitButton(),
    selectedValues      : [],
    title               : '',
    selectedWidget      : null,
    selectedFieldset    : null,
    selectedWidgetType  : null,
    selectedFieldsetType: null,
    configPanelVisible  : false
};


const StateContext = React.createContext<any | null>(null);
const DispatchContext = React.createContext<Dispatch<any> | undefined>(undefined);

const FormProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export {
    DispatchContext,
    StateContext,
    FormProvider,
};
