import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const getDraggableStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
});

export default (WrappedComponent) => {
    const DraggableHoc = ({ draggableId, index, ...props }) => (
        <Draggable draggableId={draggableId} index={index}>
            {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
                <div
                    {...draggableProps}
                    {...dragHandleProps}
                    className={props.className}
                    ref={innerRef}
                    style={getDraggableStyle(
                        snapshot.isDragging,
                        draggableProps.style
                    )}
                >
                    <WrappedComponent {...props} />
                </div>
            )}
        </Draggable>
    );

    DraggableHoc.displayName = `DraggableHoc(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return DraggableHoc;
};
