import React from 'react';
import CustomInput from 'reactstrap/lib/CustomInput';
import FormGroup from 'reactstrap/lib/FormGroup';

const FormikSwitchInput = ({ field, form: { touched, errors }, ...props }) => (
    <FormGroup>
        <CustomInput
            defaultChecked={field.value}
            type="switch"
            invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props}
        />
    </FormGroup>
);

export default FormikSwitchInput;
