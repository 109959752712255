// @ts-ignore
import autoBind from 'react-autobind';
import isNull from 'lodash/isNull';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import React from 'react';
import CreateUserForm from './CreateUserForm';
import EditUserForm from './EditUserForm';
import IUser from '../../Core/User/IUser';

interface IProps {
    data: IUser;
    onClose: Function;
}

class FullnameModal extends React.Component<IProps> {
    protected onClose: Function;
    protected isNew: boolean;
    protected user: IUser;

    constructor(props: IProps) {
        super(props);
        autoBind(this);

        this.isNew = isNull(props.data);
        this.onClose = props.onClose;

        if (props.data) {
            this.user = props.data;
        }
    }

    render() {
        let form = null;

        if (this.isNew) {
            form = (
                <CreateUserForm
                    onCancel={() => this.onClose(false)}
                    onSuccess={() => this.onClose(true)}
                />
            );
        } else {
            form = (
                <EditUserForm
                    onCancel={() => this.onClose(false)}
                    onSuccess={() => this.onClose(true)}
                    formValues={this.user}
                />
            );
        }

        return (
            <>
                <Modal isOpen={true}>
                    <ModalHeader toggle={() => this.onClose(false)} tag="div">
                        {
                            this.isNew ? (
                                <h4 className="m-0">Create user</h4>
                            ) : (
                                <>
                                    <h4 className="m-0">Edit user</h4>
                                    <small>{this.user.email}</small>
                                </>
                            )
                        }
                    </ModalHeader>
                    <ModalBody>
                        {form}
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default FullnameModal;
