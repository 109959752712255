import React, { CSSProperties } from 'react';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

const Image = (props: { altText: string, imageStyle: CSSProperties, parentStyle: CSSProperties, src: string, width: string }) => {
    return (
        <div style={props.parentStyle}>
            <img alt={props.altText} src={props.src} width={props.width} style={props.imageStyle} />
        </div>
    );
};

const Widget = DraggableWidget(Image);

const ImageWidget = (props: any) => {
    const altText = getValueForProperty(props.values, 'accessibility', 'altText');
    const src = getValueForProperty(props.values, 'image', 'imageSource');
    const width = getValueForProperty(props.values, 'image', 'width') + '%';
    const parentStyle = {
        textAlign: getValueForProperty(props.values, 'image', 'alignment'),
    };
    const imageStyle = {
        margin: getValueForProperty(props.values, 'image', 'margin'),
    };

    return (
        <Widget
            {...props.draggableWidgetProps}
            altText={altText}
            imageStyle={imageStyle}
            parentStyle={parentStyle}
            src={src}
            width={width}
        />
    );
};

export default ImageWidget;
