import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from "./IField";

interface IInputField extends IField {
    label: string;
    name: string;
    style: Object;
    placeholder: string;
}

const InputField = (props: IInputField) => {
    return (
        <FormGroup style={props.style}>
            <Label for={props.id}>{props.label}</Label>
            <Input
                type={props.type}
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
            />
        </FormGroup>
    );
};

export default InputField;

