import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faShapes } from '@fortawesome/free-solid-svg-icons/faShapes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import map from 'lodash/map';
import React, { useState } from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import Row from 'reactstrap/lib/Row';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import IWidget from '../../Core/Form/IWidget';
import { widgets } from '../../Core/Form/widgets';
import DraggableHoc from '../DraggableHoc';
import DroppableHoc from '../DroppableHoc';
import Scrollbar from '../Scrollbar';
import { useFormDispatch, useFormState } from "../../Core/Form/useFormContext";
import { actionTypes } from "../../Core/Form/FormReducer";

const tabs = [
    {
        icon  : faCog,
        id    : 'formConfig',
        label : 'Form config',
        target: 'formConfig'
    },
    {
        icon  : faShapes,
        id    : 'widgets',
        label : 'Widgets',
        target: 'widgets'
    }
];

function Widget({ widget }: { widget: any }) {
    return <div className="widget mb-3">
        <FontAwesomeIcon icon={widget.icon} />
        <h6 className="m-0">{widget.label}</h6>
    </div>;
}

function WidgetsList(props: { widgets: IWidget[] }) {
    return <Row>
        {
            map(props.widgets, (widget, idx) => (
                <Col key={idx} className="text-center" xl={4}>
                    <DraggableInput
                        draggableId={widget.id}
                        index={idx}
                        widget={widget}
                    />
                </Col>
            ))
        }
    </Row>;
}

const DraggableInput = DraggableHoc(Widget);
const DroppableWidgets = DroppableHoc(WidgetsList);

const WidgetsPanel = () => {
    const [activeTab, setActiveTab] = useState('formConfig');

    const {
              name,
          } = useFormState();

    const dispatch = useFormDispatch();

    const toggle = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    };

    const onSetName = (newName) => {
        dispatch({
            type: actionTypes.nameChange, params: { name: newName }
        });
    };

    const widgetTabs = map(tabs, tab => (
        <NavItem className="widgets-panel__nav-item" key={tab.id}>
            <NavLink href="#"
                     className="widgets-panel__nav-link"
                     active={activeTab === tab.id}
                     id={tab.id}
                     onClick={() => {
                         toggle(tab.id);
                     }}>
                <FontAwesomeIcon icon={tab.icon} size="lg" />
            </NavLink>
            <UncontrolledTooltip target={tab.target}>{tab.label}</UncontrolledTooltip>
        </NavItem>
    ));

    return (
        <div className="widgets-panel">
            <Nav className="widgets-panel__nav" tabs fill>
                {widgetTabs}
            </Nav>
            <TabContent activeTab={activeTab} className="py-3 h-100">
                <TabPane tabId="formConfig">
                    <Container fluid className="h-100">
                        <FormGroup>
                            <Label for="title">Name</Label>
                            <Input
                                defaultValue={name}
                                type="text"
                                name="title"
                                maxLength={60}
                                onChange={(e) => onSetName(e.target.value)}
                                placeholder="Enter form name"
                                required
                            />
                        </FormGroup>
                    </Container>
                </TabPane>
                <TabPane tabId="widgets" className="h-100 tab-pane--with-scroll">
                    <Scrollbar noScrollX={true}>
                        <Container fluid className="h-100">
                            <DroppableWidgets
                                draggableId="widgets"
                                droppableId="form"
                                isDropDisabled={true}
                                index={0}
                                type="widget"
                                widgets={widgets}
                            />
                        </Container>
                    </Scrollbar>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default WidgetsPanel;
