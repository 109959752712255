import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import { IMenuItem } from '../../Core/Menu/IMenu';
import { deleteMenuItem, setCurrentMenuItem } from '../../Core/Menu/MenuActions';
import { useMenuDispatch } from '../../Core/Menu/useMenuContext';

const MenuItemButtons = ({ menuId, menuItem }: { menuId: string, menuItem: IMenuItem }) => {
    const dispatch = useMenuDispatch();

    const onDelete = () => {
        deleteMenuItem(dispatch, { menuId: menuId, parentId: menuItem.parentId, menuItemId: menuItem.id });
    };

    const onEdit = () => {
        setCurrentMenuItem(dispatch, { menuItem: menuItem });
    };

    return (
        <>
            <Button color="link" className="px-1" onClick={onEdit}>
                <FontAwesomeIcon icon="pencil-alt" className="text-secondary" />
            </Button>
            <Button color="link" className="px-1" onClick={onDelete}>
                <FontAwesomeIcon icon="trash-alt" className="text-danger" />
            </Button>
        </>
    )
};

export default MenuItemButtons;
