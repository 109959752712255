import IconPicker from 'Components/IconPicker';
import { Field, Form, Formik } from 'formik';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import React from 'react';
import { ReactstrapInput, ReactstrapSelect } from 'reactstrap-formik';
import Button from 'reactstrap/lib/Button';
import { IMenuItem } from '../../Core/Menu/IMenu';
import Yup from '../../Core/YupValidator';

const UrlTypes = [
    { id: '_system', name: 'External' },
    { id: '_self', name: 'Internal' },
];

const editMenuItemSchema = Yup.object().shape({
    icon  : Yup.object()
               .shape({
                   prefix  : Yup.string()
                                .required('Required'),
                   iconName: Yup.string()
                                .required('Required'),
               })
               .required('Required'),
    label : Yup.string()
               .min(2, 'The field must have min. 2 characters!')
               .max(60, 'The field must have max. 60 characters!')
               .required('Required'),
    target:
        Yup.string()
           // IONUT - fix this later
           // .oneOf(keys(keyBy(UrlTypes, 'id')))
           .required('Required'),
    url   : Yup.string()
               .max(155, 'The field must have max. 155 characters!')
               .nullable()
               .required('Required'),
});

const MenuItemForm = (
    { formValues, onCancel, onSuccess }: { formValues: IMenuItem, onCancel: () => void, onSuccess: (values: IMenuItem) => void }
) => {
    return (
        <Formik initialValues={formValues} onSubmit={(values) => onSuccess(values)} validationSchema={editMenuItemSchema}>
            {(props) => (
                <Form noValidate autoComplete="off">
                    <Field
                        component={ReactstrapInput}
                        id="label"
                        label="Label"
                        maxLength="60"
                        name="label"
                        type="text"
                    />
                    <Field
                        component={ReactstrapInput}
                        disabled={formValues.type === 'page'}
                        id="url"
                        label="URL"
                        maxLength="155"
                        name="url"
                        type="text"
                    />
                    <Field
                        component={ReactstrapSelect}
                        id="target"
                        inputprops={{
                            defaultOption: 'Select an option...',
                            options      : UrlTypes
                        }}
                        label="Url Type"
                        name="target"
                    />
                    <Field
                        component={IconPicker}
                        id="icon"
                        label="Icon"
                        name="icon"
                    />
                    <div className="form-actions d-flex align-items-center justify-content-end mt-5">
                        <Button
                            color="link"
                            className="text-secondary"
                            onClick={onCancel}
                        >Cancel</Button>{' '}
                        <Button
                            type="submit"
                            color="primary"
                        >Save menu item</Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default MenuItemForm;
