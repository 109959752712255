import {Transition} from '@uirouter/react';
import AppConfig from '../../Components/AppConfig/AppConfig';
import AppUser from '../../Modules/AppUser';
import AppUserFiles from '../../Modules/AppUserFiles';
import {Form} from '../../Modules/Form';
import FormCreate from '../../Modules/FormCreate';
import FormEdit from "../../Modules/FormEdit";
import Login from '../../Modules/Login';
import MediaUploader from '../../Modules/MediaUploader';
import MediaUploaderAdd from '../../Modules/MediaUploaderAdd';
import Menu from '../../Modules/Menu';
import Notifications from '../../Modules/Notifications';
import Office from "../../Modules/Office";
import Page from '../../Modules/Page';
import PageCreate from '../../Modules/PageCreate';
import PageEdit from '../../Modules/PageEdit';
import User from '../../Modules/User';

/**
 * Resolve function for 'login' state to figure out what state to return to, after a successful login.
 *
 * If the user was initially redirected to login state (due to the auth redirect), then return the toState/params
 * they were redirected from.  Otherwise, if they transitioned directly, return the fromState/params.
 * Otherwise return the main "dashboard" state.
 */
const redirectAfterLogin = ($transition$: Transition) => {
    if ($transition$.redirectedFrom()) {
        // The user was redirected to the login state
        // Return to the original attempted target state
        return $transition$.redirectedFrom().targetState();
    }

    let $state = $transition$.router.stateService;

    // The user was not redirected to the login state; they directly activated the login state somehow.
    // Return them to the state they came from.
    if ($transition$.from().name !== '') {
        return $state.target($transition$.from(), $transition$.params('from'));
    }

    // If the fromState's name is empty, then this was the initial transition. Just return them to the home state
    return $state.target('users');
};

const states = [
    {
        component: Login,
        data     : {
            auth: false
        },
        name     : 'login',
        url      : '/login',
        resolve  : [
            {
                token    : 'redirectAfterLogin',
                deps     : ['$transition$'],
                resolveFn: redirectAfterLogin,
            },
        ]
    },
    {
        name     : 'menus',
        url      : '/menus',
        component: Menu
    },
    {
        name     : 'forms',
        url      : '/forms',
        component: Form
    },
    {
        name     : 'forms.create',
        url      : '/create',
        component: FormCreate
    },
    {
        name     : 'forms.edit',
        url      : '/edit/:id',
        component: FormEdit
    },
    {
        name     : 'media_uploader',
        url      : '/media-uploader',
        component: MediaUploader,
    },
    {
        name     : 'media_uploader_add',
        url      : '/media-uploader/add-files',
        component: MediaUploaderAdd
    },
    {
        name     : 'pages',
        url      : '/pages',
        component: Page
    },
    {
        name     : 'pages.create',
        url      : '/create',
        component: PageCreate
    },
    {
        name     : 'pages.edit',
        url      : '/edit/:id',
        component: PageEdit
    },
    {
        name     : 'users',
        url      : '/users',
        component: User
    },
    {
        name     : 'app_users',
        url      : '/app-users',
        component: AppUser
    },
    {
        name     : 'app_users.files',
        url      : '/:id/files',
        component: AppUserFiles
    },
    {
        name     : 'offices',
        url      : '/offices',
        component: Office
    },
    {
        name     : 'config',
        url      : '/config',
        component: AppConfig
    },
    {
        name     : 'notifications',
        url      : '/notifications',
        component: Notifications
    }
];

export default states;
