import React from 'react';
import uuid from 'uuid/v4';
import FormEditor from '../Components/FormEditor/FormEditor';
import FormService from '../Core/Form/FormService';
import { getWidgetDefaultValues } from '../Core/Form/widgets';
import MessageHandler from '../Core/MessageHandler';
import Router from '../Core/Router';

const buildInitialFormWidgets = () => {

    // Text field and submit button as default widgets
    const initialWidgets = [];
    const defaultButtonValues = getWidgetDefaultValues('button');
    const defaultTextValues = getWidgetDefaultValues('text');

    initialWidgets.push({ id: uuid(), type: 'text', values: defaultTextValues });
    initialWidgets.push({ id: uuid(), type: 'button', values: defaultButtonValues });

    return initialWidgets;
};

const FormCreate = () => {

    const onSave = (content: any, name: string) => {

        const formData = {
            name   : name,
            content: content
        };

        FormService.createForm(formData)
                   .then(({ id }) => {
                       MessageHandler.displaySuccessMessage('The form has been created successfully.', 5);

                       Router.stateService.go('forms.edit', { id: id });
                   })
                   .catch(error => {
                       if (error.response.status === 404) {
                           MessageHandler.displayErrorMessage('Form not found.');
                           Router.stateService.go('forms');
                       }
                       if (error.response.status === 400) {
                           MessageHandler.displayException(error.response.data['violations']);
                       }
                   });
    };

    return (<FormEditor isNew={true} onSave={onSave} name="New form" data={buildInitialFormWidgets()} />);
};

export default FormCreate;
