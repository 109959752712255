import map from 'lodash/map';
import React from 'react';
import CustomInput from 'reactstrap/lib/CustomInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import IField from './IField';
import { ISelectOption } from './SelectField';

interface IRadioProps extends IField {
    options: ISelectOption[];
}

const RadioField = (props: IRadioProps) => {
    return (
        <FormGroup>
            <Label>{props.label}</Label>
            {
                map(props.options, (option, index) => (
                    <CustomInput type="radio" key={index} id={option.value} label={option.label} name={props.name} />
                ))
            }
        </FormGroup>
    );
};

export default RadioField;
