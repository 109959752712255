import React, { Dispatch, useReducer } from 'react';
import { IMenu, IMenuItem } from './IMenu';
import { reducer } from './MenuReducer';

export interface IPage {
    id: number;
    slug: string;
    title: string;
}

export interface IStateContext {
    configId: number | null; // The ID of the record from the config table
    currentMenuItem: IMenuItem | null;
    menus: IMenu[];
    pages: IPage[];
}

export const defaultState = {
    configId       : null,
    currentMenuItem: null,
    menus          : [],
    pages          : [],
};

const StateContext = React.createContext<IStateContext | null>(null);
const DispatchContext = React.createContext<Dispatch<any> | undefined>(undefined);

const MenuProvider = ({ children }) => {
    const initialState = { ...defaultState };
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export {
    DispatchContext,
    StateContext,
    MenuProvider,
};
