import axios from 'axios';
import { IGridRequestParams } from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';
import IForm from './IForm';

class FormService {
    createForm(form: IForm): Promise<any> {
        const payload = {
            id: null,
            name: form.name,
            content: form.content
        };

        const url = HttpRouter.generate('create_form');

        return axios.post(url, {form: payload});
    }

    deleteForm(formId: number): Promise<any> {
        const url = HttpRouter.generate('delete_form', {id: formId});

        return axios.delete(url);
    }

    public getForm(formId: number): Promise<any> {
        const url = HttpRouter.generate('get_form', { id: formId });

        return axios.get(url);
    }

    formsList(params: IGridRequestParams): Promise<any> {
        const url = HttpRouter.generate('form_ds');

        return axios.post(url, params);
    }

    getForms(): Promise<any> {
        const url = HttpRouter.generate('get_forms');

        return axios.get(url);
    }

    updateForm(formId: number, form: IForm): Promise<any> {
        const payload = {
            id: formId,
            name: form.name,
            content: form.content
        };

        const url = HttpRouter.generate('create_form');

        return axios.post(url, { form: payload });
    }
}

export default new FormService();
