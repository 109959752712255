import React, { useEffect, useState } from 'react';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Label from 'reactstrap/lib/Label';
import IPropertyConfigProps from './IPropertyConfigProps';
import CssSpaceProperty from "../../../Core/ValueObject/CssSpaceProperty";
import { useFormDispatch } from "../../../Core/Form/useFormContext";
import { actionTypes } from "../../../Core/Form/FormReducer";

const paddingOptions = [
    {
        label: 'Top',
        name : 'top',
    }, {
        label: 'Right',
        name : 'right',
    }, {
        label: 'Bottom',
        name : 'bottom',
    }, {
        label: 'Left',
        name : 'left',
    }
];

const PaddingOption = (props: any) => {
    return (
        <>
            <FormGroup row>
                <Label sm={4}>
                    <span className="pl-3">{props.label}:</span>
                </Label>
                <Col sm={8}>
                    <InputGroup>
                        <Input
                            onChange={props.onChange}
                            min={0}
                            name={props.name}
                            step={5}
                            type="number"
                            value={props.value}
                        />
                        <InputGroupAddon addonType="append">px</InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
        </>
    );
};

const generatePaddingProperties = (value: string) => {
    const paddingProperties = value.split(' ').map(i => parseInt(i));

    return new CssSpaceProperty(paddingProperties).toCss();
};

const generateShorthand = (options: object) => {
    return new CssSpaceProperty(Object.values(options)).toShorthand();
};

const Padding = (props: IPropertyConfigProps) => {
    const [state, setState] = useState(props.value);
    const paddingGroup = generatePaddingProperties(state);

    const dispatch = useFormDispatch();

    const handleOnChange = (event: any) => {
        const position = event.target.name;
        const target = {};

        target[position] = event.target.value || 0;

        setState(generateShorthand({...paddingGroup, ...target}));
    };

    useEffect(() => {
        dispatch({
            type  : actionTypes.propertyChange,
            params: {
                propertyId   : props.id,
                propertyGroup: props.group,
                value        : state
            }
        })
    }, [props.id, props.group, state]);

    return (
        <>
            <hr />
            <h6 className="font-weight-bold">{props.label}</h6>

            {
                paddingOptions.map((option, index) => (
                    <PaddingOption
                        key={index}
                        label={option.label}
                        name={option.name}
                        onChange={handleOnChange}
                        value={paddingGroup[option.name]}
                    />
                ))
            }
        </>
    );
};

export default Padding;
