import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";
import IPropertyConfigProps from "../Properties/IPropertyConfigProps";

const CheckboxField = (props: IPropertyConfigProps) => {

    const dispatch = useFormDispatch();

    const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {

        dispatch({
            type  : actionTypes.propertyChange,
            params: {
                propertyId   : props.id,
                propertyGroup: props.group,
                value        : $event.target.checked
            }
        });
    };

    return (
        <FormGroup>
            <FormGroup check inline>
                <Label>
                    <Input type="checkbox"
                           checked={props.value}
                           defaultValue={props.value}
                           onChange={onChange} />
                    {props.label}
                </Label>
            </FormGroup>
        </FormGroup>
    );
};

export default CheckboxField;
