import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClassNames from 'classnames';
import keys from 'lodash/keys';
import React from 'react';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import { actionTypes } from "../../Core/Form/FormReducer";
import { useFormDispatch, useFormState } from "../../Core/Form/useFormContext";
import { widgetsProperties } from '../../Core/Form/widgets';
import Scrollbar from '../Scrollbar';
import PropertyGroup from './PropertyGroup';

const ConfigPanel = () => {
    const { configPanelVisible, selectedValues, selectedWidgetType } = useFormState();
    const classes = ClassNames({
        'config-panel'      : true,
        'config-panel--open': configPanelVisible,
    });
    const dispatch = useFormDispatch();
    const properties = widgetsProperties[selectedWidgetType];

    const handleClose = () => {
        dispatch({
            type: actionTypes.closeConfigPanel, params: {}
        });
    };

    const getPropertyGroups = function () {
        return <>
            {
                keys(properties).map(groupKey => {
                    const propertyGroup = properties[groupKey];
                    const groupValues = selectedValues[groupKey];

                    return (
                        <PropertyGroup
                            {...propertyGroup}
                            className="mb-2"
                            groupKey={groupKey}
                            key={groupKey}
                            valueItems={groupValues}
                        />
                    );
                })
            }
        </>;
    };

    return (
        <div className={classes}>
            <Nav className="widgets-panel__nav justify-content-end" tabs tag="div">
                <NavItem className="widgets-panel__nav-item">
                    <NavLink className="widgets-panel__nav-link" href="#" onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </NavLink>
                </NavItem>
            </Nav>
            <Scrollbar noScrollX={true}>
                <div className="h-100 pb-3">
                    {getPropertyGroups()}
                </div>
            </Scrollbar>
        </div>
    );
};

export default ConfigPanel;
