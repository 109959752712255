import axios from 'axios';
import {IGridRequestParams} from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';

class AppUserFilesService {
    deleteFile(fileId: number): Promise<any> {
        const url = HttpRouter.generate('private_files_delete', {id: fileId});

        return axios.delete(url);
    }

    downloadFile(userId: number, fileId: number): Promise<any> {
        const url = HttpRouter.generate('private_files_download', {userId, fileId});

        return axios.get(url, {responseType: 'blob'});
    }

    filesDs(appUserId: number, params: IGridRequestParams): Promise<any> {
        const url = HttpRouter.generate('private_files_ds', {id: appUserId});

        return axios.post(url, params);
    }

    uploadFiles(data: FormData): Promise<any> {
        const url = HttpRouter.generate('private_files_upload');

        return axios.post(url, data);
    }
}

export default new AppUserFilesService();
