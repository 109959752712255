import React from 'react';
import MenuManager from '../Components/MenuManager/MenuManager';
import { MenuProvider } from '../Core/Menu/MenuContext';

const Menu = () => {
    return (
        <MenuProvider>
            <MenuManager />
        </MenuProvider>
    );
};

export default Menu;
