import axios from 'axios';
import { IAppConfigData } from '../../Components/AppConfig/AppConfig';
import HttpRouter from '../HttpRouter';
import MessageHandler from '../MessageHandler';
import PageService from '../Page/PageService';

const bootstrapConfigData = (): Promise<any> => {
    const getPages = PageService.getAllPages();

    return axios
        .all([getConfigs(), getPages])
        .then(
            axios.spread((...responses) => {
                return Promise.resolve(responses);
            })
        )
        .catch(error => {
            MessageHandler.displayErrorMessage('Page not found.');
            // if (error.response.status === 404) {
            //     MessageHandler.displayErrorMessage('Page not found.');
            // }
        });
};

function getConfigs(): Promise<any> {
    const url = HttpRouter.generate('get_configs');

    return axios.get(url);
}

function saveConfig(name: string, value: IAppConfigData): Promise<any> {

    const url = HttpRouter.generate('save_config');

    return axios.post(url, { config: { name, content: value } });
}

export { bootstrapConfigData, getConfigs, saveConfig }
