import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from "lodash/findIndex";
import has from 'lodash/has';
import size from 'lodash/size';
import uniqueId from "lodash/uniqueId";
import uuid from 'uuid/v4';
import { getWidgetDefaultValues } from "./widgets";

const actionTypes = {
    addWidget       : 'addWidget',
    editWidget      : 'editWidget',
    copyWidget      : 'copyWidget',
    deleteWidget    : 'deleteWidget',
    reorderWidget   : 'reorderWidget',
    closeConfigPanel: 'closeConfigPanel',
    propertyChange  : 'propertyChange',
    nameChange      : 'nameChange',
    loadFormData    : 'loadFormData',
    addSubmitButton : 'addSubmitButton'
};

interface IPayload {
    type: string;
    params: any
}

const reducer = (state: any, { type, params }: IPayload) => {
    switch (type) {
        case actionTypes.editWidget: {
            const { widgetId } = params;
            const currentWidget = find(state.formWidgets, { id: widgetId });

            return {
                ...state,
                selectedWidget    : currentWidget,
                selectedWidgetType: currentWidget.type,
                configPanelVisible: true,
                selectedValues    : currentWidget.values
            };
        }
        case actionTypes.copyWidget: {

            const { widgetId } = params;
            const widgetToCopy = find(state.formWidgets, { id: widgetId });
            let currentWidgets = cloneDeep(state.formWidgets);

            // In case the widget already has some values (ex on copy) then
            // make sure these values will be copied as well
            const defaultValues = getWidgetDefaultValues(widgetToCopy.type);
            const values = {
                ...defaultValues,
                ...widgetToCopy.values
            };
            // Unique form field name to avoid issues when rendering/send the form
            values.general.name = uniqueId(widgetToCopy.values.general.name + '_');

            // Make sure submit button is the last widget in the list
            currentWidgets.splice(size(currentWidgets) - 1, 0, { id: uuid(), type: widgetToCopy.type, values: values });

            return {
                ...state,
                formWidgets: currentWidgets
            };
        }

        case actionTypes.deleteWidget: {
            const { widgetId } = params;

            const currentWidgets = cloneDeep(state.formWidgets);
            const widgetIndex = findIndex(currentWidgets, { id: widgetId });
            currentWidgets.splice(widgetIndex, 1);

            return {
                ...state,
                formWidgets: currentWidgets
            };
        }
        case actionTypes.reorderWidget: {
            const { startIndex, endIndex } = params;

            let currentWidgets = cloneDeep(state.formWidgets);
            currentWidgets = reorder(currentWidgets, startIndex, endIndex);

            return {
                ...state,
                formWidgets: currentWidgets
            };
        }

        case actionTypes.addWidget:
            const { widget, index } = params;

            let currentWidgets = cloneDeep(state.formWidgets);

            // In case the widget already has some values (ex on copy) then
            // make sure these values will be copied as well
            const defaultValues = getWidgetDefaultValues(widget.type);
            const values = {
                ...defaultValues,
                ...widget.values
            };

            currentWidgets.splice(index, 0, { id: uuid(), type: widget.type, values: values});

            return { ...state, formWidgets: currentWidgets };

        case actionTypes.closeConfigPanel: {

            return {
                ...state,
                configPanelVisible: false,
                selectedWidget    : null,
                selectedWidgetType: null,
                selectedValues    : null
            };
        }

        case actionTypes.propertyChange: {

            const { propertyId, propertyGroup, value } = params;
            const selectedValuesCopy = cloneDeep(state.selectedValues);

            // New selected values
            if (!has(selectedValuesCopy, propertyGroup)) {
                selectedValuesCopy[propertyGroup] = {};
            }
            selectedValuesCopy[propertyGroup][propertyId] = value;

            const selectedWidgetCopy = cloneDeep(state.selectedWidget);
            selectedWidgetCopy.values = selectedValuesCopy;

            const widgetIdx = findIndex(state.formWidgets, { id: selectedWidgetCopy.id });

            // Replace widget with updated values
            const currentWidgets = cloneDeep(state.formWidgets);
            currentWidgets[widgetIdx] = selectedWidgetCopy;

            return {
                ...state,
                selectedValues: selectedValuesCopy,
                selectedWidget: selectedWidgetCopy,
                formWidgets   : currentWidgets
            };
        }

        case actionTypes.nameChange: {
            const { name } = params;

            return {
                ...state,
                name: name
            };
        }
        case actionTypes.loadFormData: {
            const { name, widgets } = params;

            return {
                ...state,
                name       : name,
                formWidgets: widgets
            };
        }

        case actionTypes.addSubmitButton: {

            let currentWidgets = cloneDeep(state.formWidgets);

            const defaultValues = getWidgetDefaultValues('button');
            currentWidgets.push({ id: uuid(), type: 'button', values: defaultValues });

            return {
                ...state,
                formWidgets: currentWidgets
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`);
        }
    }
};

const reorder = (list, startIndex, endIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
};

export {
    actionTypes,
    reducer
}
