import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import map from 'lodash/map';
import React from 'react';
import IField from '../../FormFields/IField';
import DraggableHoc from '../../DraggableHoc';

const DraggableField = DraggableHoc(Field);

function Field(props: { field: IField, onClick: Function }) {
    return <div
        className="btn btn-light d-flex align-items-center mb-1"
        onClick={() => props.onClick(props.field)}
    >
        <FontAwesomeIcon icon={faSort} className="mr-2" />
        <h6 className="mb-0 pr-1 text-truncate">{props.field.label}</h6>
        <FontAwesomeIcon icon={faPencilAlt} className="ml-auto" />
    </div>;
}

const FieldsList = (props: { fields: IField[], onClick: Function }) => map(props.fields, (field, index) => (
    <DraggableField
        draggableId={field.id}
        field={field}
        index={index}
        key={field.id}
        onClick={props.onClick}
    />
));

export default FieldsList;
