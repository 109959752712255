// @ts-ignore
import autoBind from 'react-autobind';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import IOffice from '../Core/Office/IOffice';
import MessageHandler from '../Core/MessageHandler';
import React, { Component } from 'react';
import OfficeGrid from '../Components/Office/OfficeGrid';
import OfficeModal from '../Components/Office/OfficeModal';
import OfficeService from '../Core/Office/OfficeService';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

interface IOfficeState {
    reloadGrid: boolean;
    showOfficeModal: boolean;
}

class Office extends Component<{}, IOfficeState> {
    protected office: IOffice;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.office = null;
        this.state = {
            reloadGrid     : false,
            showOfficeModal: false
        };
    }

    render() {
        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                            <h4 className="m-0">Offices</h4>
                            <Button
                                className="ml-auto"
                                color="info"
                                size="sm"
                                onClick={this.onAddOffice}
                            >Create office</Button>
                        </CardHeader>
                        <CardBody>
                            <OfficeGrid
                                onDelete={this.onDeleteOffice}
                                onEdit={this.onEditOffice}
                                onReady={this.onGridReady}
                                reload={this.state.reloadGrid}
                            />
                        </CardBody>
                    </Card>
                </div>

                {
                    this.state.showOfficeModal ? (
                        <OfficeModal
                            data={this.office}
                            onClose={this.onOfficeModalClose}
                        />
                    ) : null
                }
            </>
        );
    }

    private onAddOffice(): void {
        this.office = null;
        this.setState({showOfficeModal: true});
    }

    private onDeleteOffice(office: IOffice): void {
        // @ts-ignore
        this.props.registerPromise(
            OfficeService.deleteOffice(office.id)
        ).then(({data}) => {
            if (data.success) {
                this.setState({reloadGrid: true});
                MessageHandler.displaySuccessMessage(data.message);
            } else {
                MessageHandler.displayErrorMessage(data.message);
            }
        });
    }

    private onEditOffice(office: IOffice): void {
        this.office = office;
        this.setState({showOfficeModal: true});
    }

    private onGridReady() {
        this.setState({reloadGrid: false});
    }

    private onOfficeModalClose(shouldReloadGrid: boolean = false): void {
        this.setState({
            showOfficeModal: false,
            reloadGrid     : shouldReloadGrid,
        });
    }
}

export default makeComponentTrashable(Office);
