import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const LoadingIndicator = () => (
    <>
        <div className="d-flex flex-column justify-content-center" style={{ height: 'calc(100vh - 60px)' }}>
            <div className="d-flex align-items-center justify-content-center">
                <FontAwesomeIcon className="mr-2" icon={faSpinner} spin /> Loading...
            </div>
        </div>
    </>
);

export default LoadingIndicator;
