import map from 'lodash/map';
import React from 'react';
import CustomInput from 'reactstrap/lib/CustomInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import IField from "../../FormFields/IField";
import DraggableWidget from "./DraggableWidget";
import { getValueForProperty } from "../../../Core/Form/properties";
import { ISelectOption } from "../../FormFields/SelectField";

interface IRadioProps extends IField {
    options: ISelectOption[];
    style: Object;
}

const RadioField = (props: IRadioProps) => {

    return (
        <FormGroup style={props.style}>
            <Label>{props.label}</Label>{props.required ? (<span>*</span>) : ''}
            {
                map(props.options, (option, index) => (
                    <CustomInput
                        type="radio"
                        key={index}
                        id={option.value}
                        label={option.label}
                        name={props.name} />
                ))
            }
        </FormGroup>
    );
};

const Widget = DraggableWidget(RadioField);

const RadioWidget = (props) => {
    const style = {
        color: getValueForProperty(props.values, 'styles', 'color'),
    };
    const label = getValueForProperty(props.values, 'general', 'label');
    const name = getValueForProperty(props.values, 'general', 'name');
    const options = getValueForProperty(props.values, 'general', 'options');
    const required = getValueForProperty(props.values, 'general', 'required');

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
            label={label}
            name={name}
            options={options}
            required={required}
        />
    );
};

export default RadioWidget;


