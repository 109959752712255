import has from 'lodash/has';
import map from 'lodash/map';
import React from 'react';
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { useFormState } from "../../../Core/Form/useFormContext";
import Margin from "../Properties/Margin";
import Padding from "../Properties/Padding";
import ButtonWidget from "./ButtonWidget";
import CheckboxWidget from "./CheckboxWidget";
import DateWidget from "./DateWidget";
import EmailWidget from './EmailWidget';
import IDraggableWidgetProps from './IDraggableWidgetProps';
import NumberWidget from "./NumberWidget";
import PasswordWidget from "./PasswordWidget";
import RadioWidget from "./RadioWidget";
import SelectWidget from "./SelectWidget";
import TextareaWidget from "./TextareaWidget";
import TextWidget from "./TextWidget";
import TimeWidget from "./TimeWidget";

const widgetComponentMap = {
    text     : TextWidget,
    radio    : RadioWidget,
    checkbox : CheckboxWidget,
    password : PasswordWidget,
    number   : NumberWidget,
    date     : DateWidget,
    time     : TimeWidget,
    textarea : TextareaWidget,
    select   : SelectWidget,
    margin   : Margin,
    padding  : Padding,
    button   : ButtonWidget,
    email    : EmailWidget
};

const componentForWidgetType = (widgetType: string) => {
    if (!has(widgetComponentMap, widgetType)) {
        throw new Error(`Widget type "${widgetType}" has no associated component`);
    }

    return widgetComponentMap[widgetType];
};

const renderWidget = (widget, idx, selectedWidget) => {

    const draggableWidgetProps: IDraggableWidgetProps = {
        id        : widget.id,
        index     : parseInt(idx),
        isSelected: selectedWidget === widget.id,
    };

    const Widget = componentForWidgetType(widget.type);

    return (
        <div key={widget.id} className="preview-element">
            <Widget
                draggableWidgetProps={draggableWidgetProps}
                values={widget.values}
            />
        </div>
    );
};

const WidgetsList = () => {

    const {
              formWidgets,
              selectedWidget
          } = useFormState();

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        {
                            formWidgets && formWidgets.length
                            ? (
                                //TODO .......don't map through all the widgets, only the selected one should be re rendered
                                map(formWidgets, (widget, idx) => {
                                    return renderWidget(widget, idx, selectedWidget);
                                })
                            )
                            : (
                                <div className="bg-info py-3 text-center text-white">No content. Drag widgets here.</div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default WidgetsList;
