import * as yup from 'yup';

function validate(data, meta: { filtersEnabled: boolean }) {
    const schema = yup.object({
        message : yup.string()
                     .required('The Message is required.'),
        criteria: yup.array()
                     .test({
                         message: 'You haven\'t select any receivers.',
                         test   : value => {
                             if (!meta.filtersEnabled) return true;

                             return meta.filtersEnabled && value.length;
                         }
                     })
                     .nullable(),
    });

    return schema.validate(data);
}

export {validate}
