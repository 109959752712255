import has from 'lodash/has';

export function getValueForProperty(properties: { [key: string]: any[] }, group: string, propertyName: string) {
    if (!properties) {
        throw new Error('No properties.');
    }

    if (!has(properties, group)) {
        throw new Error(`No group <${group}> in properties ${properties}.`);
    }

    if (!has(properties[group], propertyName)) {
        throw new Error(`No property <${propertyName}> for group ${group}`);
    }

    return properties[group][propertyName];
}

