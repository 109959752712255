import {faArrowsAlt} from '@fortawesome/free-solid-svg-icons/faArrowsAlt';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

const ButtonDrag = (props: { dragHandleProps: any }) => {
    return (
        <div {...props.dragHandleProps}>
            <FontAwesomeIcon icon={faArrowsAlt} inverse />
        </div>
    );
};

export default ButtonDrag;
