class CssSpaceProperty {
    protected values: any;

    constructor(values: number[]) {
        this.values = values;
    }

    public toCss = (): object => {
        if (this.values.length === 1) {
            return this.generateSpaceRule(this.values[0], this.values[0], this.values[0], this.values[0]);
        }

        if (this.values.length === 2) {
            return this.generateSpaceRule(this.values[0], this.values[1], this.values[0], this.values[1]);
        }

        if (this.values.length === 3) {
            return this.generateSpaceRule(this.values[0], this.values[1], this.values[2], this.values[1]);
        }

        return this.generateSpaceRule(this.values[0], this.values[1], this.values[2], this.values[3]);
    };

    public toShorthand = (): string => {
        let options = Array.from(this.values);

        // left and right values are the same
        // BUT top and bottom values are different
        if (this.values[1] === this.values[3] && this.values[0] !== this.values[2]) {
            options.splice(3, 1);
        }
        // left and right values are the same AND top and bottom values are the same
        // BUT top and right values are different
        if (this.values[1] === this.values[3] && this.values[0] === this.values[2] && this.values[0] !== this.values[1]) {
            options.splice(2, 2);
        }
        // all values are equal
        if (this.values.every( (val, i, arr) => val === arr[0] )) {
            options.splice(1, 3);
        }

        return options.map(item => item + 'px').join(' ');
    };

    private generateSpaceRule = (...values) => ({
        top   : values[0],
        right : values[1],
        bottom: values[2],
        left  : values[3],
    });
}

export default CssSpaceProperty;
