import axios from 'axios';
import {IGridRequestParams} from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';

function searchUsersByEmail(searchTerm): Promise<any> {
    const url = HttpRouter.generate('search_app_users');

    return axios.post(url, {criteria: {name: 'FindByEmail', value: searchTerm}});
}

export {searchUsersByEmail}

class AppUserService {
    deleteUser(userId: number): Promise<any> {
        const url = HttpRouter.generate('delete_app_user', {id: userId});

        return axios.delete(url);
    }

    getUser(userId: number): Promise<any> {
        const url = HttpRouter.generate('get_app_user', {id: userId});

        return axios.get(url);
    }

    usersDs(params: IGridRequestParams): Promise<any> {
        const url = HttpRouter.generate('app_user_ds');

        return axios.post(url, params);
    }

    updatePassword(userId: number, data: { password: string, passwordConfirm: string }): Promise<any> {
        const payload = {
            password: data.password,
            id      : userId
        };

        const url = HttpRouter.generate('app_user_change_password', {id: userId});

        return axios.post(url, payload);
    }
}

export default new AppUserService();

