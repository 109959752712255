import classNames from 'classnames';
import EventService from 'eventservice';
import map from 'lodash/map';
import omit from 'lodash/omit';
import range from 'lodash/range';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import ButtonCopy from '../ActionButtons/ButtonCopy';
import ButtonDelete from '../ActionButtons/ButtonDelete';
import ButtonDrag from '../ActionButtons/ButtonDrag';
import ButtonEdit from '../ActionButtons/ButtonEdit';
import { COPY_SECTION, DELETE_SECTION, EDIT_SECTION } from '../../../Core/Page/events';
import { getValueForProperty } from '../../../Core/Page/properties';

const getElementClasses = (isSelected) => {
    return classNames({
        'preview-element__selectable': true,
        'preview-element--selected'  : isSelected
    });
};

const onCopy = (index) => {
    EventService.fire(COPY_SECTION, index);
};

const onDelete = (index) => {
    EventService.fire(DELETE_SECTION, index);
};

const onEdit = (index) => {
    EventService.fire(EDIT_SECTION, index);
};

export default (WrappedComponent) => {
    const DraggableSection = ({ id, index, isSelected, ...props }) => {
        const styles = {
            backgroundColor: getValueForProperty(props.values, 'general', 'color')
        };

        const columns = range(props.values.columns.columns);

        return (
            <div style={styles}>
                <Draggable draggableId={id} index={index}>
                    {({ innerRef, draggableProps, dragHandleProps }) => (
                        <div {...draggableProps} ref={innerRef}>
                            <Container>
                                <Row>
                                    {
                                        map(columns, idx => {
                                            const componentProps = omit(props, 'widgets');
                                            const content = props.widgets[idx];

                                            return (
                                                <Col key={idx}>
                                                    <WrappedComponent
                                                        {...componentProps}
                                                        columnIndex={idx}
                                                        droppableId={`${props.droppableId}_${idx}`}
                                                        widgets={content}
                                                    />
                                                </Col>
                                            );
                                        })
                                    }
                                </Row>
                            </Container>

                            <div className={getElementClasses(isSelected)}>
                                <div className="preview-element__actions">
                                    <ButtonDrag dragHandleProps={dragHandleProps} />
                                    <ButtonEdit onClick={() => onEdit(index)} />
                                    <ButtonCopy onClick={() => onCopy(index)} />
                                    <ButtonDelete onClick={() => onDelete(index)} />
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
            </div>
        );
    };

    DraggableSection.displayName = `DraggableSectionHoc(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return DraggableSection;
}
