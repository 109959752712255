import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldArray, Form, Formik } from 'formik';
import isString from 'lodash/isString';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import React from 'react';
import { ReactstrapInput, ReactstrapSelect } from 'reactstrap-formik';
import Button from 'reactstrap/lib/Button';
import Yup from '../../../Core/YupValidator';
import ButtonWithLoader from '../../ButtonWithLoader';
import ErrorMessage from '../../FormFields/ErrorMessage';
import IField from '../../FormFields/IField';
import { ISelectOption } from '../../FormFields/SelectField';
import FormikSwitchInput from '../../FormikSwitchInput';

interface IProps {
    onCancel: Function;
    onDelete: Function;
    onSuccess: Function;
    formValues: IField;
}

const fieldTypes = [
    { id: 'checkbox', name: 'Checkbox' },
    { id: 'date', name: 'Date' },
    { id: 'email', name: 'Email' },
    { id: 'number', name: 'Number' },
    { id: 'password', name: 'Password' },
    { id: 'plaintext', name: 'Plain Text (Static)' },
    { id: 'radio', name: 'Radio' },
    { id: 'select', name: 'Select' },
    { id: 'tel', name: 'Tel' },
    { id: 'text', name: 'Text' },
    { id: 'time', name: 'Time' },
    { id: 'textarea', name: 'Textarea' },
    { id: 'url', name: 'URL' },
];

const fieldsWithoutPlaceholder = ['checkbox', 'radio', 'select'];
const fieldsWithOptions = ['radio', 'select'];

const FieldSchema = Yup.object().shape({
          isRequired : Yup.boolean(),
          label      : Yup.string()
                          .min(2, 'The field must have min. 2 characters!')
                          .required('Required'),
          name       :
              Yup.string()
                 .min(2, 'The field must have min. 2 characters!')
                 .max(60, 'The field must have max. 60 characters!')
                 .required('Required'),
          options    :
              Yup.array()
                 .of(
                     Yup.object().shape({
                         label: Yup.string()
                                   .required('Required'),
                         value: Yup.string()
                                   .required('Required'),
                     })
                 )
                 .when('type', (type, schema) => {
                     const hasOptions = fieldsWithOptions.indexOf(type) > -1;

                     return hasOptions
                            ? schema.required('Required').min(2, 'You have to add at least 2 options')
                            : schema.min(0);
                 }),
          placeholder:
              Yup.string()
                 .min(2, 'The field must have min. 2 characters!')
                 .max(60, 'The field must have max. 60 characters!')
                 .nullable(),
          type       :
              Yup.string()
            //     .oneOf(keys(keyBy(fieldTypes, 'id')))
                 .required('Required'),
      })
;

const initialOption: ISelectOption = { label: '', value: '' };

const FieldForm = (props: IProps) => {
    const { onCancel, onDelete, onSuccess, formValues } = props;

    return (
        <Formik
            initialValues={formValues}
            validationSchema={FieldSchema}
            onSubmit={(values, { setSubmitting }) => {
                onSuccess(values);
                setSubmitting(false);
            }}
        >
            {
                (props) => {
                    const { isSubmitting, isValid, errors, touched, values } = props;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                component={ReactstrapSelect}
                                id="type"
                                label="Field type"
                                name="type"
                                inputprops={{
                                    options: fieldTypes
                                }}
                                type="select"
                            />
                            <Field
                                component={ReactstrapInput}
                                id="name"
                                label="Field name"
                                maxLength="60"
                                name="name"
                                type="text"
                            />
                            <Field
                                component={ReactstrapInput}
                                id="label"
                                label="Field label"
                                name="label"
                                type="text"
                            />

                            {
                                fieldsWithoutPlaceholder.indexOf(values.type) === -1
                                ? <Field
                                    component={ReactstrapInput}
                                    id="placeholder"
                                    label="Field placeholder"
                                    maxLength="60"
                                    name="placeholder"
                                    type="text"
                                />
                                : null
                            }

                            <Field
                                component={FormikSwitchInput}
                                id="isRequired"
                                label="Required"
                                name="isRequired"
                            />

                            {
                                fieldsWithOptions.indexOf(values.type) > -1
                                ? <>
                                    <h5>Options</h5>

                                    <FieldArray
                                        name="options"
                                        render={arrayHelpers => {
                                            const onAddOption = () => arrayHelpers.push(initialOption);

                                            return (
                                                <>
                                                    {
                                                        map(values.options, (option, index) => (
                                                            <div className="form-inline justify-content-between mb-2"
                                                                 key={index}
                                                                 style={{ alignItems: 'flex-start' }}
                                                            >
                                                                <div>
                                                                    <Field
                                                                        component={ReactstrapInput}
                                                                        name={`options[${index}].value`}
                                                                        placeholder="Value" />
                                                                    <ErrorMessage name={`options[${index}].value`} />
                                                                </div>
                                                                <div>
                                                                    <Field
                                                                        component={ReactstrapInput}
                                                                        name={`options.${index}.label`}
                                                                        placeholder="Label" />
                                                                    <ErrorMessage name={`options[${index}].label`} />
                                                                </div>
                                                                <Button
                                                                    onClick={() => arrayHelpers.remove(index)}>
                                                                    <FontAwesomeIcon icon={faMinus} />
                                                                </Button>
                                                            </div>
                                                        ))
                                                    }

                                                    {
                                                        isString(errors.options) && touched.options
                                                        ? <div className="text-danger">{errors.options}</div>
                                                        : null
                                                    }

                                                    <Button onClick={onAddOption}>Add option</Button>
                                                </>
                                            );
                                        }}
                                    />
                                </>
                                : null
                            }

                            <div className="form-actions d-flex align-items-center justify-content-end mt-4 pt-3">
                                {formValues.id
                                 ? <Button
                                     className="mr-auto"
                                     color="danger"
                                     onClick={() => onDelete(formValues.id)}
                                 >Delete</Button>
                                 : null}
                                <Button
                                    color="link"
                                    className="text-secondary"
                                    onClick={() => onCancel()}
                                >Cancel</Button>{' '}
                                <ButtonWithLoader
                                    color="primary"
                                    disabled={isSubmitting || !isValid}
                                    isLoading={isSubmitting}
                                    text="Save field"
                                    type="submit"
                                />
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default FieldForm;
