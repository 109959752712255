import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import React, { useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Button from 'reactstrap/lib/Button';
import ButtonToolbar from 'reactstrap/lib/ButtonToolbar';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { actionTypes } from "../../Core/Form/FormReducer";
import { useFormDispatch, useFormState } from "../../Core/Form/useFormContext";
import { widgets as allWidgets } from '../../Core/Form/widgets';
import Router from '../../Core/Router';
import DroppableHoc from '../DroppableHoc';
import ConfigPanel from "../FormEditor/ConfigPanel";
import WidgetsPanel from "../FormEditor/WidgetsPanel";
import WidgetsList from "./Widgets/WidgetsList";

const onBackToList = () => {
    Router.stateService.go('forms');
};

const PreviewPanel = DroppableHoc(WidgetsList);

const FormEditor = (props) => {
    const {
              name,
              formWidgets,
          } = useFormState();

    const dispatch = useFormDispatch();

    useEffect(() => {
        // Populate form with data
        dispatch({
            type  : actionTypes.loadFormData,
            params: { name: props.name, widgets: props.data }
        });
    }, []);

    const getWidget = (widgetId: string) => {
        return cloneDeep(find(allWidgets, { id: widgetId }))
    };

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;

        if (!destination) {
            return;
        }

        // add widget
        if (source.droppableId === 'form') {
            const widget = getWidget(draggableId);
            dispatch({
                type: actionTypes.addWidget, params: {
                    widget,
                    index: destination.index
                }
            });
        } else {
            // reorder widget
            const currentWidget = getWidget(draggableId);
            dispatch({
                type: actionTypes.reorderWidget, params: {
                    currentWidget,
                    startIndex: destination.index,
                    endIndex  : source.index
                }
            });
            return false;
        }
    };

    return (
        <>
            <header className="page-header d-flex align-items-center sticky-top border-bottom">
                <h4 className="m-0">
                    {props.isNew ? 'Create form' : 'Edit form'}
                </h4>
                <ButtonToolbar className="page-actions ml-auto">
                    <Button outline onClick={onBackToList}>Back to list</Button>
                    <Button
                        color="primary"
                        className="ml-1"
                        onClick={() => props.onSave(formWidgets, name)}>
                        {props.isNew ? 'Create' : 'Update'}
                    </Button>
                </ButtonToolbar>
            </header>

            <DragDropContext onDragEnd={onDragEnd}>
                <Container fluid>
                    <Row className="page-container">
                        <Col className="preview-panel" xl={10}>
                            <PreviewPanel
                                droppableId="preview"
                                type="widget"
                            />
                        </Col>
                        <Col className="d-none d-xl-block tools-panel" xl={2} md={3}>
                            <WidgetsPanel />
                            <ConfigPanel />
                        </Col>
                    </Row>
                </Container>
            </DragDropContext>
        </>);
};

export default FormEditor;
