import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import IField from "../../FormFields/IField";
import { ISelectOption } from "../../FormFields/SelectField";
import Input from "reactstrap/lib/Input";

interface IRadioProps extends IField {
    options: ISelectOption[];
}
// NOT USED FOR NOW
const RadioField = (props: IRadioProps) => {

    return (
        <FormGroup check>
            <Label check>
                {props.label}
                <Input
                    className={'custom-control'}
                    type="radio"
                    name={props.name} />
            </Label>

        </FormGroup>
    );
};

export default RadioField;
