import { Field, getIn } from 'formik';
import React from 'react';
import FormFeedback from 'reactstrap/lib/FormFeedback';

const ErrorMessage = ({ name }) => (
    <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);

            return touch && error ? <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback> : null;
        }}
    />
);

export default ErrorMessage;
