import { faAlignCenter } from '@fortawesome/free-solid-svg-icons/faAlignCenter';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons/faAlignRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import IPropertyConfigProps from './IPropertyConfigProps';
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";

const ALIGNMENT_LEFT = 'left';
const ALIGNMENT_CENTER = 'center';
const ALIGNMENT_RIGHT = 'right';

const AlignmentPicker = (props: IPropertyConfigProps) => {

    const dispatch = useFormDispatch();

    const handleOnClick = (alignment: string) => {

        dispatch({
            type  : actionTypes.propertyChange,
            params: {
                propertyId   : props.id,
                propertyGroup: props.group,
                value        : alignment
            }
        });
    };

    return (
        <FormGroup className="d-flex">
            <Label>{props.label}</Label>
            <ButtonGroup className="ml-auto">
                <Button
                    active={props.value === ALIGNMENT_LEFT}
                    onClick={() => handleOnClick(ALIGNMENT_LEFT)}
                    outline>
                    <FontAwesomeIcon icon={faAlignLeft} />
                </Button>
                <Button
                    active={props.value === ALIGNMENT_CENTER}
                    onClick={() => handleOnClick(ALIGNMENT_CENTER)}
                    outline>
                    <FontAwesomeIcon icon={faAlignCenter} />
                </Button>
                <Button
                    active={props.value === ALIGNMENT_RIGHT}
                    onClick={() => handleOnClick(ALIGNMENT_RIGHT)}
                    outline>
                    <FontAwesomeIcon icon={faAlignRight} />
                </Button>
            </ButtonGroup>
        </FormGroup>
    );
};

export default AlignmentPicker;
