import React from 'react';
import Async from 'react-select/async';
import FormGroup from 'reactstrap/lib/FormGroup';
import {searchUsersByEmail} from '../../Core/AppUser/AppUserService';

interface IEmailFilterProps {
    isDisabled: boolean;
    onChange: (val) => void;
    value: any[];
}

const EmailFilter = (props: IEmailFilterProps) => {
    const loadOptions = async (inputValue) => {
        const resp = await searchUsersByEmail(inputValue);
        return resp.users;
    };

    const getOptionLabel = (option) => {
        return `${option.email} (${option.firstName} ${option.lastName})`;
    };

    return (
        <FormGroup>
            <label>Find users by Email</label>
            <Async
                cacheOptions
                getOptionLabel={getOptionLabel}
                getOptionValue={opt => opt.id}
                isDisabled={props.isDisabled}
                isMulti
                loadOptions={loadOptions}
                onChange={props.onChange}
                value={props.value}
            />
        </FormGroup>
    );
};

export default EmailFilter;
