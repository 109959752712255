import React from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import IPropertyConfigProps from './IPropertyConfigProps';

const COLUMNS_ONE = 1;
const COLUMNS_TWO = 2;
const COLUMNS_THREE = 3;
const COLUMNS_FOUR = 4;

const ColumnsNumber = (props: IPropertyConfigProps) => {
    const handleOnClick = (columnsNumber) => {
        if (parseInt(props.value) === columnsNumber) {
            return;
        }

        if (parseInt(props.value) > columnsNumber) {
            if (!window.confirm('Are you sure? Any unsaved changes will be lost!')) {
                return;
            }
        }

        PropertyConfigService.notifyPropertyChange(props.id, props.group, columnsNumber);
    };

    return (
        <FormGroup className="d-flex">
            <Label>{props.label}</Label>

            <ButtonGroup className="ml-auto">
                <Button
                    active={props.value === COLUMNS_ONE}
                    onClick={() => handleOnClick(COLUMNS_ONE)}
                    outline
                >1</Button>

                <Button
                    active={props.value === COLUMNS_TWO}
                    onClick={() => handleOnClick(COLUMNS_TWO)}
                    outline
                >2</Button>

                <Button
                    active={props.value === COLUMNS_THREE}
                    onClick={() => handleOnClick(COLUMNS_THREE)}
                    outline
                >3</Button>

                <Button
                    active={props.value === COLUMNS_FOUR}
                    onClick={() => handleOnClick(COLUMNS_FOUR)}
                    outline
                >4</Button>
            </ButtonGroup>
        </FormGroup>
    );
};

export default ColumnsNumber;
