import React from 'react';
import Button from 'reactstrap/lib/Button';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ButtonWithLoader = (props: any) => {
    const { isLoading, text, ...remainingProps } = props;

    return (
        <>
            <Button {...remainingProps}>
                {text}
                {
                    isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} className={'ml-2'} spin />
                    ) : null
                }
            </Button>
        </>
    );
};

export default ButtonWithLoader;
