import cloneDeep from 'lodash/cloneDeep';
import { IMenuItem } from './IMenu';
import { IStateContext } from './MenuContext';
import { getMenuById, getMenuIndex, getMenuItemById, getMenuItemIndex, reorderList } from './utils';

const actionTypes = {
    addMenuItem       : 'addMenuItem',
    addMenuItemChild  : 'addMenuItemChild',
    deleteMenuItem    : 'deleteMenuItem',
    reorderMenu       : 'reorderMenu',
    reorderMenuItem   : 'reorderMenuItem',
    updateMenus       : 'updateMenus',
    setData           : 'setData',
    setCurrentMenuItem: 'setCurrentMenuItem',
    updateMenuItem    : 'updateMenuItem',
    updateMenuTitle   : 'updateMenuTitle',
};

interface IPayload {
    type: string;
    payload: any;
}

const reducer = (state: IStateContext, { type, payload }: IPayload) => {
    switch (type) {
        case actionTypes.addMenuItem: {
            const menusClone = cloneDeep(state.menus);
            const menu = getMenuById(menusClone, payload.menuItem.menuId);
            const menuIndex = getMenuIndex(menusClone, payload.menuItem.menuId);

            // Add item to menu on the given position
            menu.items.splice(payload.index, 0, payload.menuItem);

            // Update menu in menus list
            menusClone[menuIndex] = menu;

            return { ...state, menus: menusClone };
        }
        case actionTypes.addMenuItemChild: {
            const menusClone = cloneDeep(state.menus);
            const menuId = payload.child.menuId;
            const menuItemId = payload.child.parentId;
            const menu = getMenuById(menusClone, menuId);
            const menuIndex = getMenuIndex(menusClone, menuId);
            const menuItem = getMenuItemById(menu.items, menuItemId);
            const menuItemIndex = getMenuItemIndex(menu.items, menuItemId);

            // Add child to menuItem on the given position
            menuItem.items.splice(payload.index, 0, payload.child);
            menuItem.hasItems = true;
            // Update item in menu items
            menu.items[menuItemIndex] = menuItem;
            // Update menu in menus list
            menusClone[menuIndex] = menu;

            return { ...state, menus: menusClone };
        }
        case actionTypes.deleteMenuItem: {
            const menusClone = cloneDeep(state.menus);
            const menuId = payload.menuId;
            const menuItemId = payload.menuItemId;
            const menu = getMenuById(menusClone, menuId);
            const menuIndex = getMenuIndex(menusClone, menuId);
            let menuItemIndex = getMenuItemIndex(menu.items, menuItemId);

            if (menuItemIndex > -1) {
                // Delete item from menu items
                menu.items.splice(menuItemIndex, 1);
            } else {
                // We must delete a child
                menuItemIndex = getMenuItemIndex(menu.items, payload.parentId);
                const menuItem = getMenuItemById(menu.items, payload.parentId);
                const childIndex = getMenuItemIndex(menuItem.items, menuItemId);

                // Delete child from menuItem
                menuItem.items.splice(childIndex, 1);
                menuItem.hasItems = menuItem.items.length > 0;

                // Update item in menu items
                menu.items[menuItemIndex] = menuItem;
            }

            // Update menu in menus list
            menusClone[menuIndex] = menu;

            return { ...state, menus: menusClone };
        }
        case actionTypes.reorderMenu: {
            const menusClone = cloneDeep(state.menus);
            const menuId = payload.menuId;
            const menu = getMenuById(menusClone, menuId);
            const menuIndex = getMenuIndex(menusClone, menuId);

            menusClone[menuIndex].items = reorderList(menu.items, payload.oldIndex, payload.newIndex);

            return { ...state, menus: menusClone };
        }
        case actionTypes.reorderMenuItem: {
            const menusClone = cloneDeep(state.menus);
            const menuId = payload.menuId;
            const menuItemId = payload.menuItemId;
            const menu = getMenuById(menusClone, menuId);
            const menuIndex = getMenuIndex(menusClone, menuId);
            const menuItem = getMenuItemById(menu.items, menuItemId);
            const menuItemIndex = getMenuItemIndex(menu.items, menuItemId);

            menuItem.items = reorderList(menuItem.items, payload.oldIndex, payload.newIndex);
            menu.items[menuItemIndex] = menuItem;
            menusClone[menuIndex] = menu;

            return { ...state, menus: menusClone };
        }
        case actionTypes.setData: {
            return {
                ...state,
                menus   : payload.menus,
                pages   : payload.pages
            };
        }
        case actionTypes.setCurrentMenuItem: {
            return { ...state, currentMenuItem: payload.menuItem };
        }
        case actionTypes.updateMenuItem: {
            const menusClone = cloneDeep(state.menus);
            const menuIndex = getMenuIndex(menusClone, payload.menuItem.menuId);
            const menu = menusClone[menuIndex];

            menusClone[menuIndex].items = updateItemRecursively(menu.items, payload.menuItem);

            return { ...state, menus: menusClone };
        }
        case actionTypes.updateMenuTitle: {
            const { menuId, menuTitle } = payload;
            const menusClone = cloneDeep(state.menus);
            const menu = getMenuById(menusClone, menuId);
            const menuIndex = getMenuIndex(menusClone, menuId);

            menu.title = menuTitle;
            menusClone[menuIndex] = menu;

            return { ...state, menus: menusClone };
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`);
        }
    }
};

function updateItemRecursively(menuItems: IMenuItem[], menuItem: IMenuItem) {
    return menuItems.map((item, idx) => {
        if (item.id === menuItem.id) {
            return { ...item, ...menuItem };
        } else if (item.id === menuItem.parentId) {
            item.items = updateItemRecursively(item.items, menuItem);

            return item;
        }
        return item;
    });
}

export {
    actionTypes,
    reducer
}
