import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UISref } from '@uirouter/react';
import ClassNames from 'classnames';
import EventService from 'eventservice';
import React from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Nav from 'reactstrap/lib/Nav';
import Navbar from 'reactstrap/lib/Navbar';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import logo from '../../images/logo.png';
import { CLOSE_SIDEBAR, TOGGLE_SIDEBAR } from '../Sidebar/events';
import UserMenu from './UserMenu';

class Header extends React.Component<{}, { isSidebarVisible: boolean; }> {
    constructor(props: any) {
        super(props);

        this.state = { isSidebarVisible: false };

        this.listenForSidebarEvents();
    }

    componentWillUnmount(): void {
        EventService.off(CLOSE_SIDEBAR);
    }

    render() {
        const navbarHeaderClasses = ClassNames({
            'navbar--header'  : true,
            'sticky-top'      : true,
            'navbar--expanded': this.state.isSidebarVisible,
        });
        const navbarToggleClasses = ClassNames({
            'navbar-toggle': true,
            'toggled'      : this.state.isSidebarVisible,
        });
        const logoClasses = ClassNames({
            'brand__img'         : true,
            'mr-2'               : true,
            'brand__img--visible': !this.state.isSidebarVisible,
        });

        return (
            <Navbar className={navbarHeaderClasses} expand="lg">
                <div className="container-fluid d-flex align-items-center">
                    <div className={navbarToggleClasses}>
                        <button type="button" className="navbar-toggler" onClick={this.toggleSidebar.bind(this)}>
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <UISref to="" className="navbar-brand d-lg-none">
                        <a href="/">
                            <img src={logo}
                                 alt="App-Constructor"
                                 className={logoClasses}
                                 height="50"
                            />
                        </a>
                    </UISref>
                    <Button className="navbar-toggler" id="topNavToggler">
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </Button>
                    <UncontrolledCollapse className="navbar-collapse justify-content-end" toggler="topNavToggler">
                        <Nav navbar>
                            <NavItem className="nav-item">
                                <NavLink href={process.env.REACT_APP_FRONTEND_URL}
                                         target="_blank"
                                         title="View your App"
                                >
                                    <FontAwesomeIcon icon={faEye} className="nav__icon" />
                                    <span className="nav__text">View your App</span>
                                </NavLink>
                            </NavItem>
                            <UserMenu />
                        </Nav>
                    </UncontrolledCollapse>
                </div>
            </Navbar>
        );
    }

    private listenForSidebarEvents() {
        EventService.on(CLOSE_SIDEBAR, async () => {
            this.setState({ isSidebarVisible: false });
        });
    }

    private toggleSidebar() {
        const isSidebarVisible = !this.state.isSidebarVisible;

        this.setState({ isSidebarVisible: isSidebarVisible });

        EventService.fire<boolean>(TOGGLE_SIDEBAR, isSidebarVisible);
    }
}

export default Header;
