import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import IPropertyConfigProps from './IPropertyConfigProps';

interface IProps extends IPropertyConfigProps {
    maxValue: number;
    minValue: number;
}

const Range = (props: IProps) => {
    const onChange = ($event) => {
        PropertyConfigService.notifyPropertyChange(props.id, props.group, $event.target.value);
    };

    return (
        <FormGroup>
            <Label>{props.label}</Label>
            <Input
                className="custom-range"
                max={props.maxValue}
                min={props.minValue}
                onChange={onChange}
                type="range"
                value={props.value}
            />
        </FormGroup>
    );
};

export default Range;
