import map from 'lodash/map';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IPropertyConfigProps from "./IPropertyConfigProps";
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";

interface IProps extends IPropertyConfigProps {
    options: any[];
}

const SelectField = (props: IProps) => {

    const dispatch = useFormDispatch();

    const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type  : actionTypes.propertyChange,
            params: {
                propertyId   : props.id,
                propertyGroup: props.group,
                value        : $event.target.value
            }
        })
    };

    return (
        <FormGroup>
            <Label>{props.label} </Label>
            <Input type="select"
                   onChange={onChange}
                   value={props.value}>
                {
                    map(props.options, (option) => {
                        return <option key={option.id} value={option.id}>{option.name}</option>;
                    })
                }
            </Input>
        </FormGroup>
    );
};

export default SelectField;
