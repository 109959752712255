import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { IMenuItem } from '../../Core/Menu/IMenu';
import MenuItemForm from './MenuItemForm';

interface IEditMenuItemModalProps {
    menuItem: IMenuItem;
    onClose: () => void;
    onSave: (menuItem: IMenuItem) => void;
}

const EditMenuItemModal = ({ menuItem, onClose, onSave }: IEditMenuItemModalProps) => {
    const closeBtn = <button className="close" onClick={onClose}>&times;</button>;

    return (
        <Modal isOpen={true} size="lg">
            <ModalHeader close={closeBtn}>Edit menu item</ModalHeader>
            <ModalBody>
                <MenuItemForm formValues={menuItem} onCancel={onClose} onSuccess={onSave} />
            </ModalBody>
        </Modal>
    );
};

export default EditMenuItemModal;
