import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Button, ButtonToolbar, Col, Container, Row } from 'reactstrap';
import { IMenuItem } from '../../Core/Menu/IMenu';
import {
    addMenuItem,
    addMenuItemChild,
    bootstrapMenuData,
    reorderMenu,
    reorderMenuItem,
    saveMenus,
    setCurrentMenuItem,
    updateMenuItem
} from '../../Core/Menu/MenuActions';
import MenuItemFactory from '../../Core/Menu/MenuItemFactory';
import { useMenuDispatch, useMenuState } from '../../Core/Menu/useMenuContext';
import { getMenuIndex, getPageById } from '../../Core/Menu/utils';
import LoadingIndicator from '../LoadingIndicator';
import EditMenuItemModal from './EditMenuItemModal';
import MenusConfig from './MenusConfig';
import MenusList from './MenusList';

const MenuManager = () => {
    const dispatch = useMenuDispatch();
    const { currentMenuItem, menus, pages } = useMenuState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        bootstrapMenuData(dispatch).then(resp => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const onDragEnd = (result: DropResult): void => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        const isMenu = (menuId: string): boolean => getMenuIndex(menus, menuId) > -1;

        switch (source.droppableId) {
            case destination.droppableId:
                if (isMenu(destination.droppableId)) {
                    reorderMenu(dispatch, { menuId: destination.droppableId, oldIndex: source.index, newIndex: destination.index });
                } else {
                    const [menuId, menuItemId] = destination.droppableId.split('_');

                    reorderMenuItem(
                        dispatch,
                        { menuId: menuId, menuItemId: menuItemId, oldIndex: source.index, newIndex: destination.index }
                    );
                }
                break;
            case 'pages':
                const page = getPageById(pages, parseInt(draggableId));

                setIsLoading(true);
                if (isMenu(destination.droppableId)) {
                    const menuItem = {
                        ...MenuItemFactory.createFromPage(page),
                        depth   : 0,
                        menuId  : destination.droppableId,
                        parentId: null,
                    };

                    addMenuItem(dispatch, { menuItem: menuItem, index: destination.index }).then(r => {
                        setCurrentMenuItem(dispatch, { menuItem: menuItem });
                        setIsLoading(false);
                    });
                } else {
                    const [menuId, menuItemId] = destination.droppableId.split('_');
                    const child = {
                        ...MenuItemFactory.createFromPage(page),
                        depth   : 1,
                        menuId  : menuId,
                        parentId: menuItemId
                    };

                    addMenuItemChild(dispatch, { child: child, index: destination.index }).then(r => {
                        setCurrentMenuItem(dispatch, { menuItem: child });
                        setIsLoading(false);
                    });
                }
                break;
            case 'customLink':
                setIsLoading(true);
                if (isMenu(destination.droppableId)) {
                    const menuItem = {
                        ...MenuItemFactory.createFromCustomLink(),
                        depth   : 0,
                        menuId  : destination.droppableId,
                        parentId: null,
                    };

                    addMenuItem(dispatch, { menuItem: menuItem, index: destination.index }).then(r => {
                        setCurrentMenuItem(dispatch, { menuItem: menuItem });
                        setIsLoading(false);
                    });
                } else {
                    const [menuId, menuItemId] = destination.droppableId.split('_');
                    const child = {
                        ...MenuItemFactory.createFromCustomLink(),
                        depth   : 1,
                        menuId  : menuId,
                        parentId: menuItemId
                    };

                    addMenuItemChild(dispatch, { child: child, index: destination.index }).then(r => {
                        setCurrentMenuItem(dispatch, { menuItem: child });
                        setIsLoading(false);
                    });
                }
                break;
            default:
                return;
        }
    };

    const onModalClose = () => {
        setCurrentMenuItem(dispatch, { menuItem: null });
    };

    const onModalSave = (menuItem: IMenuItem) => {
        updateMenuItem(dispatch, { menuItem: menuItem });
        onModalClose();
    };

    const onUpdateMenus = (menuData) => {
        setIsLoading(true);
        saveMenus(menuData).then(resp => {
            setIsLoading(false);
        });
    };

    return (
        <>
            <header className="page-header d-flex align-items-center sticky-top border-bottom">
                <h4 className="m-0">Menu Manager</h4>
                <ButtonToolbar className="page-actions ml-auto">
                    <Button
                        color="primary"
                        className="ml-1"
                        onClick={() => onUpdateMenus(menus)}
                    >Save changes</Button>
                </ButtonToolbar>
            </header>

            <DragDropContext onDragEnd={onDragEnd}>
                <Container fluid>
                    {
                        isLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <Row>
                                <Col md={9} xl={10} className="pt-3">
                                    <MenusList menus={menus} />
                                </Col>
                                <Col md={3} xl={2} className="pt-3" style={{ minHeight: '50vh' }}>
                                    <MenusConfig pages={pages} />
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </DragDropContext>

            {currentMenuItem && <EditMenuItemModal menuItem={currentMenuItem} onClose={onModalClose} onSave={onModalSave} />}
        </>
    );
};

export default MenuManager;
