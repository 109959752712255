import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import filter from 'lodash/filter';
import map from 'lodash/map';
import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { useDebounce } from '../../Core/UseDebounce';
import availableIcons from '../../icons.json';

const useInitialfocus = (ref) => {
    useEffect(() => {
        ref.current.focus();
    }, [ref]);
};
const IconsList = ({ icons, onSelectItem, selectedIcon }) => {
    const selectedIconName = selectedIcon && selectedIcon.iconName;

    return (
        <>
            <div style={{ height: 200, overflowY: 'auto' }}>
                <div className="d-flex flex-row flex-wrap justify-content-start">
                    {map(icons, (icon, idx) => {
                        const isSelected = icon.iconName === selectedIconName;
                        const fontIcon = { prefix: icon.prefix, iconName: icon.iconName };
                        const className = classNames(
                            'd-flex flex-column align-items-center justify-content-center w-25 p-3',
                            { border: isSelected }
                        );

                        return (
                            <div key={idx} className={className} onClick={() => onSelectItem(fontIcon)}>
                                <FontAwesomeIcon icon={fontIcon} size="2x" fixedWidth className="mb-2" />
                                <span>{icon.iconName}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

const IconSearchForm = ({ onChange }: { onChange: (value: string) => void }) => {
    const searchInputRef = useRef(null);

    useInitialfocus(searchInputRef);

    return (
        <>
            <Input
                onChange={(e) => onChange(e.target.value)}
                placeholder="Search icon..."
                innerRef={searchInputRef}
                type="search"
            />
        </>
    );
};

const IconPicker = ({ field, form: { touched, errors, setFieldValue }, ...props }) => {
    const [filteredIcons, setFilteredIcons] = useState(null);
    const [icon, setIcon] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isListVisible, setIsListVisible] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 100);

    useEffect(() => {
        if (debouncedSearchTerm) {
            filterList(debouncedSearchTerm);
        } else {
            setFilteredIcons(null);
            setIcon(field.value);
        }
    }, [field.value, debouncedSearchTerm]);

    const selectIcon = (icon) => {
        setIcon(icon);
        setIsListVisible(false);
        setFilteredIcons(null);
        setFieldValue(field.name, icon);
    };

    const filterList = (value: string) => {
        const filteredList = filter(availableIcons, (icon, idx) => {
            return icon.iconName.includes(value) || icon.searchTerms.some((t) => t.includes(value));
        });

        setFilteredIcons(filteredList);
    };

    return (
        <>
            <FormGroup>
                <Label htmlFor={props.id} className="d-block">{props.label}</Label>
                <Input
                    defaultValue={icon}
                    id={props.id}
                    invalid={!!(touched[field.name] && errors[field.name])}
                    name={field.name}
                    type="hidden"
                />
                <Button type="button" id="showList" onClick={() => setIsListVisible(true)}>
                    {icon
                     ? <>
                         <FontAwesomeIcon icon={icon} size="lg" className="mr-2" />
                         {icon.iconName}
                         <FontAwesomeIcon icon={faPencilAlt} className="ml-4" title="Change icon" />
                     </> : 'Add icon'}
                </Button>
                {(!icon && errors[field.name]) && <FormFeedback>{errors[field.name]}</FormFeedback>}
            </FormGroup>

            <Popover isOpen={isListVisible} target="#showList" placement="right" modifiers={{
                fixedWidth: {
                    enabled: true,
                    fn     : (data) => {
                        return { ...data, styles: { ...data.styles, height: '300px', maxWidth: '500px', width: '500px' } };
                    },
                    order  : 840,
                }
            }}>
                <PopoverHeader>Icon picker</PopoverHeader>
                <PopoverBody>
                    <IconSearchForm onChange={setSearchTerm} />
                    {filteredIcons && <IconsList icons={filteredIcons} selectedIcon={icon} onSelectItem={selectIcon} />}
                </PopoverBody>
            </Popover>
        </>
    );
};

export default IconPicker;
