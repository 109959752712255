import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faPassport } from "@fortawesome/free-solid-svg-icons/faPassport";
import { faRadiation } from "@fortawesome/free-solid-svg-icons/faRadiation";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons/faSortAmountDown";
import { faSortNumericUp } from "@fortawesome/free-solid-svg-icons/faSortNumericUp";
import { faTextHeight } from "@fortawesome/free-solid-svg-icons/faTextHeight";
import { faTextWidth } from "@fortawesome/free-solid-svg-icons/faTextWidth";
import { faUserClock } from "@fortawesome/free-solid-svg-icons/faUserClock";
import forEach from 'lodash/forEach';
import has from 'lodash/has';
import IProperty from './IProperty';
import IWidget from './IWidget';

const DEFAULT_IMAGE = 'https://via.placeholder.com/500x100?text=IMAGE';
const ALIGNMENT_CENTER = 'center';
const ALIGNMENT_LEFT = 'left';

export const widgets = [
    {
        id    : 'text',
        icon  : faTextHeight,
        label : 'Text',
        type  : 'text',
        values: {}
    },
    {
        id    : 'radio',
        icon  : faRadiation,
        label : 'Radio',
        type  : 'radio',
        values: {}
    },
    {
        id    : 'checkbox',
        icon  : faCheckCircle,
        label : 'Checkbox',
        type  : 'checkbox',
        values: {}
    },
    {
        id    : 'password',
        icon  : faPassport,
        label : 'Password',
        type  : 'password',
        values: {}
    },
    {
        id    : 'number',
        icon  : faSortNumericUp,
        label : 'Numeric',
        type  : 'number',
        values: {}
    },
    {
        id    : 'email',
        icon  : faAddressCard,
        label : 'Email',
        type  : 'email',
        values: {}
    },
    {
        id    : 'date',
        icon  : faCalendarDay,
        label : 'Date',
        type  : 'date',
        values: {}
    },
    {
        id    : 'time',
        icon  : faUserClock,
        label : 'Time',
        type  : 'time',
        values: {}
    },
    {
        id    : 'textarea',
        icon  : faTextWidth,
        label : 'Textarea',
        type  : 'textarea',
        values: {}
    },
    {
        id    : 'select',
        icon  : faSortAmountDown,
        label : 'Select',
        type  : 'select',
        values: {}
    },
    {
        id    : 'button',
        icon  : faBuilding,
        label : 'Submit',
        type  : 'button',
        values: {}
    },
] as IWidget[];

export const widgetsProperties = {
    text    : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label      : {
                    id          : 'label',
                    label       : 'Label',
                    defaultValue: 'Text input label',
                    type        : 'text'
                } as IProperty,
                placeholder: {
                    id          : 'placeholder',
                    label       : 'Placeholder',
                    defaultValue: 'Input placeholder',
                    type        : 'text'
                } as IProperty,
                name       : {
                    id          : 'name',
                    label       : 'Name',
                    defaultValue: 'name',
                    type        : 'text'
                } as IProperty,
                required   : {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                // margin : {
                //     id          : 'margin',
                //     label       : 'Margin',
                //     defaultValue: '0px',
                //     type        : 'margin'
                // } as IProperty,
                // padding: {
                //     id          : 'padding',
                //     label       : 'Padding',
                //     defaultValue: '0px',
                //     type        : 'padding'
                // } as IProperty,
            }
        }
    },
    email   : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label      : {
                    id          : 'label',
                    label       : 'Label',
                    defaultValue: 'Email label',
                    type        : 'text'
                } as IProperty,
                placeholder: {
                    id          : 'placeholder',
                    label       : 'Placeholder',
                    defaultValue: 'Email placeholder',
                    type        : 'text'
                } as IProperty,
                name       : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'email',
                    type        : 'text'
                } as IProperty,
                required   : {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                // margin : {
                //     id          : 'margin',
                //     label       : 'Margin',
                //     defaultValue: '0px',
                //     type        : 'margin'
                // } as IProperty,
                // padding: {
                //     id          : 'padding',
                //     label       : 'Padding',
                //     defaultValue: '0px',
                //     type        : 'padding'
                // } as IProperty,
            }
        }
    },
    radio   : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Radio label',
                    defaultValue: 'Radio label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'radio',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
                options : {
                    id          : 'options',
                    label       : 'Options',
                    defaultValue: [{ value: 1, label: 'Radio option' }],
                    type        : 'options'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
            }
        }
    },
    checkbox: {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Checkbox label',
                    defaultValue: 'Checkbox label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'checkbox',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
            }
        }
    },
    password: {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label      : {
                    id          : 'label',
                    label       : 'Password label',
                    defaultValue: 'Password label',
                    type        : 'text'
                } as IProperty,
                name       : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'password',
                    type        : 'text'
                } as IProperty,
                placeholder: {
                    id          : 'placeholder',
                    label       : 'Placeholder',
                    defaultValue: 'Type a password',
                    type        : 'text'
                } as IProperty,
                required   : {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Label color',
                    type        : 'colorPicker'
                } as IProperty,
                // margin : {
                //     id          : 'margin',
                //     label       : 'Margin',
                //     defaultValue: '0px',
                //     type        : 'margin'
                // } as IProperty,
                // padding: {
                //     id          : 'padding',
                //     label       : 'Padding',
                //     defaultValue: '0px',
                //     type        : 'padding'
                // } as IProperty,
            }
        }
    },
    number  : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Number label',
                    defaultValue: 'Number label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'number',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
            }
        }
    },
    date    : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Date label',
                    defaultValue: 'Date label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'date',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
            }
        }
    },
    time    : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Time label',
                    defaultValue: 'Time label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'date',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
            }
        }
    },
    textarea: {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Textarea label',
                    defaultValue: 'Textarea label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'textarea',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color: {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
            }
        }
    },
    select  : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label   : {
                    id          : 'label',
                    label       : 'Select label',
                    defaultValue: 'Select label',
                    type        : 'text'
                } as IProperty,
                name    : {
                    id          : 'name',
                    label       : 'Field name',
                    defaultValue: 'select',
                    type        : 'text'
                } as IProperty,
                required: {
                    id          : 'required',
                    label       : 'Is required',
                    defaultValue: false,
                    type        : 'checkbox'
                } as IProperty,
                options : {
                    id          : 'options',
                    label       : 'Options',
                    defaultValue: [{ value: 1, label: 'Option 1' }, { value: 2, label: 'Option 2' }],
                    type        : 'options'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color : {
                    defaultValue: 'rgb(110,123,142, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                margin: {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
            }
        }
    },
    button  : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label      : {
                    id          : 'label',
                    label       : 'Label',
                    defaultValue: 'Submit',
                    type        : 'text'
                } as IProperty,
                submitEmail: {
                    id          : 'submitEmail',
                    label       : 'Email submit action',
                    defaultValue: 'email@example.com',
                    type        : 'text'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color      : {
                    defaultValue: 'rgb(40,135,198, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                borderColor: {
                    defaultValue: 'rgb(40,135,198, 1)',
                    id          : 'borderColor',
                    label       : 'Border color',
                    type        : 'colorPicker'
                } as IProperty,
                textColor  : {
                    defaultValue: 'rgb(255,255,255, 1)',
                    id          : 'textColor',
                    label       : 'Text color',
                    type        : 'colorPicker'
                } as IProperty,
                alignment  : {
                    defaultValue: ALIGNMENT_LEFT,
                    id          : 'alignment',
                    label       : 'Align',
                    type        : 'alignmentPicker'
                } as IProperty,
                margin     : {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
            }
        },
    },
};

export function getWidgetDefaultValues(widgetType: string) {
    const defaultValues = {};
    const widgetProperties = widgetsProperties[widgetType];

    forEach(widgetProperties, (group, groupKey: string) => {
        defaultValues[groupKey] = {};

        forEach(group.items, (property: IProperty, propertyKey: string) => {
            if (!has(property, 'defaultValue')) {
                throw new Error(`No default value for "${widgetType}.${groupKey}.${propertyKey}".`);
            }

            defaultValues[groupKey][propertyKey] = property.defaultValue;
        });
    });

    return defaultValues;
}
