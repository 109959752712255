import autoBind from 'react-autobind';
import React from 'react';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import { UIView } from '@uirouter/react';
import PageGrid from '../Components/PageGrid';
import Router from '../Core/Router';
import { PageProvider } from "../Core/Page/PageContext";
import { FormProvider } from "../Core/Form/FormContext";

class Page extends React.Component<any, { reloadGrid: boolean; }> {
    constructor(props) {
        super(props);
        autoBind(this, 'onAddPage', 'onGridReady');

        this.state = { reloadGrid: false };
    }

    render() {
        return (
            <PageProvider>
                <FormProvider>
                <UIView>
                    <>
                        <div className="content">
                            <Card>
                                <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                                    <h4 className="m-0">Pages</h4>
                                    <Button
                                        className="ml-auto"
                                        color="info"
                                        size="sm"
                                        onClick={this.onAddPage}
                                    >Create new page</Button>
                                </CardHeader>
                                <CardBody>
                                    <PageGrid
                                        onReady={this.onGridReady}
                                        reload={this.state.reloadGrid}
                                    />
                                </CardBody>
                            </Card>
                        </div>
                    </>
                </UIView>
                </FormProvider>
            </PageProvider>
        );
    }

    private onAddPage(): void {
        Router.stateService.go('pages.create');
    }

    private onGridReady() {
        this.setState({ reloadGrid: false });
    }
}

export default Page;
