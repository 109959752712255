// @ts-ignore
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { ReactstrapInput } from 'reactstrap-formik';
import Button from 'reactstrap/lib/Button';
import MessageHandler from '../../Core/MessageHandler';
import UserService from '../../Core/User/UserService';
import Yup from '../../Core/YupValidator';
import ButtonWithLoader from '../ButtonWithLoader';
import PasswordMeterInput from '../PasswordMeterInput';

const formValues = {
    firstName: '',
    lastName : '',
    email    : '',
    password : '',
    active   : 1
};

const CreateUserSchema = Yup.object().shape({
    firstName: Yup.string()
                  .min(2, 'The field must have min. 2 characters!')
                  .max(60, 'The field must have max. 50 characters!')
                  .required('Required'),
    lastName : Yup.string()
                  .min(2, 'The field must have min. 2 characters!')
                  .max(60, 'The field must have max. 60 characters!')
                  .required('Required'),
    email    : Yup.string()
                  .email('The email address is invalid!')
                  .required('Required'),
    password : Yup.string()
                  .min(6, 'The field must have min. 6 characters!')
                  .required('Required')
                  .strength('The password is weak!'),
});

const CreateUserForm = (props: { onCancel: Function, onSuccess: Function }) => {
    const { onCancel, onSuccess } = props;

    return (
        <Formik initialValues={formValues}
                validationSchema={CreateUserSchema}
                onSubmit={(values, { setSubmitting }) => {
                    UserService.createUser(values)
                               .then((response) => {
                                   setSubmitting(false);
                                   MessageHandler.displaySuccessMessage('The user has been created successfully.', 5);
                                   onSuccess();
                               })
                               .catch(error => {
                                   setSubmitting(false);
                                   // if (error.response.status === 404) {
                                   //     MessageHandler.displayErrorMessage('User not found.');
                                   //     Router.stateService.go('users');
                                   // }
                                   //
                                   // if (error.response.status === 400) {
                                   //     MessageHandler.displayException(error.response.data['violations']);
                                   // }
                               });
                }}
        >
            {
                (props) => {
                    const { isSubmitting } = props;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                type="text"
                                id="firstName"
                                label="First name"
                                maxLength="60"
                                name="firstName"
                                component={ReactstrapInput}
                            />
                            <Field
                                type="text"
                                id="lastName"
                                label="Last name"
                                maxLength="60"
                                name="lastName"
                                component={ReactstrapInput}
                            />
                            <Field
                                type="text"
                                id="email"
                                label="Email address"
                                name="email"
                                component={ReactstrapInput}
                            />
                            <Field
                                type="password"
                                id="password"
                                label="Password"
                                name="password"
                                withSuggestions={false}
                                component={PasswordMeterInput}
                            />
                            <div className="form-actions d-flex align-items-center justify-content-end">
                                <Button
                                    color="link"
                                    className="text-secondary"
                                    onClick={() => onCancel()}>
                                    Cancel
                                </Button>
                                {' '}
                                <ButtonWithLoader
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    text="Save user"
                                />
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default CreateUserForm;
