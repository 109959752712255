import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import MediaUploader from '../MediaUploader';
import IPropertyConfigProps from './IPropertyConfigProps';

const ImageUrl = (props: IPropertyConfigProps) => {
    const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
        PropertyConfigService.notifyPropertyChange(props.id, props.group, $event.target.value);
    };

    const onFilesUploaded = (mediaObjects) => {

        PropertyConfigService.notifyPropertyChange(props.id, props.group, mediaObjects[0]['fileUrl']);
    };

    return (
        <>
            <FormGroup>
                <MediaUploader
                    autoUpload={true}
                    onFilesUploaded={onFilesUploaded}
                    showFilePreview={false}
                />
            </FormGroup>

            <FormGroup>
                <Label>{props.label}</Label>
                <Input
                    onChange={onChange}
                    placeholder="Image URL"
                    type="text"
                    value={props.value}
                />
            </FormGroup>
        </>
    );
};

export default ImageUrl;
