import FormService from "../Form/FormService";

const pageActions = {
    getForms: 'getForms',
};

interface IPayload {
    type: string;
    params: any
}

const reducer = (state: any, {type, params}: IPayload) => {
    switch (type) {
        case pageActions.getForms: {
            FormService.getForms().then(({data}) => {
                state.forms = data;
            });

            return {
                ...state
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${type}`);
        }
    }
};

export {
    pageActions,
    reducer
}
