import App from './App';
import AxiosClient from './Core/AxiosClient';
import HttpRouter from './Core/HttpRouter';
import IconFonts from './Core/IconFonts';
import AuthService from './Core/Security/AuthenticationService';

HttpRouter.init(process.env.REACT_APP_API_URL);
AxiosClient.init();
AuthService.init();
IconFonts.init();
App.init();
