import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DraggableHoc from 'Components/DraggableHoc';
import DroppableHoc from 'Components/DroppableHoc';
import map from 'lodash/map';
import React, { memo, useState } from 'react';
import { Collapse, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { IPage } from '../../Core/Menu/MenuContext';
import Scrollbar from '../Scrollbar';

const ConfigItem = ({ title }: { title: string }) => (
    <>
        <FontAwesomeIcon icon={faExpandArrowsAlt} className="mr-3" fixedWidth />
        {title}
    </>
);
const Pages = ({ pages }: { pages: IPage[] }) => (
    <ListGroup className="mt-3">
        {map(pages, (page, idx) => (
            <DraggableMenuItem
                key={`page_${page.id}`}
                className="list-group-item bg-light mb-1"
                draggableId={page.id}
                index={idx}
                title={page.title}
            />
        ))}
    </ListGroup>
);
const CustomLink = () => (
    <ListGroup className="mt-3">
        <DraggableMenuItem
            className="list-group-item bg-light mb-1"
            draggableId="customLink"
            index={1000}
            title="Custom link"
        />
    </ListGroup>
);

const DraggableMenuItem = DraggableHoc(ConfigItem);
const DroppablePages = DroppableHoc(Pages);
const DroppableCustomLink = DroppableHoc(CustomLink);

const MenusConfig = ({ pages }: { pages: IPage[] }) => {
    const [isOpen, setIsOpen] = useState({ pages: true, otherOptions: false });

    return (
        <aside className="bg-white h-100">
            <header className="border-bottom p-3">
                <h5 className="m-0">Available menu content</h5>
                <h6 className="m-0">
                    <small>Drag an item to add it to the menu</small>
                </h6>
            </header>
            <Scrollbar noScrollX={true}>
                <div className="h-100 pb-3">
                    <ListGroup flush>
                        <ListGroupItem>
                            <ListGroupItemHeading className="m-0 caret d-flex"
                                                  onClick={() => setIsOpen({ ...isOpen, pages: !isOpen.pages })}>
                                Pages
                                <FontAwesomeIcon icon={isOpen.pages ? faChevronUp : faChevronDown} className="ml-auto" />
                            </ListGroupItemHeading>
                            <Collapse isOpen={isOpen.pages}>
                                <DroppablePages
                                    droppableId="pages"
                                    isDropDisabled={true}
                                    type="menuItem"
                                    pages={pages}
                                />
                            </Collapse>
                        </ListGroupItem>
                        <ListGroupItem>
                            <ListGroupItemHeading className="m-0 caret d-flex"
                                                  onClick={() => setIsOpen({ ...isOpen, otherOptions: !isOpen.otherOptions })}>
                                Other options
                                <FontAwesomeIcon icon={isOpen.otherOptions ? faChevronUp : faChevronDown} className="ml-auto" />
                            </ListGroupItemHeading>
                            <Collapse isOpen={isOpen.otherOptions}>
                                <DroppableCustomLink
                                    droppableId="customLink"
                                    isDropDisabled={true}
                                    type="menuItem"
                                />
                            </Collapse>
                        </ListGroupItem>
                    </ListGroup>
                </div>
            </Scrollbar>
        </aside>
    );
};

export default memo(MenusConfig);
