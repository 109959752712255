import classNames from 'classnames';
import EventService from 'eventservice';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { COPY_WIDGET, DELETE_WIDGET, EDIT_WIDGET } from '../../../Core/Page/events';
import ButtonCopy from '../ActionButtons/ButtonCopy';
import ButtonDelete from '../ActionButtons/ButtonDelete';
import ButtonDrag from '../ActionButtons/ButtonDrag';
import ButtonEdit from '../ActionButtons/ButtonEdit';
import IDraggableWidgetProps from './IDraggableWidgetProps';
import IWidgetActionProps from './IWidgetActionProps';

interface IProps {
    draggableWidgetProps: IDraggableWidgetProps

    [propName: string]: any;
}

const getElementClasses = (isSelected: boolean) => {
    return classNames({
        'preview-element__selectable': true,
        'preview-element--selected'  : isSelected
    });
};

const onCopy = (widgetActionProps: IWidgetActionProps) => {
    EventService.fire(COPY_WIDGET, widgetActionProps);
};

const onDelete = (widgetActionProps: IWidgetActionProps) => {
    EventService.fire(DELETE_WIDGET, widgetActionProps);
};

const onEdit = (widgetActionProps: IWidgetActionProps) => {
    EventService.fire(EDIT_WIDGET, widgetActionProps);
};

export default (WrappedComponent) => {
    const DraggableWidget = ({ columnIndex, id, index, isSelected, sectionId, ...props }: IProps) => {
        const widgetActionProps = {
            columnIndex: columnIndex,
            sectionId  : sectionId,
            widgetId   : id
        } as IWidgetActionProps;

        const editVisible = (props.type !== 'form');
        const copyVisible = (props.type !== 'form');

        return (
            <Draggable draggableId={id} index={index}>
                {({ innerRef, draggableProps, dragHandleProps }) => (
                    <div {...draggableProps} ref={innerRef}>
                        <WrappedComponent {...props} />

                        <div className={getElementClasses(isSelected)}>
                            <div className="preview-element__actions">
                                <ButtonDrag dragHandleProps={dragHandleProps} />
                                {editVisible ? <ButtonEdit onClick={() => onEdit(widgetActionProps)} /> : ''}
                                {copyVisible ? <ButtonCopy onClick={() => onCopy(widgetActionProps)} /> : ''}
                                <ButtonDelete onClick={() => onDelete(widgetActionProps)} />
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    DraggableWidget.displayName = `DraggableWidgetHoc(${WrappedComponent.name || 'Component'})`;

    return DraggableWidget;
};
