import map from 'lodash/map';
import React from 'react';
import { getValueForProperty } from '../../../Core/Page/properties';
import Section from './Section';

interface ISectionsList {
    sections: Array<any>;
    selectedSection: number;
    selectedWidget: number;
}

const getSections = (sections, selectedSection, selectedWidget) => map(sections, (section, idx) => {
    const { properties, values, content } = section;
    const isSelected = selectedSection === idx;
    const styles = {
        backgroundColor: getValueForProperty(values, 'general', 'bgColor'),
        margin         : getValueForProperty(values, 'general', 'margin')
    };

    return (
        <div key={section.id} className="preview-element" style={styles}>
            <Section
                droppableId={section.id}
                id={section.id}
                index={idx}
                isSelected={isSelected}
                properties={properties}
                selectedWidget={selectedWidget}
                widgets={content}
                values={values}
            />
        </div>
    );
});

const SectionsList = (props: ISectionsList) => {
    const { sections, selectedSection, selectedWidget } = props;

    return (
        <>
            {getSections(sections, selectedSection, selectedWidget)}
        </>
    );
};

// export default React.memo(SectionsList);
export default SectionsList;
