import axios from 'axios';
import { IGridRequestParams } from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';

interface IPageDetails {
    content: string;
    title: string;
    slug: string;
    relations: Array<{ type, id }> | null;
}

class PageService {
    public createPage(page: IPageDetails): Promise<any> {

        const payload = {
            content  : page.content,
            title    : page.title,
            id       : null,
            relations: page.relations,
            slug     : page.slug
        };

        const url = HttpRouter.generate('save_page');

        return axios.post(url, { page: payload });
    }

    public updatePage(pageId: number, page: IPageDetails): Promise<any> {

        const payload = {
            id       : pageId,
            content  : page.content,
            title    : page.title,
            slug     : page.slug,
            relations: page.relations
        };

        const url = HttpRouter.generate('save_page');

        return axios.post(url, { page: payload });
    }

    public getPage(pageId: number): Promise<any> {
        const url = HttpRouter.generate('get_page', { id: pageId });

        return axios.get(url);
    }

    public getAllPages(): Promise<any> {
        const url = HttpRouter.generate('get_all_pages');

        return axios.get(url);
    }

    public pagesDs(params: IGridRequestParams): Promise<any> {

        const url = HttpRouter.generate('page_ds');

        return axios.post(url, params);
    }
}

export default new PageService();
