import map from 'lodash/map';
import React from 'react';
import { toast, TypeOptions } from 'react-toastify';

const TOAST_ID = 'toast-msg';

const MessageHandler = {
    closeAll             : () => toast.dismiss(),
    displayException     : (violations: any[]) => {
        const errors = map(violations, (preview, key) => (
            <div key={`error_${key}`}>
                <strong>{preview.propertyPath}</strong>: {preview.message}
            </div>
        ));

        toast(<>{errors}</>, {
            autoClose: 5000,
            type     : toast.TYPE.ERROR,
        });
    },
    displayErrorMessage  : (message: string) => {
        toast(message, {
            toastId: TOAST_ID,
            type   : toast.TYPE.ERROR,
        });
    },
    displayMessage       : (message: string) => {
        toast(message, {
            toastId     : TOAST_ID,
            pauseOnHover: false,
        });
    },
    displaySuccessMessage: (message: string, autoClose?: number) => {
        toast(message, {
            autoClose: autoClose ? (autoClose * 1000) : false,
            toastId  : TOAST_ID,
            type     : toast.TYPE.SUCCESS,
        });
    },
    displayWarningMessage: (message: string) => {
        toast(message, {
            toastId: TOAST_ID,
            type   : toast.TYPE.WARNING,
        });
    },
    displayInfoMessage: (message: string, autoClose?: number) => {
        toast(message, {
            autoClose: autoClose ? (autoClose * 1000) : false,
            toastId: TOAST_ID,
            type   : toast.TYPE.INFO,
        });
    },
    update               : (message: string, type: TypeOptions, autoClose?: number) => toast.update(TOAST_ID, {
        autoClose: autoClose ? (autoClose * 1000) : false,
        render   : message,
        type     : type
    }),
};

export default MessageHandler;
