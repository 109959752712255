import clone from 'lodash/clone';
import React, {useState} from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardFooter from 'reactstrap/lib/CardFooter';
import CardHeader from 'reactstrap/lib/CardHeader';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import CustomInput from 'reactstrap/lib/CustomInput';
import Row from 'reactstrap/lib/Row';
import ButtonWithLoader from '../Components/ButtonWithLoader';
import EmailFilter from '../Components/Notification/EmailFilter';
import NotificationMessage from '../Components/Notification/NotificationMessage';
import OfficesFilter from '../Components/Notification/OfficesFilter';
import MessageHandler from '../Core/MessageHandler';
import {saveNotification} from '../Core/Notifications/NotificationService';
import * as NotificationValidator from '../Core/Notifications/NotificationValidator';
import {buildRequest} from '../Core/Notifications/ultils';
import IOffice from '../Core/Office/IOffice';

interface IOfficeOption extends IOffice {
    id: number;
}

interface IUserOption {
    id: string;
    email: string;
}

const initialNotification = {
    filters: {
        findByOfficeIds: [],
        findByIds      : [],
    },
    message: '',
};

const Notifications = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [filtersEnabled, setFiltersEnabled] = useState(true);
    const [notification, setNotification] = useState(initialNotification);
    const [filters, setFilters] = useState(initialNotification.filters);

    const onSendNotification = () => {
        setIsLoading(true);

        // build the notification request
        const data = buildRequest(notification);

        // validate data
        NotificationValidator
            .validate(data, {filtersEnabled})
            .then(resp => {
                // send the request
                saveNotification(data)
                    .then(() => {
                        MessageHandler.displaySuccessMessage('The notification has been sent successfully.', 5);

                        setFilters(initialNotification.filters);
                        setNotification(initialNotification);

                        setIsLoading(false);
                    })
                    .catch(() => {
                        MessageHandler.displayErrorMessage('The notification could not be sent.');
                        setIsLoading(false);
                    });
            })
            .catch(error => {
                MessageHandler.displayErrorMessage(error.errors[0]);
                setIsLoading(false);
            });
    };

    const onOfficeChange = (offices: IOfficeOption[]) => {
        setFilter('findByOfficeIds', offices);
    };

    const onEmailChange = (users: IUserOption[]) => {
        setFilter('findByIds', users);
    };

    const setFilter = (name: string, value: any) => {
        const filtersCopy = clone(filters);
        filtersCopy[name] = value;

        setFilters({...filtersCopy});
        setNotification({...notification, filters: filtersCopy});
    };

    const toggleFilters = () => {
        const toggle = !filtersEnabled;

        if (toggle) {
            setNotification({...notification, filters: filters});
        } else {
            setNotification({...notification, filters: initialNotification.filters});
        }

        setFiltersEnabled(toggle);
    };

    return (
        <>
            <header className="page-header d-flex align-items-center border-bottom mb-5">
                <h4 className="m-0">Notifications</h4>
            </header>
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <Card className="h-100">
                            <CardHeader className="d-flex align-items-center">
                                <h6 className="font-weight-bold m-0 text-uppercase">Users</h6>
                                <div className="ml-auto d-flex">
                                    All
                                    <CustomInput
                                        className="ml-2"
                                        id="enableFilters"
                                        type="switch"
                                        defaultChecked={true}
                                        onChange={() => toggleFilters()}
                                    />
                                    Custom
                                </div>
                            </CardHeader>
                            <CardBody>
                                <OfficesFilter
                                    isDisabled={!filtersEnabled}
                                    value={filters.findByOfficeIds}
                                    onChange={(val) => onOfficeChange(val)}
                                />

                                <EmailFilter
                                    isDisabled={!filtersEnabled}
                                    onChange={(val) => onEmailChange(val)}
                                    value={filters.findByIds}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <Card>
                            <CardHeader>
                                <h6 className="font-weight-bold m-0 text-uppercase">Message</h6>
                            </CardHeader>
                            <CardBody>
                                <NotificationMessage
                                    defaultValue={notification.message}
                                    onChange={message => {
                                        setNotification({...notification, message: message})
                                    }}
                                />
                            </CardBody>
                            <CardFooter className="text-right">
                                <ButtonWithLoader
                                    size="lg"
                                    color="primary"
                                    text="Send"
                                    isLoading={isLoading}
                                    onClick={onSendNotification}
                                />
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Notifications;
