import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, CustomInput, Form, FormGroup, Label } from 'reactstrap';
import { bootstrapConfigData, saveConfig } from '../../Core/AppConfig/ConfigService';
import { IPage } from '../../Core/Menu/MenuContext';
import MessageHandler from '../../Core/MessageHandler';
import LoadingIndicator from '../LoadingIndicator';

export interface IAppConfigData {
    value: any
}

interface IAppConfig {
    homepage: { name: string, content: IAppConfigData };
    public_app: { name: string, content: IAppConfigData };
}

const initialConfig = {
    homepage  : { name: 'homepage', content: { value: '' } },
    public_app: { name: 'public_app', content: { value: false } },
} as IAppConfig;

const AppConfig: React.FC = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [config, setConfig] = useState(initialConfig);
    const [pageOptions, setPageOptions] = useState([]);

    function getConfigOptions(configData: any[]): IAppConfig {
        return keyBy(configData, 'name') as IAppConfig;
    }

    useEffect(() => {
        setIsLoading(true);

        bootstrapConfigData()
            .then(responses => {
                const [configResponse, pagesResponse] = responses;

                setConfig(getConfigOptions(configResponse.configs));
                setPageOptions(pagesResponse.pages);
                setIsLoading(false);
            });
    }, []);

    const onSave = (configName: string, value: any) => {
        MessageHandler.closeAll();

        if (value === '') {
            MessageHandler.displayErrorMessage('You have to provide a value.');
            return false;
        }

        MessageHandler.displayMessage('Loading, please wait...');

        saveConfig(configName, { value })
            .then(resp => {
                MessageHandler.update('The config has been updated successfully.', 'success', 5);
            })
            .catch((error) => {
                MessageHandler.update('The config could not be updated.', 'error');
            });
    };

    return (
        <>
            <header className="page-header d-flex align-items-center sticky-top border-bottom mb-5">
                <h4 className="m-0">Application Config</h4>
            </header>
            <Container className="d-flex flex-column align-items-center" fluid>
                {
                    isLoading ? (
                        <LoadingIndicator />
                    ) : (
                        <Card className="w-50">
                            <CardBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="public_app">App visibility</Label>
                                        <div>
                                            <CustomInput
                                                type="switch"
                                                id="public_app"
                                                label="Enable public registration?"
                                                name="public_app"
                                                defaultChecked={config && config.public_app && config.public_app.content.value}
                                                onChange={e => onSave(config.public_app.name, e.target.checked)}
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="homepage">Select homepage</Label>
                                        <CustomInput
                                            type="select"
                                            id="homepage"
                                            name="homepage"
                                            defaultValue={config && config.homepage && config.homepage.content.value}
                                            onChange={e => onSave(config.homepage.name, e.target.value)}>
                                            <option value="">Select a page</option>
                                            {
                                                map(pageOptions, (page: IPage) => (
                                                    <option value={page.id} key={`page_${page.id}`}>{page.title}</option>
                                                ))
                                            }
                                        </CustomInput>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    )
                }
            </Container>
        </>
    );
};

export default AppConfig;
