// @ts-ignore
import autoBind from 'react-autobind';
// @ts-ignore
import { TrashablePromise } from 'trashable';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import React from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import { UISref } from '@uirouter/react';
import MediaFileGrid from '../Components/MediaFileGrid';
import MediaUploaderService from '../Core/Media/MediaUploaderService';
import MessageHandler from '../Core/MessageHandler';

class MediaUploader extends React.Component<{ registerPromise?: TrashablePromise }, { reloadGrid: boolean; }> {
    constructor(props: any) {
        super(props);
        autoBind(this);

        this.state = { reloadGrid: false };
    }

    render() {
        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                            <h4 className="m-0">Media files</h4>
                            <div className="ml-auto">
                                <UISref to="media_uploader_add" className="btn btn-info btn-sm">
                                    <a href="/add-files">Add more files</a>
                                </UISref>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <MediaFileGrid
                                onDelete={this.onDeleteFile}
                                onReady={this.onGridReady}
                                reload={this.state.reloadGrid}
                            />
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }

    private onDeleteFile(mediaFile): void {
        this.props.registerPromise(
            MediaUploaderService.deleteMediaFile(mediaFile.id),
        ).then(({ data }) => {
            this.setState({ reloadGrid: true });

            MessageHandler.displaySuccessMessage('The media file has been deleted successfully.', 5);
        });
    }

    private onGridReady() {
        this.setState({ reloadGrid: false });
    }
}

export default makeComponentTrashable(MediaUploader);
