// @ts-ignore
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import FullnameModal from '../Components/User/FullnameModal';
import PasswordModal from '../Components/User/PasswordModal';
import UserGrid from '../Components/User/UserGrid';
import MessageHandler from '../Core/MessageHandler';
import IUser from '../Core/User/IUser';
import UserService from '../Core/User/UserService';

interface IUserState {
    reloadGrid: boolean;
    showPasswordModal: boolean;
    showUserModal: boolean;
}

class User extends Component<{}, IUserState> {
    protected user: IUser;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.user = null;
        this.state = {
            reloadGrid       : false,
            showPasswordModal: false,
            showUserModal    : false
        };
    }

    render() {
        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                            <h4 className="m-0">Admin Users</h4>
                            <Button
                                className="ml-auto"
                                color="info"
                                size="sm"
                                onClick={this.onAddUser}
                            >Create new user</Button>
                        </CardHeader>
                        <CardBody>
                            <UserGrid
                                onChangePassword={this.onChangePassword}
                                onDelete={this.onDeleteUser}
                                onEdit={this.onEditUser}
                                onReady={this.onGridReady}
                                reload={this.state.reloadGrid}
                            />
                        </CardBody>
                    </Card>
                </div>

                {
                    this.state.showUserModal ? (
                        <FullnameModal
                            data={this.user}
                            onClose={this.onUserModalClose}
                        />
                    ) : null
                }

                {
                    this.state.showPasswordModal ? (
                        <PasswordModal
                            data={this.user}
                            onClose={this.onPasswordModalClose}
                        />
                    ) : null
                }
            </>
        );
    }

    protected onChangePassword(user: IUser): void {
        this.user = user;
        this.setState({ showPasswordModal: true });
    }

    private onAddUser(): void {
        this.user = null;
        this.setState({ showUserModal: true });
    }

    private onDeleteUser(user: IUser): void {
        // @ts-ignore
        this.props.registerPromise(
            UserService.deleteUser(user.id)
        ).then((response) => {
            this.setState({ reloadGrid: true });

            MessageHandler.displaySuccessMessage('User deleted with success.');
        });
    }

    private onEditUser(user: IUser): void {
        this.user = user;
        this.setState({ showUserModal: true });
    }

    private onGridReady() {
        this.setState({ reloadGrid: false });
    }

    private onPasswordModalClose(shouldReloadGrid: boolean = false): void {
        this.setState({
            showPasswordModal: false,
            reloadGrid       : shouldReloadGrid,
        });
    }

    private onUserModalClose(shouldReloadGrid: boolean = false): void {
        this.setState({
            showUserModal: false,
            reloadGrid   : shouldReloadGrid,
        });
    }
}

export default makeComponentTrashable(User);
