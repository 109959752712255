import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import merge from 'lodash/merge';
import size from 'lodash/size';
import React, { useEffect, useState } from 'react';
import LoadingIndicator from '../Components/LoadingIndicator';
import PageEditor from '../Components/PageEditor/PageEditor';
import FormService from '../Core/Form/FormService';
import { FORM } from '../Core/Page/constants';
import PageService from '../Core/Page/PageService';
import { getSectionDefaultValues } from '../Core/Page/sections';
import { getWidgetDefaultValues } from '../Core/Page/widgets';

const getSectionsConfig = (sectionValues: object) => {
    const defaultValues = getSectionDefaultValues();

    return merge({}, defaultValues, sectionValues);
};

const getWidgetsConfig = (widgets: any[]) => {
    return widgets.map(widget => {
        const widgetCopy = cloneDeep(widget);
        const defaultValues = getWidgetDefaultValues(widget.type);

        widgetCopy.values = merge({}, defaultValues, widget.values);

        return widgetCopy;
    });
};

// Transform existing form structure to be similar with form functionality
export const buildExistingFormContent = (form) => {

    let formObject = {
        button : {},
        fields : {
            fieldsList: []
        },
        general: {},
        type   : 'form',
        formId : form.id
    };

    map(form.content, (field, index) => {
        let fieldToBuild = {
            id  : field.id,
            type: field.type
        };

        map(field.values, (fieldGroup, idx) => {
            map(fieldGroup, (fieldValue, fieldKey) => {
                fieldToBuild[fieldKey] = fieldValue;
            });
        });

        if (field.type === 'button') {
            formObject['button'] = fieldToBuild;
        } else {
            formObject.fields.fieldsList.push(fieldToBuild);
        }
    });

    return formObject;
};

const getPageContent = (sections: any[], forms: Array<{ id, name, content }>) => sections.map(section => {
    const sectionCopy = cloneDeep(section);
    const content = section.content.map(column => getWidgetsConfig(column));
    const values = getSectionsConfig(section.values);

    each(content, (widgetsList, index) => {
        each(widgetsList, (widget, widgetIndex) => {
            if (widget.type === 'form') {
                each(widget.references, (widgetReference) => {
                    // If having existing form widget
                    if (widgetReference.type === FORM) {
                        const formId = widgetReference.id;
                        const currentForm = find(forms, { id: formId });
                        // Build form structure
                        if (!isUndefined(currentForm)) {
                            widget.values = buildExistingFormContent(currentForm);
                        } else {
                            // Fallback to default form widgets
                            widget.values = getWidgetDefaultValues('form');
                        }
                    }
                });
            }
        });
    });

    sectionCopy.content = content;
    sectionCopy.values = values;

    return sectionCopy;
});

const processPageContent = (content) => {

    each(content, (sectionContent) => {
        each(sectionContent.content, (widgetsList) => {
            each(widgetsList, (widget) => {
                // For existing forms reset widget values (form content will be taken from reference object)
                if (widget.type === 'form') {
                    widget.values = {};
                    //TODO remove later, formId acts as a placeholder
                    widget.values = { formId: widget.formId };
                }
            });
        });
    });

    return content;
};

const PageEdit = (props: any) => {
    const [page, setPage] = useState({
        content  : [],
        isLoaded : false,
        title    : '',
        slug     : '',
        relations: []
    });

    const pageId = Number(props.$stateParams.id);

    const onSave = (content: any, title: string, slug: string, relations: Array<{ type, id }>) => {

        const processedContent = processPageContent(content);

        // If relations is empty array, then make it null. For the API to work.
        const pageRelations = size(relations) > 0 ? relations : null;

        PageService.updatePage(pageId, { content: processedContent, title, slug, relations:pageRelations })
                   .then(({ page }) => {
                   })
                   .catch(error => {
                       // if (error.response.status === 404) {
                       //     MessageHandler.displayErrorMessage('Page not found.');
                       //     Router.stateService.go('pages');
                       // }
                       // if (error.response.status === 400) {
                       //     MessageHandler.displayException(error.response.data['violations']);
                       // }
                   });
    };

    useEffect(() => {
        const getForms = FormService.getForms();
        const getPage = PageService.getPage(pageId);

        axios
            .all([getForms, getPage])
            .then(
                axios.spread((...responses) => {
                    const [formsResponse, pageResponse] = responses;

                    // If no relation API sends null. Transform it into empty array
                    const pageRelations = !isNull(pageResponse.page.relations) ? pageResponse.page.relations : [];

                    setPage({
                        content  : getPageContent(pageResponse.page.content, formsResponse.forms),
                        title    : pageResponse.page.title,
                        slug     : pageResponse.page.slug,
                        relations: pageRelations,
                        isLoaded : true
                    });
                })
            )
            .catch(error => {
                // if (error.response.status === 404) {
                //     MessageHandler.displayErrorMessage('Page not found.');
                // }
            });

    }, [props.$stateParams.id]);

    return (
        <>
            {page.isLoaded
             ? <PageEditor
                 data={page.content}
                 isNew={false}
                 onSave={onSave}
                 title={page.title}
                 slug={page.slug}
                 relations={page.relations}
             />
             : <LoadingIndicator />
            }
        </>
    );
};

export default PageEdit;
