import {faEdit} from '@fortawesome/free-solid-svg-icons/faEdit';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Button from 'reactstrap/lib/Button';

const ButtonEdit = (props: any) => {
    return (
        <Button className="bg-transparent border-0 p-0" onClick={props.onClick}>
            <FontAwesomeIcon icon={faEdit} inverse />
        </Button>
    );
};

export default ButtonEdit;
