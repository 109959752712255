import React, { CSSProperties } from 'react';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

function Divider(props: { style: CSSProperties }) {
    return (
        <hr style={props.style} />
    );
}

const Widget = DraggableWidget(Divider);

const DividerWidget = (props: any) => {
    const style = {
        backgroundColor: getValueForProperty(props.values, 'styles', 'color'),
        height         : getValueForProperty(props.values, 'styles', 'height') + 'px',
        margin         : getValueForProperty(props.values, 'styles', 'margin'),
    };

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
        />
    );
};

export default DividerWidget;
