import axios from 'axios';
import EventService from 'eventservice';
// @ts-ignore
import autoBind from 'react-autobind';
import HttpRouter from '../HttpRouter';
import IAuthenticationRequest from './IAuthenticationRequest';
import { END_USER_SESSION, FAIL_USER_AUTH, START_USER_SESSION, SUCCESS_USER_AUTH } from './SecurityEvents';
import Session from './Session';

interface ISecurityUser {
    token: string;
    email: string;
}

class AuthenticationService {
    private isInitialized = false;
    private userIsAuthenticated: boolean = false;

    constructor() {
        autoBind(this);
    }

    public init() {
        if (Session.has('user')) {
            this.initUserSession();
        }

        this.isInitialized = true;
        this.registerHttpSecurityHandlers();
    }

    public authenticate(authRequestData: IAuthenticationRequest) {
        this.ifItsReady('authenticate');

        this.checkCredentials(authRequestData)
            .then(({ token, userData }) => {
                const user = {} as ISecurityUser;
                user.token = token;
                user.email = userData.email;

                // Push user into session for now
                this.initUserSession(user as ISecurityUser);

                setTimeout(() => {
                    EventService.fire(SUCCESS_USER_AUTH, userData);
                }, 50);
            })
            .catch(({status, errors}) => {
                if (status === 'AUTHENTICATION_ERROR') {
                    this.logout();
                    EventService.fire(FAIL_USER_AUTH, errors);
                }
            });
    }

    public getUser(): ISecurityUser {
        this.ifItsReady('getUser');

        if (!Session.has('user')) {
            throw new Error('User session not initialized!');
        }

        return Session.get('user');
    }

    public isAuthenticated(): boolean {
        this.ifItsReady('isAuthenticated');

        return this.userIsAuthenticated;
    }

    public logout() {
        this.ifItsReady('logout');
        this.userIsAuthenticated = false;

        Session.remove('user');

        setTimeout(() => {
            EventService.fire(END_USER_SESSION, 'User logout');
        }, 50);
    }

    private registerHttpSecurityHandlers() {
        this.ifItsReady('registerHttpSecurityHandlers');

        // Check for 401 unauthorized  response
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                // 401 status code
                if (error.response.status === 401) {
                    this.logout();
                }
                // Reject promise with data
                return Promise.reject(error.response.data);
            }
        );

        EventService.on(START_USER_SESSION, async (authData: ISecurityUser) => {
            // axios.defaults.headers.Authorization = `Bearer ${authData.token}`;
            // add auth token
            axios.defaults.headers.common['X-Token'] = authData.token;
        });

        EventService.on(END_USER_SESSION, async () => {
            //delete (axios.defaults.headers['X-Token']);
            // remove auth token
            delete axios.defaults.headers.common['X-Token'];
        });
    }

    public checkCredentials(authRequestData: IAuthenticationRequest): Promise<any> {

        const url = HttpRouter.generate('login_user');

        return axios.post(url, authRequestData);
    }

    private ifItsReady(s: string) {
        if (!this.isInitialized) {
            throw new Error(`AuthenticationService was not initialized (using ${s}). Use AuthenticationService::init`);
        }
    }

    private initUserSession(userData?: ISecurityUser) {
        if (!Session.has('user')) {
            Session.set('user', userData);
        } else {
            userData = Session.get('user');
        }

        this.userIsAuthenticated = true;

        setTimeout(() => {
            EventService.fire(START_USER_SESSION, userData);
        }, 50);
    }
}

const AuthService = new AuthenticationService();
export default AuthService;
