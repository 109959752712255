// @ts-ignore
import React from 'react';
import autoBind from 'react-autobind';
import ReactTable, { RowInfo } from 'react-table';
import Button from 'reactstrap/lib/Button';
// @ts-ignore
import { TrashablePromise } from 'trashable';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import {
    extractGridFilters,
    extractGridSorters,
    GridDefaultPageSize,
    IGridState,
    IReactTableRequestParams
} from '../../Core/Grid/IDataGrid';
import MediaUploaderService from '../../Core/Media/MediaUploaderService';
import DiskSize from '../../Core/ValueObject/DiskSize';

interface IMediaFile {
    filename: string;
    id?: number;
    url: string;
}

interface IProps {
    onDelete: Function;
    onReady: Function;
    registerPromise?: TrashablePromise;
    reload?: boolean;
}

class MediaFileGrid extends React.Component<IProps, IGridState> {
    protected data: Array<IMediaFile>;
    protected pages: number;

    protected onDeleteDelegate: Function;
    protected onReady: Function;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.onDeleteDelegate = props.onDelete;
        this.onReady = props.onReady;
        this.pages = null;

        this.state = {
            isReady      : false,
            reload       : false,
            pageSize     : GridDefaultPageSize,
            requestParams: {
                page    : 1,
                pageSize: GridDefaultPageSize,
                filtered: null,
                sorted  : null
            },
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.reload !== prevState.reload) {
            return { reload: nextProps.reload };
        }

        return null;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.reload && !prevState.reload) {
            this.getData(this.state.requestParams);
        }
    }

    render() {
        const columns = [
            {
                Header    : 'URL',
                accessor  : 'store',
                filterable: false,
                sortable  : false,
                width     : 340,
                Cell      : props => {
                    const mediaUrl = process.env.REACT_APP_MEDIA_URL + '/' + props.value;

                    return <a href={mediaUrl} target="_blank">{props.value}</a>;
                },
            },
            {
                Header    : 'Original filename',
                accessor  : 'name',
                filterable: true,
                sortable  : true,
            },
            {
                Header    : 'Type',
                accessor  : 'mime',
                filterable: false,
                sortable  : false,
                width     : 120,
            },
            {
                Header    : 'Size',
                accessor  : 'size',
                filterable: false,
                sortable  : false,
                width     : 120,
                Cell      : props => (new DiskSize(props.value)).toString()
            },
            {
                Header    : 'Actions',
                accessor  : 'actions',
                filterable: false,
                sortable  : false,
                width     : 100,
                Cell      : ({ original }: RowInfo) => (
                    <>
                        <Button className="pb-0 pt-0 text-danger" color="link" size="sm"
                                onClick={() => {
                                    this.onDelete(original);
                                }}
                        >Delete</Button>
                    </>
                ),
            },
        ];

        return (
            <ReactTable manual
                        columns={columns}
                        data={this.data}
                        defaultPageSize={10}
                        loading={!this.state.isReady}
                        pages={this.pages}
                        onFetchData={this.onFetchData}
            />
        );
    }

    protected getData(params: any): void {
        this.setState({ isReady: false });

        this.props.registerPromise(
            MediaUploaderService.mediaObjectDs({
                filters : extractGridFilters(params.filtered),
                sorters : extractGridSorters(params.sorted),
                page    : params.page,
                pageSize: params.pageSize,
            }),
        ).then(({ data, pages }) => {
            this.data = data;
            this.pages = pages;
            this.setState({ isReady: true, reload: false });
            this.onReady();
        });
    }

    protected onDelete(mediaFile: IMediaFile) {
        if (window.confirm('Are you sure you want to delete this media file?')) {
            this.onDeleteDelegate(mediaFile);
        }
    }

    protected onFetchData(state: any): void {
        const requestParams = {
            page    : state.page + 1,
            pageSize: state.pageSize,
            filtered: state.filtered,
            sorted  : state.sorted,
        } as IReactTableRequestParams;

        this.setState({ requestParams: requestParams });
        this.getData(requestParams);
    }
}

export default makeComponentTrashable(MediaFileGrid);
