import each from 'lodash/each';
import React, {useState} from 'react';
import {FileRejection} from 'react-dropzone';
import {Alert, CardFooter} from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import AppUserFilesService from '../../../Core/AppUser/AppUserFilesService';
import MessageHandler from '../../../Core/MessageHandler';
import ButtonWithLoader from '../../ButtonWithLoader';
import AcceptedFilesList from './AcceptedFilesList';
import RejectedFilesList from './RejectedFilesList';

interface IFilesProps {
    acceptedFiles: File[];
    fileRejections: FileRejection[];
}

interface IUploadFilesCardProps {
    appUserId: number;
    files: IFilesProps;
    onClose: () => void;
    onUpload: () => void;
}

const UploadFilesCard = ({appUserId, files, onClose, onUpload}: IUploadFilesCardProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(new FormData());

    const doUpload = () => {
        setIsLoading(true);
        collectData();
        // set the app user id
        formData.set('appUserId', String(appUserId));

        AppUserFilesService
            .uploadFiles(formData)
            .then(resp => {
                setIsLoading(false);
                onUpload();
            })
            .catch((e: Error) => {
                MessageHandler.displayErrorMessage('Could not upload files.');
            });
    };

    const collectData = () => {
        each(files.acceptedFiles, (file: File, index) => {
            // Files needs to be set with multiple keys, otherwise just the last image is uploaded
            formData.set('file_' + index, file);
        });

        setFormData(formData)
    };

    return (
        <Card>
            <CardHeader className="card-header bg-transparent border-bottom">
                <h4 className="m-0">Preview files</h4>
            </CardHeader>
            <CardBody>
                {
                    files.acceptedFiles.length ? (
                        <div className="accepted-files-box">
                            <h6 className="font-weight-bold">The following files were accepted:</h6>
                            <AcceptedFilesList files={files.acceptedFiles} />
                        </div>
                    ) : null
                }
                {
                    files.fileRejections.length ? (
                        <Alert className="rejected-files-box mt-4" color="warning">
                            <h6>The following files will not be uploaded:</h6>
                            <RejectedFilesList fileRejections={files.fileRejections} />
                        </Alert>
                    ) : null
                }
            </CardBody>
            <CardFooter className="text-right">
                <Button color="link" onClick={onClose}>Cancel</Button>
                {
                    files.acceptedFiles.length ? (
                        <ButtonWithLoader
                            className="ml-3"
                            onClick={doUpload}
                            text="Upload files"
                            isLoading={isLoading}
                            disabled={isLoading}
                        />
                    ) : null
                }
            </CardFooter>
        </Card>
    );
};

export default UploadFilesCard;
