import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import Label from 'reactstrap/lib/Label';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import { IContactInfoItem } from '../PageEditor/Widgets/ContactInfo';
import IPropertyConfigProps from './IPropertyConfigProps';

const TextFieldWithIcon = (props: IPropertyConfigProps) => {
    const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
        const value = { icon: props.value.icon, value: $event.target.value } as IContactInfoItem;

        PropertyConfigService.notifyPropertyChange(props.id, props.group, value);
    };

    return (
        <FormGroup>
            <Label>{props.label}</Label>
            <InputGroup>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <FontAwesomeIcon icon={props.value.icon} />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    onChange={onChange}
                    type="text"
                    value={props.value.value}
                />
            </InputGroup>
        </FormGroup>
    );
};

export default TextFieldWithIcon;
