import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons/faFileExcel';
import {faFileImage} from '@fortawesome/free-solid-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons/faFilePdf';
import {faFileWord} from '@fortawesome/free-solid-svg-icons/faFileWord';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {ListGroup, ListGroupItem} from 'reactstrap';
import DiskSize from '../../../Core/ValueObject/DiskSize';

export const iconsByMimeType = {
    'image/gif'                                                              : faFileImage,
    'image/jpg'                                                              : faFileImage,
    'image/jpeg'                                                             : faFileImage,
    'image/png'                                                              : faFileImage,
    'image/webp'                                                             : faFileImage,
    'application/pdf'                                                        : faFilePdf,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': faFileWord,
    'application/vnd.ms-excel'                                               : faFileExcel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'      : faFileExcel,
};

const Icon = ({type}: { type: string }) => (
    <span className="fa-layers fa-fw fa-4x">
        <FontAwesomeIcon icon={faCircle} color="#f4f4f4" />
        <FontAwesomeIcon icon={iconsByMimeType[type]} transform="shrink-10" />
    </span>
);

const AcceptedFilesList = ({files}: { files: File[] }) => {
    return (
        <ListGroup flush>
            {
                files.map(file => {
                    const size = new DiskSize(file.size);

                    return (
                        <ListGroupItem key={file.name} className="d-flex align-items-center">
                            <Icon type={file.type} />
                            <div className="ml-2">
                                <h5 className="mb-0">{file.name}</h5>
                                <span className="text-muted">{size.toString()}</span>
                            </div>
                        </ListGroupItem>
                    )
                })
            }
        </ListGroup>
    );
};

export default AcceptedFilesList;
