import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import IPropertyConfigProps from './IPropertyConfigProps';

const TextField = (props: IPropertyConfigProps) => {
    const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {
        PropertyConfigService.notifyPropertyChange(props.id, props.group, $event.target.value);
    };

    return (
        <FormGroup>
            <Label>{props.label}</Label>
            <Input
                onChange={onChange}
                type="text"
                value={props.value}
            />
        </FormGroup>
    );
};

export default TextField;
