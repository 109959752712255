import map from 'lodash/map';
import throttle from 'lodash/throttle';
import React from 'react';
import { Card, CardHeader, FormGroup, Input, Label } from 'reactstrap';
import { IMenusListProps } from '../../Core/Menu/IMenu';
import { updateMenuTitle } from '../../Core/Menu/MenuActions';
import { useMenuDispatch } from '../../Core/Menu/useMenuContext';
import { DroppableMenuItems } from './MenuItemsList';

const MenusList = ({ menus }: IMenusListProps) => {
    const dispatch = useMenuDispatch();

    const onMenuTitleChange = throttle((menuId, menuTitle) => {
        updateMenuTitle(dispatch, { menuId, menuTitle });
    }, 300);

    return (
        <>
            {map(menus, (menu, idx) => (
                <div className="w-50" key={menu.id}>
                    <Card>
                        <CardHeader>
                            <div className="form-inline">
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for={`menu_title_${menu.id}`} className="mr-sm-2">Menu name:</Label>
                                    <Input
                                        type="text"
                                        id={`menu_title_${menu.id}`}
                                        value={menu.title}
                                        onChange={e => onMenuTitleChange(menu.id, e.target.value)}
                                    />
                                </FormGroup>
                            </div>
                        </CardHeader>

                        <div className="mb-1">
                            <DroppableMenuItems
                                droppableId={menu.id}
                                type="menuItem"
                                items={menu.items}
                                menuId={menu.id}
                                placeholder={<div className="py-4 text-center">Drag items here</div>}
                            />
                        </div>
                    </Card>
                </div>
            ))}
        </>
    );
};

export default MenusList;
