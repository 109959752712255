import {UIView} from '@uirouter/react';
import React, {useState} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import AppUserGrid from '../Components/AppUser/AppUserGrid';
import PasswordModal from '../Components/AppUser/PasswordModal';
import AppUserService from '../Core/AppUser/AppUserService';
import MessageHandler from '../Core/MessageHandler';
import Router from '../Core/Router';
import IUser from '../Core/User/IUser';

const AppUser = () => {
    const [user, setUser] = useState(null);
    const [reloadGrid, setReloadGrid] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const onManageFiles = (user: IUser) => {
        Router.stateService.go('app_users.files', {id: user.id});
    };

    const onChangePassword = (user: IUser) => {
        setUser(user);
        setShowPasswordModal(true);
    };

    const onDeleteUser = (user: IUser) => {
        //@ts-ignore
        AppUserService.deleteUser(user.id).then(() => {
            //TODO Delete request doesn't give any answer
            //IONUT - Fix grids reload on delete entities
            setReloadGrid(true);
            MessageHandler.displaySuccessMessage('App user deleted.');
        });
    };

    const onGridReady = () => {
        setReloadGrid(true);
    }

    const onPasswordModalClose = (shouldReloadGrid: boolean = false) => {
        setShowPasswordModal(false);
        setReloadGrid(shouldReloadGrid);
    };

    return (
        <UIView>
            <>
                <div className="content">
                    <Card>
                        <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                            <h4 className="m-0">App Users</h4>
                        </CardHeader>
                        <CardBody>
                            <AppUserGrid
                                onAssignFiles={onManageFiles}
                                onChangePassword={onChangePassword}
                                onDelete={onDeleteUser}
                                onReady={onGridReady}
                                reload={reloadGrid}
                            />
                        </CardBody>
                    </Card>
                </div>

                {
                    showPasswordModal &&
                    <PasswordModal
                        data={user}
                        onClose={onPasswordModalClose}
                    />
                }
            </>
        </UIView>
    );
};

export default makeComponentTrashable(AppUser);
