import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

export interface IContactInfoItem {
    icon: IconProp;
    value: string;
}

interface IContactInfo {
    address: IContactInfoItem;
    email: IContactInfoItem;
    mobile: IContactInfoItem;
    name: string;
    phone: IContactInfoItem;
    website: IContactInfoItem;
}

const ContactInfo = ({ address, email, mobile, name, phone, website }: IContactInfo) => {
    return (
        <div className="shadow py-3 mb-5 bg-white rounded">
            <h5 className="font-weight-bold text-center mb-3">{name}</h5>
            <ul className="list-group list-group-flush">
                {address.value ? (
                    <li className="list-group-item">
                        <FontAwesomeIcon icon={address.icon} fixedWidth className="mr-2" />
                        {address.value}
                    </li>
                ) : null}
                {email.value ? (
                    <li className="list-group-item">
                        <FontAwesomeIcon icon={email.icon} fixedWidth className="mr-2" />
                        {email.value}
                    </li>
                ) : null}
                {mobile.value ? (
                    <li className="list-group-item">
                        <FontAwesomeIcon icon={mobile.icon} fixedWidth className="mr-2" />
                        {mobile.value}
                    </li>
                ) : null}
                {phone.value ? (
                    <li className="list-group-item">
                        <FontAwesomeIcon icon={phone.icon} fixedWidth className="mr-2" />
                        {phone.value}
                    </li>
                ) : null}
                {website.value ? (
                    <li className="list-group-item">
                        <FontAwesomeIcon icon={website.icon} fixedWidth className="mr-2" />
                        {website.value}
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

const Widget = DraggableWidget(ContactInfo);

const ContactInfoWidget = (props: any) => {
    const contactProps = {
        address: getValueForProperty(props.values, 'contactInfo', 'address'),
        email  : getValueForProperty(props.values, 'contactInfo', 'email'),
        mobile : getValueForProperty(props.values, 'contactInfo', 'mobile'),
        name   : getValueForProperty(props.values, 'contactInfo', 'name'),
        phone  : getValueForProperty(props.values, 'contactInfo', 'phone'),
        website: getValueForProperty(props.values, 'contactInfo', 'website'),
    };

    return (
        <Widget
            {...props.draggableWidgetProps}
            {...contactProps}
        />
    );
};

export default ContactInfoWidget;
