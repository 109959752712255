import React from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonWithLoader from '../ButtonWithLoader';
import IUser from '../../Core/User/IUser';
import MessageHandler from '../../Core/MessageHandler';
import UserService from '../../Core/User/UserService';
import Yup from '../../Core/YupValidator';
import { Field, Form, Formik } from 'formik';
import { ReactstrapInput } from 'reactstrap-formik';

const EditUserSchema = Yup.object().shape({
    firstName: Yup.string()
                  .min(2, 'The field must have min. 2 characters!')
                  .max(60, 'The field must have max. 50 characters!')
                  .required('Required'),
    lastName : Yup.string()
                  .min(2, 'The field must have min. 2 characters!')
                  .max(60, 'The field must have max. 60 characters!')
                  .required('Required'),
});

const EditUserForm = (props: { onCancel: Function, onSuccess: Function, formValues: IUser }) => {
    const { onCancel, onSuccess, formValues } = props;

    return (
        <Formik initialValues={formValues}
                validationSchema={EditUserSchema}
                onSubmit={(values, { setSubmitting }) => {
                    UserService.updateUser(formValues.id, values)
                               .then(() => {
                                   setSubmitting(false);
                                   MessageHandler.displaySuccessMessage('The user has been updated successfully.', 5); // hide after 5 seconds
                                   onSuccess();
                               })
                               .catch((error) => {
                                   setSubmitting(false);
                                   if (error.response.status === 400) {
                                       MessageHandler.displayException(error.response.data['violations']);
                                   } else {
                                       MessageHandler.displayErrorMessage(error.response.data['hydra:title'] + ': ' + error.response.data['hydra:description']);
                                   }
                               });
                }}
        >
            {
                (props) => {
                    const { isSubmitting } = props;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                type="text"
                                id="firstName"
                                label="First name"
                                maxLength="60"
                                name="firstName"
                                component={ReactstrapInput}
                            />
                            <Field
                                type="text"
                                id="lastName"
                                label="Last name"
                                maxLength="60"
                                name="lastName"
                                component={ReactstrapInput}
                            />
                            <div className="form-actions d-flex align-items-center justify-content-end">
                                <Button
                                    color="link"
                                    className="text-secondary"
                                    onClick={() => onCancel()}
                                >Cancel</Button>{' '}
                                <ButtonWithLoader
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    text="Save user"
                                />
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default EditUserForm;
