import map from "lodash/map";
import size from "lodash/size";

export interface IReactTableRequestParams {
    filtered: Object | null;
    page: number;
    pageSize: number;
    sorted: Object | null;
}

export interface IGridRequestParams {
    filters: Array<{ key, value }> | null;
    page: number;
    pageSize: number;
    sorters: Array<{ key, value }> | null;
}

export interface IGridState {
    isReady: boolean;
    reload: boolean;
    pageSize: number;
    requestParams: any;
}

export const GridDefaultPageSize = 10;

export const extractGridFilters = (filters) => {

    //TODO for the API empty array validation (needs to be null)
    if (size(filters) === 0) {
        return null;
    }

    return map(filters, (filter) => {
        return {
            key  : filter.id,
            value: filter.value
        }

    });
};

export const extractGridSorters = (sorters) => {

    //TODO for the API empty array validation (needs to be null)
    if (size(sorters) === 0) {
        return null;
    }

    return map(sorters, (sorter) => {
        return {
            key  : sorter.id,
            value: sorter.desc ? 'desc' : 'asc'
        }

    });
};



