import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import React, { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import uuid from 'uuid/v4';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import IField from '../FormFields/IField';
import DroppableHoc from '../DroppableHoc';
import FieldModal from './Field/FieldModal';
import FieldsList from './Field/FieldsList';
import IPropertyConfigProps from './IPropertyConfigProps';

const DroppableFields = DroppableHoc(FieldsList);

const initialField = {
    id         : null,
    isRequired : false,
    required   : false,
    label      : '',
    name       : '',
    placeholder: '',
    type       : 'text',
} as IField;

const reorder = (list, startIndex, endIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const FormFields = (props: IPropertyConfigProps) => {
    const [state, setState] = useState({ isModalVisible: false, selectedField: initialField });

    const addField = (values) => {
        const fields = cloneDeep(props.value);
        const newField = { ...values, id: uuid() };

        fields.push(newField);

        PropertyConfigService.notifyPropertyChange(props.id, props.group, fields);
    };

    const handleOnClose = () => {
        setState({
            isModalVisible: false,
            selectedField : initialField
        });
    };

    const handleOnDelete = (fieldId) => {
        const fields = cloneDeep(props.value);
        const fieldIndex = findIndex(fields, { id: fieldId });

        fields.splice(fieldIndex, 1);

        PropertyConfigService.notifyPropertyChange(props.id, props.group, fields);

        handleOnClose();
    };

    const handleOnSave = (values) => {
        if (state.selectedField.id === null) {
            addField(values);
        } else {
            updateField(values);
        }

        handleOnClose();
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const fields = cloneDeep(props.value);

        const reorderedFields = reorder(
            fields,
            result.source.index,
            result.destination.index
        );

        PropertyConfigService.notifyPropertyChange(props.id, props.group, reorderedFields);
    };

    const onShowModal = (field: IField) => {
        setState({
            isModalVisible: true,
            selectedField : field
        });
    };

    const updateField = (values) => {
        const fields = cloneDeep(props.value);
        const fieldIndex = findIndex(fields, { id: state.selectedField.id });

        fields[fieldIndex] = { ...values };

        PropertyConfigService.notifyPropertyChange(props.id, props.group, fields);
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <DroppableFields
                    droppableId="fields"
                    type="formField"
                    fields={props.value}
                    onClick={onShowModal}
                />
            </DragDropContext>
            <FormGroup className="mt-2">
                <Button size="sm" onClick={() => onShowModal(initialField)}>Add field</Button>
            </FormGroup>
            {
                state.isModalVisible ? (
                    <FieldModal
                        field={state.selectedField}
                        onClose={handleOnClose}
                        onDelete={handleOnDelete}
                        onSave={handleOnSave}
                    />
                ) : null
            }
        </>
    );
};

export default FormFields;
