import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IMenuItem } from '../../Core/Menu/IMenu';
import DraggableHoc from '../DraggableHoc';
import DroppableHoc from '../DroppableHoc';
import MenuItemActionButtons from './MenuItemButtons';
import MenuItemsList from './MenuItemsList';

const MenuItem = ({ menuId, menuItem }: { menuId: string, menuItem: IMenuItem }) => (
    <>
        <div className="d-flex align-items-center">
            {menuItem.icon && <FontAwesomeIcon className="mr-2" icon={menuItem.icon} size="lg" fixedWidth />}
            {menuItem.label}
            <span className="ml-auto text-capitalize">{menuItem.type}</span>
            <span className="mx-2">|</span>
            <MenuItemActionButtons menuId={menuId} menuItem={menuItem} />
        </div>
        <MenuItemsList
            items={menuItem.items}
            menuId={menuId}
        />
    </>
);

export const DraggableDroppableMenuItem = DraggableHoc(DroppableHoc(MenuItem));
export const DraggableMenuItem = DraggableHoc(MenuItem);

export default MenuItem;
