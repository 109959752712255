import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import NavLink from 'reactstrap/lib/NavLink';
import {UISref, UISrefActive} from '@uirouter/react';

interface ISidebarLink {
    icon: IconProp;
    label: string;
    state: string;
}

const SidebarLink = (props: ISidebarLink) => {
    return (
        <UISrefActive class="nav-link--active">
            <UISref to={props.state}>
                <NavLink className="nav--sidebar__link d-flex align-items-center">
                    <FontAwesomeIcon
                        icon={props.icon}
                        className="nav--sidebar__icon"
                    />{' '}
                    <span className="nav--sidebar__text">{props.label}</span>
                </NavLink>
            </UISref>
        </UISrefActive>
    );
};

export default SidebarLink;
