import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from "../../FormFields/IField";
import DraggableWidget from "./DraggableWidget";
import { getValueForProperty } from "../../../Core/Form/properties";

interface IDateField extends IField {
    label: string;
    name: string;
    style: Object;
}

const DateField = (props: IDateField) => {

    return (
        <FormGroup style={props.style}>
            <Label for={props.id}>{props.label}</Label>{props.required ? (<span>*</span>): ''}
            <Input
                type="date"
                id={props.id}
                name={props.name}
            />
        </FormGroup>
    );
};

const Widget = DraggableWidget(DateField);

const DateWidget = (props) => {
    const style = {
        color  : getValueForProperty(props.values, 'styles', 'color'),
        // margin : getValueForProperty(props.values, 'styles', 'margin'),
        // padding: getValueForProperty(props.values, 'styles', 'padding'),
    };

    const label = getValueForProperty(props.values, 'general', 'label');
    const name = getValueForProperty(props.values, 'general', 'name');
    const required = getValueForProperty(props.values, 'general', 'required');

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
            label={label}
            name={name}
            required={required}
        />
    );
};

export default DateWidget;

