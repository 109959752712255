export const CHANGE_PROPERTY_VALUE = 'ChangePropertyValue';

export const COPY_SECTION = 'CopySection';
export const COPY_WIDGET = 'CopyWidget';

export const DELETE_SECTION = 'DeleteSection';
export const DELETE_WIDGET = 'DeleteWidget';

export const EDIT_SECTION = 'EditSection';
export const EDIT_WIDGET = 'EditWidget';
