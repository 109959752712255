// @ts-ignore
import { ReactstrapInput } from 'reactstrap-formik';
import React from 'react';
import Button from 'reactstrap/lib/Button';
import { Field, Form, Formik } from 'formik';
import ButtonWithLoader from '../ButtonWithLoader';
import MessageHandler from '../../Core/MessageHandler';
import OfficeService from '../../Core/Office/OfficeService';
import Yup from '../../Core/YupValidator';
import Router from '../../Core/Router';

const formValues = {
    name: ''
};

const CreateOfficeSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'The field must have min. 2 characters!')
        .max(60, 'The field must have max. 50 characters!')
        .required('Required'),
});

const OfficeForm = (props: { onCancel: Function, onSuccess: Function }) => {
    const {onCancel, onSuccess} = props;

    return (
        <Formik initialValues={formValues}
                validationSchema={CreateOfficeSchema}
                onSubmit={(values, {setSubmitting}) => {
                    OfficeService.createOffice(values)
                        .then(({data}) => {
                            setSubmitting(false);

                            MessageHandler.displaySuccessMessage('The office has been created successfully.', 5);

                            onSuccess();
                        })
                        .catch(error => {
                            setSubmitting(false);

                            if (error.response.status === 404) {
                                MessageHandler.displayErrorMessage('Office not found.');
                                Router.stateService.go('offices');
                            }

                            if (error.response.status === 400) {
                                MessageHandler.displayException(error.response.data['violations']);
                            }
                        });
                }}
        >
            {
                (props) => {
                    const {isSubmitting} = props;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                type="text"
                                id="name"
                                label="Office name"
                                maxLength="60"
                                name="name"
                                component={ReactstrapInput}
                            />
                            <div className="form-actions d-flex align-items-center justify-content-end">
                                <Button
                                    color="link"
                                    className="text-secondary"
                                    onClick={() => onCancel()}
                                >Cancel</Button>{' '}
                                <ButtonWithLoader
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    text="Save office"
                                />
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default OfficeForm;
