import React from 'react';
import { FileRejection } from 'react-dropzone';
import DiskSize from '../../../Core/ValueObject/DiskSize';

const RejectedFilesList = ({ fileRejections }: { fileRejections: FileRejection[] }) => {

    const errorMessages = (message: string) => {

         // If message contains file size error, transform file size in a friendly name (kB or MB)
         if (message.includes('File is larger'))  {
             let parsedMessage = '';
             const numberRegex = /[a-zA-Z,\s]+|[\d]+/g;
             const diskSize = message.match(numberRegex);

             diskSize.forEach((match) => {
                 if (parseInt(match).toString() === match) {
                     parsedMessage += new DiskSize(parseFloat(match));
                 } else {
                     parsedMessage += match;
                 }
             });

             return parsedMessage;
         }

         return message;
    };

    return (
        <ol className="m-0">
            {
                fileRejections.map(fileRejection => (
                    <li key={fileRejection.file.name}>
                        <span className="mr-2">{fileRejection.file.name}:</span>
                        <span className="text-danger">{errorMessages(fileRejection.errors[0].message)}</span>
                    </li>
                ))
            }
        </ol>
    );
};

export default RejectedFilesList;
