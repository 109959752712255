import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';

interface IGridActionsProps {
    onManageFiles: () => void;
    onChangePassword: () => void;
    onDelete: () => void;
}

const GridActions = (props: IGridActionsProps) => {
    return (
        <ul className="list-unstyled d-flex align-items-center justify-content-end m-0">
            <li>
                <Button
                    color="link"
                    className="pb-0 pt-0"
                    size="sm"
                    onClick={props.onChangePassword}
                >Change password</Button>
            </li>
            <li>
                <Button
                    className="pb-0 pt-0 text-danger"
                    color="link"
                    size="sm"
                    onClick={props.onDelete}
                >Delete</Button>
            </li>
            <li className="mr-2">
                <UncontrolledDropdown title="More options">
                    <DropdownToggle className="py-0 text-body" color="link" size="sm">
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={props.onManageFiles}>Manage files</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </li>
        </ul>
    );
};

export default GridActions;
