import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from "../../FormFields/IField";
import DraggableWidget from "./DraggableWidget";
import { getValueForProperty } from "../../../Core/Form/properties";

const createMarkup = (value) => ({__html: value});

const CheckboxField = (props: IField) => {
    return (
        <FormGroup>
            <div className="custom-control custom-checkbox">
                <Input type="checkbox" className="custom-control-input" id={props.id} name={props.name} />
                <Label
                    className="custom-control-label"
                    dangerouslySetInnerHTML={createMarkup(props.label)}
                    htmlFor={props.id}
                />
                {props.required ? (<span>*</span>): ''}
            </div>
        </FormGroup>
    );
};

const Widget = DraggableWidget(CheckboxField);

const CheckboxWidget = (props) => {
    const label = getValueForProperty(props.values, 'general', 'label');
    const name = getValueForProperty(props.values, 'general', 'name');
    const required = getValueForProperty(props.values, 'general', 'required');

    return (
        <Widget
            {...props.draggableWidgetProps}
            label={label}
            name={name}
            required={required}
        />
    );
};

export default CheckboxWidget;

