// @ts-ignore
import autoBind from 'react-autobind';
import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import PasswordForm from './PasswordForm';
import IUser from '../../Core/User/IUser';

interface IProps {
    data: IUser;
    onClose: Function;
}

class PasswordModal extends React.Component<IProps> {
    protected onClose: Function;
    protected user: IUser;

    constructor(props: IProps) {
        super(props);
        autoBind(this);

        this.onClose = props.onClose;
        this.user = props.data;
    }

    render() {
        return (
            <>
                <Modal isOpen={true}>
                    <ModalHeader toggle={() => this.onClose(false)} tag="div">
                        <h4 className="m-0">Change password</h4>
                        <small>for {this.user.email}</small>
                    </ModalHeader>
                    <ModalBody>
                        <PasswordForm
                            onCancel={() => this.onClose(false)}
                            onSuccess={() => this.onClose(true)}
                            userId={this.user.id}
                        />
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default PasswordModal;
