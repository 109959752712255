import map from 'lodash/map';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IPropertyConfigProps from './IPropertyConfigProps';

interface IProps extends IPropertyConfigProps {
    options: any[];
}

const SelectField = (props: IProps) => {
    return (
        <FormGroup>
            <Label>{props.label} </Label>
            <Input type="select" defaultValue={props.value}>
                {
                    map(props.options, (option, key) => {
                        return <option key={key} value={key}>{option}</option>;
                    })
                }
            </Input>
        </FormGroup>
    );
};

export default SelectField;
