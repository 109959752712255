import React, {useCallback} from 'react';
import {FileRejection, useDropzone} from 'react-dropzone';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import FilesGrid from './FilesGrid';

const acceptedFiles = [
    'image/*',
    'application/pdf',
    'application/msword',
    'application/vnd.ms-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

interface FilesCardParams {
    appUserId: number;
    onAddFiles: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
    reloadGrid: boolean;
}

const FilesCard = ({appUserId, onAddFiles, reloadGrid}: FilesCardParams) => {
    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        onAddFiles(acceptedFiles, fileRejections);
    }, []);
    const {getInputProps, open} = useDropzone({
        // Disable click and keydown behavior
        accept    : acceptedFiles,
        maxSize   : 10485760,
        noClick   : true,
        noKeyboard: true,
        onDrop    : onDrop,
    });

    return (
        <Card>
            <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                <h4 className="m-0">Existing files</h4>
                <div className="ml-auto">
                    <input {...getInputProps()} />
                    <Button color="primary" onClick={open}>Add files</Button>
                </div>
            </CardHeader>
            <CardBody>
                <FilesGrid appUserId={appUserId} shouldReload={reloadGrid} />
            </CardBody>
        </Card>
    );
};

export default FilesCard;
