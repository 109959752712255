import { useContext } from 'react';
import { DispatchContext, StateContext } from './FormContext';

const useFormDispatch = () => {
    const context = useContext(DispatchContext);

    if (context === undefined) {
        throw new Error("useFormDispatch must be used within a FormProvider");
    }

    return context;
};

const useFormState = () => {
    const context = useContext(StateContext);

    if (context === undefined) {
        throw new Error("useFormState must be used within a FormProvider");
    }

    return context;
};

export {
    useFormDispatch,
    useFormState
};
