import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";
import IPropertyConfigProps from "../../Property/IPropertyConfigProps";

const TextField = (props: IPropertyConfigProps) => {

    const dispatch = useFormDispatch();

    const onChange = ($event: React.ChangeEvent<HTMLInputElement>) => {

        dispatch({
            type  : actionTypes.propertyChange,
            params: {
                propertyId   : props.id,
                propertyGroup: props.group,
                value        : $event.target.value
            }
        });
    };

    return (
        <FormGroup>
            <Label>{props.label}</Label>
            <Input
                onChange={onChange}
                type="text"
                placeholder={props.defaultValue}
                value={props.value}
            />
        </FormGroup>
    );
};

export default TextField;
