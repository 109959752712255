import uuid from 'uuid/v4';
import { IMenuItem } from './IMenu';
import { IPage } from './MenuContext';

const defaultProperties = {
    depth   : null,
    hasItems: false,
    icon    : null,
    id      : null,
    items   : [],
    label   : null,
    menuId  : null,
    parentId: null,
    target  : '_self',
    type    : null,
    url     : ''
};

function MenuItemFactory() {

}

MenuItemFactory.prototype.createFromPage = (page: IPage): IMenuItem => {
    return {
        ...defaultProperties,
        id   : uuid(),
        label: page.title,
        type : 'page',
        url  : `/${page.slug}`
    }
};

MenuItemFactory.prototype.createFromCustomLink = (): IMenuItem => {
    return {
        ...defaultProperties,
        id   : uuid(),
        label: 'Custom link',
        type : 'customLink',
    }
};

export default new MenuItemFactory();
