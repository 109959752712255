// @ts-ignore
import autoBind from 'react-autobind';
import React, {Component} from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import {UISref} from '@uirouter/react';
import MediaUploader from '../Components/MediaUploader';
import Router from '../Core/Router';

interface IState {
    acceptedFiles: Array<any>;
    hasAcceptedFiles: boolean;
    isUploading: boolean;
}

class MediaUploaderAdd extends Component<{}, IState> {
    constructor(props: any) {
        super(props);
        autoBind(this);

        this.state = {
            acceptedFiles   : [],
            hasAcceptedFiles: false,
            isUploading     : false
        };
    }

    onFilesUploaded(data) {
        Router.stateService.go('media_uploader');
    }

    render() {
        return (
            <>
                <div className="content">
                    <Card>
                        <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                            <h4 className="m-0">Add files</h4>
                            <div className="ml-auto">
                                <UISref to="media_uploader" className="btn btn-info btn-sm">
                                    <a href="/media-uploader">Back to list</a>
                                </UISref>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <MediaUploader
                                autoUpload={false}
                                maxAcceptedFiles={5}
                                onFilesUploaded={this.onFilesUploaded}
                                showFilePreview={true} />
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}

export default MediaUploaderAdd;
