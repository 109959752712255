import map from 'lodash/map';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from "../../FormFields/IField";
import DraggableWidget from "./DraggableWidget";
import { getValueForProperty } from "../../../Core/Form/properties";

interface ISelectProps extends IField {
    options: any[];
    value: string;
    style: Object;
}

const SelectField = (props: ISelectProps) => {
    return (
        <FormGroup style={props.style}>
            <Label>{props.label}</Label>{props.required ? (<span>*</span>): ''}
            <Input type="select">
                {
                    map(props.options, (option, idx) => {
                        return <option key={idx} value={option.value}>{option.label}</option>;
                    })
                }
            </Input>
        </FormGroup>
    );
};

const Widget = DraggableWidget(SelectField);

const SelectWidget = (props) => {

    const label = getValueForProperty(props.values, 'general', 'label');
    const name = getValueForProperty(props.values, 'general', 'name');
    const required = getValueForProperty(props.values, 'general', 'required');
    const options = getValueForProperty(props.values, 'general', 'options');

    const style = {
        color: getValueForProperty(props.values, 'styles', 'color'),
        margin : getValueForProperty(props.values, 'styles', 'margin'),
        // padding: getValueForProperty(props.values, 'styles', 'padding'),
    };

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
            label={label}
            name={name}
            required={required}
            options={options}
        />
    );
};

export default SelectWidget;

