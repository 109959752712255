// @ts-ignore
import each from 'lodash/each';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import keys from 'lodash/keys';
import map from 'lodash/map';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import MediaUploaderService from '../../Core/Media/MediaUploaderService';
import MessageHandler from '../../Core/MessageHandler';
import DiskSize from '../../Core/ValueObject/DiskSize';
import ButtonWithLoader from '../ButtonWithLoader';
import DragoverBox from './DragoverBox';

interface IProps {
    onFilesUploaded?: Function;
    maxAcceptedFiles?: number;
    showFilePreview?: boolean;
    autoUpload?: boolean
}

interface IState {
    hasAcceptedFiles: boolean;
    isUploading: boolean;
    filesPreview: Array<any>;
}

class MediaUploader extends Component<IProps, IState> {
    static defaultProps: IProps;
    protected formData: FormData;
    protected acceptedFiles: Array<File>;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.formData = new FormData();
        this.acceptedFiles = [];

        this.state = {
            hasAcceptedFiles: false,
            isUploading     : false,
            filesPreview    : [],
        };
    }

    render() {
        let previews = null;

        if (this.props.showFilePreview) {
            const filesPreview = map(this.state.filesPreview, (preview, key) => (
                <li key={key} className="d-flex align-items-center">
                    <figure>
                        <img src={preview.src} alt="" className="ml-2 mr-2" width="100" />
                        {preview.name} - {preview.size}
                    </figure>
                </li>
            ));

            previews = <div className="uploaded-files-list mt-3">
                <p>Accepted files: </p>
                <ol className="p-0">{filesPreview}</ol>
            </div>;
        }

        return (
            <>
                <div className="content">
                    <DragoverBox
                        acceptedTypes=".jpg, .png, .svg, .webp, .pdf"
                        maxFileSize={10485760} // 10MB
                        maxFilesNr={this.props.maxAcceptedFiles}
                        onDrop={this.onDrop}
                    />
                    {
                        this.state.hasAcceptedFiles && !this.props.autoUpload ?
                            (
                                <>
                                    {previews}
                                    <div className="d-flex">
                                        <ButtonWithLoader
                                            className="ml-auto"
                                            color="primary"
                                            isLoading={this.state.isUploading}
                                            text="Upload all files"
                                            onClick={this.onHandleUpload}
                                        />
                                    </div>
                                </>
                            ) : null
                    }
                </div>
            </>
        );
    }

    protected onDrop(acceptedFiles: FileList) {
        // only accepted new files
        const newFiles = filter(acceptedFiles, (file) => {
            const existingFile = find(this.acceptedFiles, ['name', file.name]);

            return isUndefined(existingFile);
        });

        this.setNewFiles(newFiles);

        if (this.props.autoUpload) {
            this.onHandleUpload();
        }
    }

    protected onHandleUpload() {
        this.setState({ isUploading: true });
        this.collectData();
        this.doUpload();
    }

    private collectData() {
        each(this.acceptedFiles, (file: File, index) => {
            // Files needs to be set with multiple keys, otherwise just the last image is uploaded
            this.formData.set('file_' + index, file);
        });
    }

    private doUpload() {
        MediaUploaderService.uploadMediaFiles(this.formData)
                            .then(({ mediaObjects }) => {
                                this.setState({ isUploading: false });
                                MessageHandler.displaySuccessMessage('The files has been uploaded successfully.', 5);
                                this.reset();
                                this.props.onFilesUploaded(mediaObjects);
                            })
                            .catch(err => {
                                this.setState({ isUploading: false });
                                MessageHandler.displayErrorMessage('Unexpected error encountered while performing your request.');
                            });
    }

    private reset() {
        this.setState({ filesPreview: [] });
        this.acceptedFiles = [];
        for (const key in keys(this.formData)) {
            if (this.formData.hasOwnProperty(key)) {
                this.formData.delete(key);
            }
        }
    }

    private setNewFiles(newFiles: Array<File>) {
        this.acceptedFiles = [];
        each(newFiles, (file: File) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const size = new DiskSize(file.size);

                const fileInfo = {
                    name: file.name,
                    size: size.toString(),
                    src : reader.result
                };

                const filesPreview = this.state.filesPreview;
                filesPreview.push(fileInfo);
                this.setState({ hasAcceptedFiles: true, filesPreview: filesPreview });
            };

            reader.readAsDataURL(file);
            this.acceptedFiles.push(file);
        });
        this.setState({ hasAcceptedFiles: true });
    }
}

MediaUploader.defaultProps = {
    autoUpload      : false,
    maxAcceptedFiles: 1,
    onFilesUploaded : null,
    showFilePreview : true
};

export default MediaUploader;
