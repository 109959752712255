import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import IField from '../../FormFields/IField';
import FieldForm from './FieldForm';

export interface IFieldModalProps {
    field: IField;
    onClose: Function;
    onDelete: Function;
    onSave: Function;
}

const FieldModal = (props: IFieldModalProps) => {
    const isNew = props.field.id === null;
    const modalTitle = isNew ? 'Add field' : 'Update field';

    return (
        <Modal isOpen={true}>
            <ModalHeader toggle={() => props.onClose()}>{modalTitle}</ModalHeader>
            <ModalBody>
                <FieldForm
                    onCancel={props.onClose}
                    onDelete={props.onDelete}
                    onSuccess={props.onSave}
                    formValues={props.field}
                />
            </ModalBody>
        </Modal>
    );
};

export default FieldModal;
