import axios from 'axios';
import HttpRouter from '../HttpRouter';
import { IMenu } from './IMenu';

class MenuService {
    getMenus(): Promise<any> {
        const url = HttpRouter.generate('get_config', { name: 'menus' });

        return axios.get(url);
    }

    updateMenu(menuData: IMenu[]): Promise<any> {
        const payload = {
            content: { value: menuData },
            name   : 'menus',
        };

        const url = HttpRouter.generate('save_config');

        return axios.post(url, { config: payload });
    }
}

export default new MenuService();
