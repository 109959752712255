import axios from 'axios';
import { IGridRequestParams } from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';
import IUser from './IUser';

class UserService {
    createUser(user: IUser): Promise<any> {
        const payload = {
            firstName: user.firstName,
            lastName : user.lastName,
            password : user.password,
            email    : user.email,
        };

        const url = HttpRouter.generate('save_user');

        return axios.post(url, payload);
    }

    updateUser(userId: number, user: IUser): Promise<any> {
        const payload = {
            id       : userId,
            firstName: user.firstName,
            lastName : user.lastName,
        };

        const url = HttpRouter.generate('update_user', { id: userId });

        return axios.post(url, payload);
    }

    deleteUser(userId: number): Promise<any> {
        const url = HttpRouter.generate('delete_user', { id: userId });

        return axios.delete(url);
    }

    usersDs(params: IGridRequestParams): Promise<any> {
        const url = HttpRouter.generate('user_ds');

        return axios.post(url, params);
    }

    updatePassword(userId: number, data: { password: string, passwordConfirm: string }): Promise<any> {
        const payload = {
            password: data.password,
            id      : userId
        };

        const url = HttpRouter.generate('user_change_password', { id: userId });

        return axios.post(url, payload);
    }
}

export default new UserService();
