import { useContext } from 'react';
import { DispatchContext, StateContext } from './MenuContext';

const useMenuDispatch = () => {
    const context = useContext(DispatchContext);

    if (context === undefined) {
        throw new Error("useMenuDispatch must be used within a MenuProvider");
    }

    return context;
};

const useMenuState = () => {
    const context = useContext(StateContext);

    if (context === undefined) {
        throw new Error("useMenuState must be used within a MenuProvider");
    }

    return context;
};

export {
    useMenuDispatch,
    useMenuState
};
