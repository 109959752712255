interface IDiskSize<> {
    toString(): string;
}

const MEGA_UNIT = 1048576;
const KILO_UNIT = 1024;

class DiskSize implements IDiskSize {
    constructor(protected value: number) {
        this.value = value;
    };

    public toString = (): string => {
        if (this.value >= 1000000) {
            return this.toMegaBytes();
        }

        if (this.value >= 1000) {
            return this.toKiloBytes();
        }

        return `${this.value} bytes`;
    };

    public toMegaBytes(): string {
        return `${(this.value / MEGA_UNIT).toFixed(2)} MB`;
    }

    public toKiloBytes(): string {
        return `${(this.value / KILO_UNIT).toFixed()} KB`;
    }
}

export default DiskSize;
