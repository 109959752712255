import axios from 'axios';
import MessageHandler from '../MessageHandler';
import PageService from '../Page/PageService';
import Router from '../Router';
import { IMenu, IMenuItem } from './IMenu';
import { actionTypes } from './MenuReducer';
import MenuService from './MenuService';

interface IAddMenuItemPayload {
    index: number;
    menuItem: IMenuItem;
}

interface IAddMenuItemChildPayload {
    child: IMenuItem;
    index: number;
}

interface IDeleteMenuItemPayload {
    menuId: string;
    menuItemId: string;
    parentId: string;
}

interface IReorderMenuPayload {
    menuId: string;
    newIndex: number;
    oldIndex: number;
}

interface IReorderMenuItemPayload extends IReorderMenuPayload {
    menuItemId: string;
}

interface ISetCurrentMenuItemPayload {
    menuItem: IMenuItem | null;
}

interface IUpdateMenuItemPayload {
    menuItem: IMenuItem;
}

interface IUpdateMenuTitlePayload {
    menuId: string;
    menuTitle: string;
}

const addMenuItem = (dispatch, payload: IAddMenuItemPayload): Promise<any> => {
    return Promise.resolve(dispatch({ type: actionTypes.addMenuItem, payload: payload }));
};

const addMenuItemChild = (dispatch, payload: IAddMenuItemChildPayload): Promise<any> => {
    return Promise.resolve(dispatch({ type: actionTypes.addMenuItemChild, payload: payload }));
};

/**
 * Fetch all necessary data for building the menu
 *
 * @param dispatch
 */
const bootstrapMenuData = (dispatch): Promise<void> => {
    const getMenus = MenuService.getMenus();
    const getPages = PageService.getAllPages();

    return axios
        .all([getMenus, getPages])
        .then(
            axios.spread((...responses) => {

                const [menuResponse, pageResponse] = responses;

                const configMenus = menuResponse.config;
                const menus = configMenus && configMenus.content.value;
                const pages = pageResponse.pages;

                dispatch({
                    type   : actionTypes.setData,
                    payload: {
                        menus: menus,
                        pages: pages,
                    }
                });
            })
        )
        .catch(error => {
            //TODO Dead code, remove this
            // if (error.response.status === 404) {
            //     MessageHandler.displayErrorMessage('Page not found.');
            //     // Router.stateService.go('pages');
            // }
        });
};

const deleteMenuItem = (dispatch, payload: IDeleteMenuItemPayload) => {
    dispatch({ type: actionTypes.deleteMenuItem, payload: payload });
};

const reorderMenu = (dispatch, payload: IReorderMenuPayload) => {
    dispatch({ type: actionTypes.reorderMenu, payload: payload });
};

const reorderMenuItem = (dispatch, payload: IReorderMenuItemPayload) => {
    dispatch({ type: actionTypes.reorderMenuItem, payload: payload });
};

const saveMenus = (menuData: IMenu[]): Promise<void> => {
    return MenuService
        .updateMenu(menuData)
        .then(({ data }) => {
            MessageHandler.displaySuccessMessage('The menus have been updated successfully.', 5);
        })
        .catch(error => {
            if (error.response.status === 400) {
                MessageHandler.displayException(error.response.data['violations']);
            }
            if (error.response.status === 404) {
                MessageHandler.displayErrorMessage('Menus not found.');
                Router.stateService.go('menus');
            }
            if (error.response.status === 405) {
                MessageHandler.displayErrorMessage(error.response.statusText);
            }
        });
};

const setCurrentMenuItem = (dispatch, payload: ISetCurrentMenuItemPayload) => {
    dispatch({ type: actionTypes.setCurrentMenuItem, payload: payload });
};

const updateMenuItem = (dispatch, payload: IUpdateMenuItemPayload) => {
    dispatch({ type: actionTypes.updateMenuItem, payload: payload });
};

const updateMenuTitle = (dispatch, payload: IUpdateMenuTitlePayload) => {
    dispatch({ type: actionTypes.updateMenuTitle, payload: payload });
};

export {
    addMenuItem,
    addMenuItemChild,
    bootstrapMenuData,
    deleteMenuItem,
    reorderMenu,
    reorderMenuItem,
    saveMenus,
    setCurrentMenuItem,
    updateMenuItem,
    updateMenuTitle,
}
