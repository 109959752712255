import {faBell} from '@fortawesome/free-solid-svg-icons/faBell';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { faMagic } from '@fortawesome/free-solid-svg-icons/faMagic';
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faUserAlt } from '@fortawesome/free-solid-svg-icons/faUserAlt';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { UISref } from '@uirouter/react';
import map from 'lodash/map';
import React from 'react';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import logo from '../../images/logo.png';
import SidebarLink from './SidebarLink';

const menuItems = [
    {
        state: 'pages',
        icon : faDesktop,
        label: 'Pages'
    },
    {
        state: 'menus',
        icon : faListAlt,
        label: 'Menus'
    },
    {
        state: 'forms',
        icon : faMagic,
        label: 'Forms'
    },
    {
        state: 'media_uploader',
        icon : faCloudUploadAlt,
        label: 'Media uploader'
    },
    {
        state: 'users',
        icon : faUsers,
        label: 'Admins'
    },
    {
        state: 'app_users',
        icon : faUserAlt,
        label: 'Application Users'
    },
    {
        state: 'offices',
        icon : faStore,
        label: 'Offices'
    },
    {
        state: 'notifications',
        icon : faBell,
        label: 'Notifications'
    },
    {
        state: 'config',
        icon : faCog,
        label: 'Config'
    }
];

const Sidebar = () => {
    const renderMenuItems = () => map(menuItems, (item, i) =>
        (
            <NavItem className="nav--sidebar__item" key={i}>
                <SidebarLink
                    state={item.state}
                    icon={item.icon}
                    label={item.label}
                />
            </NavItem>
        )
    );

    return (
        <aside className="sidebar">
            <div className="logo d-flex">
                <UISref to="" className="logo__link">
                    <a href="/">
                        <img src={logo} alt="App-Constructor" className="logo__img" height="35" />
                        <span className="logo__text">App-Constructor</span>
                    </a>
                </UISref>
            </div>
            <div className="sidebar-wrapper">
                <Nav className="nav--sidebar">
                    {renderMenuItems()}
                </Nav>
            </div>
        </aside>
    );
};

export default Sidebar;
