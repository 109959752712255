import {Editor} from '@tinymce/tinymce-react';
import React from 'react';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import IPropertyConfigProps from './IPropertyConfigProps';

const plugins = [
    'advlist autolink lists link charmap print anchor, searchreplace visualblocks code paste code help wordcount',
];

const toolbar = [
    {
        name: 'history', items: ['undo', 'redo']
    },
    {
        name: 'styles', items: ['styleselect']
    },
    {
        name: 'formatting', items: ['bold', 'italic', 'backcolor', 'forecolor']
    },
    {
        name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify']
    },
    {
        name: 'list', items: ['bullist', 'numlist']
    },
    {
        name: 'removeformat', items: ['removeformat']
    }
];

const initOptions = {
    branding    : false,
    height      : 400,
    menubar     : false,
    mobile      : {
        menubar: true
    },
    plugins     : plugins,
    toolbar     : toolbar,
    toolbar_mode: 'scrolling',
};

const HtmlEditor = (props: IPropertyConfigProps) => {
    const onChange = (content) => {
        PropertyConfigService.notifyPropertyChange(props.id, props.group, content);
    };

    return (
        <Editor
            value={props.value}
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            init={initOptions}
            onEditorChange={onChange}
        />
    );
};

export default HtmlEditor;
