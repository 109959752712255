import React from 'react';
import Button from 'reactstrap/lib/Button';
import ButtonWithLoader from '../ButtonWithLoader';
import IOffice from '../../Core/Office/IOffice';
import MessageHandler from '../../Core/MessageHandler';
import OfficeService from '../../Core/Office/OfficeService';
import Yup from '../../Core/YupValidator';
import { Field, Form, Formik } from 'formik';
import { ReactstrapInput } from 'reactstrap-formik';

const EditOfficeSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'The field must have min. 2 characters!')
        .max(60, 'The field must have max. 50 characters!')
        .required('Required'),
});

const EditOfficeForm = (props: { onCancel: Function, onSuccess: Function, formValues: IOffice }) => {
    const {onCancel, onSuccess, formValues} = props;

    return (
        <Formik initialValues={formValues}
                validationSchema={EditOfficeSchema}
                onSubmit={(values, {setSubmitting}) => {
                    OfficeService.updateOffice(formValues.id, values)
                        .then(() => {
                            setSubmitting(false);
                            MessageHandler.displaySuccessMessage('The office has been updated successfully.', 5); // hide after 5 seconds
                            onSuccess();
                        })
                        .catch((error) => {
                            setSubmitting(false);
                            if (error.response.status === 400) {
                                MessageHandler.displayException(error.response.data['violations']);
                            } else {
                                MessageHandler.displayErrorMessage(error.response.data['hydra:title'] + ': ' + error.response.data['hydra:description']);
                            }
                        });
                }}
        >
            {
                (props) => {
                    const {isSubmitting} = props;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                type="text"
                                id="name"
                                label="Office name"
                                maxLength="60"
                                name="name"
                                component={ReactstrapInput}
                            />
                            <div className="form-actions d-flex align-items-center justify-content-end">
                                <Button
                                    color="link"
                                    className="text-secondary"
                                    onClick={() => onCancel()}
                                >Cancel</Button>{' '}
                                <ButtonWithLoader
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    text="Save office"
                                />
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default EditOfficeForm;
