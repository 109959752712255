// @ts-ignore
import autoBind from 'react-autobind';
import React from 'react';
import ReactDropzone from 'react-dropzone';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DiskSize from '../../Core/ValueObject/DiskSize';
import MessageHandler from '../../Core/MessageHandler';

const MAX_FILES_NR = 1;
const MAX_FILES_NR_ERROR = 'Maximum files accepted';

interface IProps {
    acceptedTypes?: string;
    maxFileSize?: number;
    maxFilesNr?: number;
    onDrop: any;
}

class DragoverBox extends React.Component<IProps, { files: Array<File>; }> {
    protected diskSize: DiskSize;
    protected maxFilesNr: number;
    protected maxFileSize: string;
    protected onDropDelegate: Function;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.onDropDelegate = props.onDrop;
        this.maxFilesNr = props.maxFilesNr || MAX_FILES_NR;
        this.diskSize = new DiskSize(props.maxFileSize);

        this.state = { files: [] };
    }

    public onDrop(acceptedFiles: [], rejectedFiles: []): void {
        if (!acceptedFiles.length) {
            MessageHandler.displayErrorMessage('No valid files selected.');
            return;
        }

        const filesNr = (this.state.files.length + acceptedFiles.length + rejectedFiles.length);

        if (filesNr > this.maxFilesNr) {
            MessageHandler.displayErrorMessage(`${MAX_FILES_NR_ERROR}: ${this.maxFilesNr}`);
            return;
        }

        const files = [...this.state.files, ...acceptedFiles];

        this.setState({ files: files });
        this.onDropDelegate(files, rejectedFiles);

        if (rejectedFiles.length) {
            const message = rejectedFiles.length > 1 ? 'files were rejected!' : 'file was rejected!';
            MessageHandler.displayWarningMessage(`${rejectedFiles.length} ${message}`);
        }

        this.setState({ files: [] });
    }

    render() {
        const { acceptedTypes, maxFileSize } = this.props;

        return (
            <ReactDropzone
                accept={acceptedTypes}
                maxSize={maxFileSize}
                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <>
                            <div {...getRootProps()} className="dragover rounded d-flex justify-content-center">
                                <input {...getInputProps()} id="files" />
                                <div className="dragover__label d-flex align-items-center justify-content-center">
                                    {
                                        isDragActive ? (
                                            <p>Drop files here...</p>
                                        ) : (
                                            <div className="text-center">
                                                <div className="dragover__icon mb-4">
                                                    <FontAwesomeIcon
                                                        color="hsla(0, 0%, 0%, 0.2)"
                                                        icon={faCloudUploadAlt}
                                                        size="4x"
                                                    />
                                                </div>
                                                <div className="font-weight-bolder m-0 text-muted">DRAG & DROP</div>
                                                <div>
                                                    your files here or
                                                    <span className="dragover__browse text-info"> browse</span>.
                                                </div>
                                                <div className="text-muted small">
                                                    You can attach maximum <b>{this.maxFilesNr} files</b>,
                                                    (maximum {this.diskSize.toString()} each)
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    );
                }}
            </ReactDropzone>
        );
    }
}

export default DragoverBox;
