import map from 'lodash/map';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from './IField';

export interface ISelectOption {
    label: string;
    value: string;
}

export interface ISelect extends IField {
    options: ISelectOption[];
}

const getOptions = (options) => map(options, (option, key) => (
    <option value={option.value} key={key}>{option.label}</option>
));

const SelectField = (props: ISelect) => {
    return (
        <FormGroup>
            <Label for={props.id}>{props.label}</Label>
            <Input
                type={props.type}
                id={props.id}
                name={props.name}
            >
                {getOptions(props.options)}
            </Input>
        </FormGroup>
    );
};

export default SelectField;
