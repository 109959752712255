import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons/faGripHorizontal';
import { faListAlt } from "@fortawesome/free-solid-svg-icons/faListAlt";
import { faShapes } from '@fortawesome/free-solid-svg-icons/faShapes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import map from 'lodash/map';
import range from 'lodash/range';
import React, { useState } from 'react';
import Col from 'reactstrap/lib/Col';
import Collapse from "reactstrap/lib/Collapse";
import Container from 'reactstrap/lib/Container';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import Row from 'reactstrap/lib/Row';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import IWidget from '../../Core/Page/IWidget';
import { ISection, sections } from '../../Core/Page/sections';
import { widgets } from '../../Core/Page/widgets';
import DraggableHoc from '../DraggableHoc';
import DroppableHoc from '../DroppableHoc';
import Scrollbar from '../Scrollbar';


const tabs = [
    {
        icon  : faCog,
        id    : 'pageConfig',
        label : 'Page config',
        target: 'pageConfig'
    },
    {
        icon  : faGripHorizontal,
        id    : 'sections',
        label : 'Sections',
        target: 'sections'
    },
    {
        icon  : faShapes,
        id    : 'widgets',
        label : 'Widgets',
        target: 'widgets'
    }
];

function Section(props: { columns: number[] }) {
    return <div className="section bg-light border py-2">
        <Container>
            <Row>
                <SectionColumns columns={props.columns} />
            </Row>
        </Container>
    </div>;
}

function SectionColumns(props: { columns: number[] }) {
    return <>
        {
            map(props.columns, (idx) =>
                <Col key={idx}>
                    <div className="bg-white py-2" />
                </Col>
            )
        }
    </>;
}

function SectionsList(props: { sections: ISection[] }) {
    return <Row>
        {
            map(props.sections, (section, idx) => {
                const columns = range(section.values.columns.columns);

                return (
                    <Col key={idx} className="text-center mb-3" xl={12}>
                        <DraggableSection
                            draggableId={section.id}
                            index={idx}
                            columns={columns}
                        />
                    </Col>
                );
            })
        }
    </Row>;
}

function Widget({widget}: { widget: any }) {

    return <div className="widget mb-3">
        <FontAwesomeIcon icon={widget.icon} />
        <h6 className="m-0">{widget.label}</h6>
    </div>;
}

function FormWidget({form}: { form: any }) {
    return <div className="widget mb-3">
        <FontAwesomeIcon icon={faListAlt} />
        <h6 className="m-0">{form.name}</h6>
    </div>;
}

function WidgetsList(props: { widgets: IWidget[] }) {
    return <Row>
        {
            map(props.widgets, (widget, idx) => {
                if (widget.type !== 'form') {
                    return (
                        <Col key={idx} className="text-center" xl={4}>
                            <DraggableWidget
                                draggableId={widget.id}
                                index={idx}
                                widget={widget}
                            />
                        </Col>
                    );
                }
            })
        }
    </Row>;
}

function FormsList(props: { forms: IWidget[] }) {

    return <Row>
        {
            map(props.forms, (form, idx) => (
                <Col key={idx} className="text-center" xl={4}>
                    <DraggableFormWidget
                        draggableId={form.id}
                        index={idx}
                        form={form}
                    />
                </Col>
            ))
        }
    </Row>;
}

const DraggableSection = DraggableHoc(Section);
const DraggableWidget = DraggableHoc(Widget);
const DroppableSections = DroppableHoc(SectionsList);
const DroppableWidgets = DroppableHoc(WidgetsList);
const DroppableForms = DroppableHoc(FormsList);

const DraggableFormWidget = DraggableHoc(FormWidget);

const WidgetsPanel = (props: { title: string, slug: string, onChange: Function, forms: Array<{ id, name, content }> }) => {

    const [activeTab, setActiveTab] = useState('pageConfig');
    const [isOpen, setIsOpen] = useState(true);
    const [isFormsOpen, setIsFormsOpen] = useState(true);
    const forms = props.forms;

    const collapsibleIndicator = isOpen ? faChevronUp : faChevronDown;
    const collapsibleFormIndicator = isFormsOpen ? faChevronUp : faChevronDown;

    const toggle = (tabId) => {
        if (activeTab !== tabId) {
            setActiveTab(tabId);
        }
    };

    const toggleGroup = () => {
        setIsOpen(!isOpen);
    };
    const toggleFormsGroup = () => {
        setIsFormsOpen(!isFormsOpen);
    };

    const widgetTabs = map(tabs, tab => (
        <NavItem className="widgets-panel__nav-item" key={tab.id}>
            <NavLink href="#"
                     className="widgets-panel__nav-link"
                     active={activeTab === tab.id}
                     id={tab.id}
                     onClick={() => {
                         toggle(tab.id);
                     }}>
                <FontAwesomeIcon icon={tab.icon} size="lg" />
            </NavLink>
            <UncontrolledTooltip target={tab.target}>{tab.label}</UncontrolledTooltip>
        </NavItem>
    ));

    return (
        <div className="widgets-panel">
            <Nav className="widgets-panel__nav" tabs fill>
                {widgetTabs}
            </Nav>
            <TabContent activeTab={activeTab} className="py-3 h-100">
                <TabPane tabId="pageConfig">
                    <Container fluid className="h-100">
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input
                                defaultValue={props.title}
                                type="text"
                                name="title"
                                maxLength={60}
                                onChange={(e) => props.onChange('title', e.target.value)}
                                placeholder="Enter page title"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="slug">Slug (/)</Label>
                            <Input
                                defaultValue={props.slug}
                                type="text"
                                name="slug"
                                maxLength={60}
                                onChange={(e) => props.onChange('slug', e.target.value)}
                                placeholder="Page unique slug"
                                required
                            />
                        </FormGroup>
                    </Container>
                </TabPane>
                <TabPane tabId="sections">
                    <Container fluid className="h-100">
                        <DroppableSections
                            droppableId="sections"
                            isDropDisabled={true}
                            type="section"
                            sections={sections}
                        />
                    </Container>
                </TabPane>
                <TabPane tabId="widgets" className="h-100 tab-pane--with-scroll">
                    <Scrollbar noScrollX={true}>
                        <Container fluid className="h-100">

                            <div className="property-group mt-2 mb-2 pb-1" id={'toggle_widgets'}>
                                <div className="property-group__label mb-2 py-1" onClick={toggleGroup}>
                                    <Container fluid className="d-flex align-items-center">
                                        <h5 className="m-0">Widgets</h5>
                                        <FontAwesomeIcon icon={collapsibleIndicator} className="ml-auto" />
                                    </Container>
                                </div>
                                <Collapse isOpen={isOpen}>
                                    <DroppableWidgets
                                        droppableId="widgets"
                                        isDropDisabled={true}
                                        type="widget"
                                        widgets={widgets} />

                                </Collapse>
                            </div>

                            <div className="property-group mt-2 mb-2 pb-1" id={'toggle_forms'}>
                                <div className="property-group__label mb-2 py-1" onClick={toggleFormsGroup}>
                                    <Container fluid className="d-flex align-items-center">
                                        <h5 className="m-0">Forms</h5>
                                        <FontAwesomeIcon icon={collapsibleFormIndicator} className="ml-auto" />
                                    </Container>
                                </div>
                                <Collapse isOpen={isFormsOpen}>
                                    <DroppableForms
                                        droppableId="widgets"
                                        isDropDisabled={true}
                                        type="widget"
                                        forms={forms}
                                    />
                                </Collapse>
                            </div>

                        </Container>
                    </Scrollbar>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default WidgetsPanel;
