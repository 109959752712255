import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactTable, { RowInfo } from 'react-table';
import Button from 'reactstrap/lib/Button';
import AppUserFilesService from '../../../Core/AppUser/AppUserFilesService';
import { downloadFile } from '../../../Core/DownloadFile';
import FilterDropdown from '../../../Core/FilterDropdown';
import { extractGridFilters, extractGridSorters, IReactTableRequestParams } from '../../../Core/Grid/IDataGrid';
import MessageHandler from '../../../Core/MessageHandler';
import DiskSize from '../../../Core/ValueObject/DiskSize';
import { iconsByMimeType } from './AcceptedFilesList';

interface IFilesGridProps {
    appUserId: number;
    shouldReload: boolean;
}

interface IFilesGridDataItem {
    diskName: string
    id: number;
    mime: string;
    originalName: string;
    size: number;
}

const filesTypes = [
    { id: '', name: 'All' },
    { id: 'image/gif', name: 'GIF Image' },
    { id: 'image/png', name: 'PNG Image' },
    { id: 'image/jpeg', name: 'JPEG Image' },
    { id: 'image/jpg', name: 'JPG Image' },
    { id: 'image/webp', name: 'WEBP Image' },
    { id: 'application/pdf', name: 'PDF document' },
    { id: 'application/vnd.ms-excel', name: 'EXCEL document' },
    { id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', name: 'EXCEL XLSX document' },
    { id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', name: 'WORD document' },
];

const FilesGrid = ({ appUserId, shouldReload }: IFilesGridProps) => {
    const [data, setData] = useState([] as IFilesGridDataItem[]);
    const [isLoading, setIsLoading] = useState(false);
    const [pages, setPages] = useState(-1); // we don't know how many pages we have
    const [requestParams, setRequestParams] = useState({} as IReactTableRequestParams);

    useEffect(() => {
        if (shouldReload) {
            onFetchData(requestParams);
        }
    }, [shouldReload]);

    const onDelete = (originalRow) => {
        if (window.confirm('Are you sure you want to delete this media file?\nThis cannot be undone.')) {
            setIsLoading(true);
            AppUserFilesService
                .deleteFile(originalRow.id)
                .then(resp => {
                    onFetchData(requestParams);
                    MessageHandler.displaySuccessMessage('File deleted successfully.', 5);
                })
                .catch((e: Error) => {
                    setIsLoading(false);
                    MessageHandler.displayErrorMessage('Could not delete the file.');
                });
        }
    };

    const onPreview = (originalRow) => {
        AppUserFilesService
            .downloadFile(appUserId, originalRow.id)
            .then(resp => {
                downloadFile(resp.data, originalRow.diskName);
            })
            .catch((e: Error) => {
                MessageHandler.displayErrorMessage('Could not download the file.');
            });
    };

    const columns = [
        {
            Header    : 'Disk filename',
            accessor  : 'diskName',
            filterable: false,
            sortable  : false,
            width     : 350,
        },
        {
            Header    : 'Original filename',
            accessor  : 'originalName',
            filterable: true,
            sortable  : true,
        },
        {
            Header    : 'Type',
            accessor  : 'mime',
            filterable: true,
            sortable  : false,
            width     : 170,
            Cell      : ({ value, original }) => {
                return <>
                    <span className="fa-layers fa-fw fa-2x">
                        <FontAwesomeIcon icon={iconsByMimeType[original.mime]} />
                    </span>
                    {value}
                </>;
            },
            Filter    : ({ filter, onChange }) => {
                return (
                    <FilterDropdown options={filesTypes}
                                    value={filter ? filter.value : ''}
                                    onChange={(value) => onChange(value)} />
                )
            }
        },
        {
            Header    : 'Size',
            accessor  : 'size',
            filterable: false,
            sortable  : false,
            width     : 120,
            Cell      : props => (new DiskSize(props.value)).toString()
        },
        {
            Header    : 'Actions',
            accessor  : 'actions',
            filterable: false,
            sortable  : false,
            width     : 150,
            Cell      : ({original}: RowInfo) => (
                <ul className="list-unstyled d-flex align-items-center m-0">
                    <li>
                        <Button
                            className="pb-0 pt-0 text-danger"
                            color="link"
                            size="sm"
                            onClick={() => onDelete(original)}
                        >Delete</Button>
                    </li>
                    <li>
                        <Button
                            className="pb-0 pt-0"
                            color="link"
                            size="sm"
                            onClick={() => onPreview(original)}
                        >Preview</Button>
                    </li>
                </ul>
            ),
        },
    ];

    const onFetchData = (state: any) => {
        setIsLoading(true);

        const requestParams = {
            page    : state.page,
            pageSize: state.pageSize,
            filtered: state.filtered,
            sorted  : state.sorted,
        } as IReactTableRequestParams;

        setRequestParams(requestParams);

        AppUserFilesService.filesDs(appUserId, {
            filters : extractGridFilters(requestParams.filtered),
            sorters : extractGridSorters(requestParams.sorted),
            page    : requestParams.page + 1,
            pageSize: requestParams.pageSize,
        }).then(({data, pages}) => {
            setIsLoading(false);
            setData(data);
            setPages(pages);
        }).catch((e: Error) => {
            setIsLoading(false);
            MessageHandler.displayErrorMessage(e.message);
        });
    };

    return (
        <>
            <ReactTable
                manual
                columns={columns}
                data={data}
                defaultPageSize={10}
                loading={isLoading}
                pages={pages}
                onFetchData={onFetchData}
            />
        </>
    );
};

export default FilesGrid;
