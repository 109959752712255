import AuthService from './Core/Security/AuthenticationService';
import ClassNames from 'classnames';
import EventService from 'eventservice';
import Header from './Components/Header';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './Core/Router';
import Sidebar from './Components/Sidebar';
import {ToastContainer} from 'react-toastify';
import {UIRouter, UIView} from '@uirouter/react';
import {CLOSE_SIDEBAR, TOGGLE_SIDEBAR} from './Components/Sidebar/events';
import {SUCCESS_USER_AUTH, END_USER_SESSION, START_USER_SESSION} from './Core/Security/SecurityEvents';
import './scss/app.scss';

class App {
    protected isSidebarVisible: boolean = false;

    public init(): void {
        // enable event service debugging
        if (process.env['NODE_ENV'] !== 'production' && console) {
            (console as any).EventServiceDebug = true;
        }

        this.listenForUserEvents();
        this.listenForLayoutEvents();

        if (!AuthService.isAuthenticated()) {
            this.showLogin();
        }
    }

    private handleOnContentOverlayClick(): void {
        EventService.fire(CLOSE_SIDEBAR);
    }

    private listenForUserEvents() {
        EventService.on(SUCCESS_USER_AUTH, async () => {
            Router.stateService.go('users');
        });

        EventService.on(START_USER_SESSION, async () => {
            this.showAdmin();
        });

        EventService.on(END_USER_SESSION, async () => {
            this.showLogin();

            Router.stateService.go('login');
        });
    }

    private listenForLayoutEvents() {
        EventService.on(CLOSE_SIDEBAR, async () => {
            this.isSidebarVisible = false;
            this.showAdmin();
        });

        EventService.on(TOGGLE_SIDEBAR, async (isSidebarVisible: boolean) => {
            this.isSidebarVisible = isSidebarVisible;
            this.showAdmin();
        });
    };

    private showAdmin() {
        const wrapperClassNames = ClassNames({
            'sidebar-open': this.isSidebarVisible,
            'wrapper'     : true,
        });

        const contentOverlayClasses = ClassNames({
            'content-overlay': true,
            'show'           : this.isSidebarVisible,
        });

        ReactDOM.render(
            <UIRouter router={Router}>
                <>
                    <div className={wrapperClassNames}>
                        <Sidebar />
                        <main className="main">
                            <Header />
                            <UIView />
                        </main>
                        <footer className="footer">
                        </footer>
                    </div>
                    <ToastContainer autoClose={false}
                                    className={'toast-container'}
                                    closeOnClick
                                    newestOnTop
                                    position={'bottom-right'}
                    />
                    <div className={contentOverlayClasses}
                         onClick={this.handleOnContentOverlayClick}
                    />
                </>
            </UIRouter>,
            document.getElementById('app'));
    }

    private showLogin() {
        ReactDOM.render(
            <UIRouter router={Router}>
                <>
                    <ToastContainer autoClose={false}
                                    className={'toast-container'}
                                    closeOnClick
                                    draggable
                                    position={'top-center'}
                                    newestOnTop
                                    rtl={false}
                    />
                    <UIView />
                </>
            </UIRouter>,
            document.getElementById('app')
        );
    }
}

export default new App();
