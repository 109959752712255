// @ts-ignore
import React from 'react';
import autoBind from 'react-autobind';
import ReactTable from 'react-table';
import Button from 'reactstrap/lib/Button';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import FormService from '../../Core/Form/FormService';
import IForm from '../../Core/Form/IForm';
import {
    extractGridFilters,
    extractGridSorters,
    GridDefaultPageSize,
    IGridState,
    IReactTableRequestParams
} from '../../Core/Grid/IDataGrid';

interface IProps {
    onDelete: Function;
    onEdit: Function;
    // onReady: Function;
    reload?: boolean;
}

class FormGrid extends React.Component<IProps, IGridState> {
    protected data: Array<IForm>;
    protected pages: number;

    protected onDeleteDelegate: Function;
    protected onEditDelegate: Function;
    protected onReady: Function;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.onDeleteDelegate = props.onDelete;
        this.onEditDelegate = props.onEdit;
        this.onReady = props.onReady;
        this.pages = null;

        this.state = {
            isReady      : false,
            reload       : false,
            pageSize     : GridDefaultPageSize,
            requestParams: {
                page    : 1,
                pageSize: GridDefaultPageSize,
                filtered: null,
                sorted  : null
            },
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.reload !== prevState.reload) {
            return { reload: nextProps.reload };
        }

        return null;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.reload && !prevState.reload) {
            this.getData(this.state.requestParams);
        }
    }

    render() {
        const columns = [
            {
                Header    : 'Name',
                accessor  : 'name',
                filterable: true,
                sortable  : true,
            },
            {
                Header    : '',
                accessor  : 'actions',
                filterable: false,
                sortable  : false,
                width     : 140,
                Cell      : ({ original }) => (
                    <>
                        <Button
                            color="link"
                            className="pb-0 pt-0"
                            size="sm"
                            onClick={() => {
                                this.onEdit(original);
                            }}
                        >Edit</Button>
                        <Button
                            className="pb-0 pt-0 text-danger"
                            color="link"
                            size="sm"
                            onClick={() => {
                                this.onDelete(original);
                            }}
                        >Delete</Button>
                    </>
                )
            },
        ];

        return (
            <ReactTable
                manual
                columns={columns}
                data={this.data}
                defaultPageSize={this.state.pageSize}
                loading={!this.state.isReady}
                pages={this.pages}
                onFetchData={this.onFetchData}
            />
        );
    }

    protected getData(params: any): void {
        this.setState({ isReady: false });

        // @ts-ignore
        this.props.registerPromise(
            FormService.formsList({
                filters : extractGridFilters(params.filtered),
                sorters : extractGridSorters(params.sorted),
                page    : params.page,
                pageSize: params.pageSize,
            })
        ).then(({ data, pages }) => {
            this.data = data;
            this.pages = pages;
            this.setState({ isReady: true, reload: false });
            this.onReady();
        });
    }

    protected onDelete(form: IForm) {
        if (window.confirm('Are you sure you want to delete this form? It can be used in various pages.')) {
            this.onDeleteDelegate(form);
        }
    }

    protected onEdit(form: IForm) {
        this.onEditDelegate(form);
    }

    protected onFetchData(state: any): void {
        const requestParams = {
            page    : state.page + 1,
            pageSize: state.pageSize,
            filtered: state.filtered,
            sorted  : state.sorted,
        } as IReactTableRequestParams;

        this.setState({ requestParams: requestParams });
        this.getData(requestParams);
    }
}

/**
 * Prevent React setState on unmounted Component.
 *
 * @see https://github.com/hjylewis/trashable-react
 */
export default makeComponentTrashable(FormGrid);
