import EventService from 'eventservice';
import { differenceWith, isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import flatten from 'lodash/flatten';
import has from 'lodash/has';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import split from 'lodash/split';
import stubArray from 'lodash/stubArray';
import times from 'lodash/times';
import React from 'react';
import autoBind from 'react-autobind';
import { DragDropContext } from 'react-beautiful-dnd';
import Button from 'reactstrap/lib/Button';
import ButtonToolbar from 'reactstrap/lib/ButtonToolbar';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import urlSlug from 'url-slug'
import uuid from 'uuid/v4';
import FormService from "../../Core/Form/FormService";
import IForm from '../../Core/Form/IForm';
import MessageHandler from '../../Core/MessageHandler';
import { FORM } from '../../Core/Page/constants';
import { COPY_SECTION, COPY_WIDGET, DELETE_SECTION, DELETE_WIDGET, EDIT_SECTION, EDIT_WIDGET } from '../../Core/Page/events';
import IPageEditorState from '../../Core/Page/IPageEditorState';
import IPropertyEvent from '../../Core/Page/IPropertyEvent';
import IWidget from '../../Core/Page/IWidget';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import { getSectionDefaultValues, sections } from '../../Core/Page/sections';
import { getWidgetDefaultValues, widgets } from '../../Core/Page/widgets';
import Router from '../../Core/Router';
import { buildExistingFormContent } from '../../Modules/PageEdit';
import DroppableHoc from '../DroppableHoc';
import ConfigPanel from './ConfigPanel';
import SectionsList from './Sections/SectionsList';
import IWidgetActionProps from './Widgets/IWidgetActionProps';
import WidgetsPanel from './WidgetsPanel';

const reorder = (list, startIndex, endIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
};

const onBackToList = () => {
    Router.stateService.go('pages');
};

const PreviewPanel = DroppableHoc(SectionsList);

class PageEditor extends React.Component<any, IPageEditorState> {
    protected selectedSection: number;
    protected selectedWidget: string;
    // protected forms: Array<{ id, name, content }>;

    state = {
        configPanelVisible : false,
        sections           : [],
        selectedValues     : null,
        selectedSection    : null,
        selectedWidgetType : null,
        selectedSectionType: null,
        title              : '',
        slug               : '',
        relations          : [],
        forms              : []
    };

    constructor(props) {
        super(props);
        autoBind(this);

        this.selectedSection = null;
        this.selectedWidget = null;
    }

    componentDidMount(): void {
        this.setData(this.props.data);
        this.setPageConfigFields('title', this.props.title);
        this.setPageConfigFields('slug', this.props.slug);

        //TODO Move populate relations when refactoring pages
        this.setState({
            relations: this.props.relations
        });

        EventService.on(COPY_SECTION, async (index) => {
            this.onCopySection(index);
        });

        EventService.on(COPY_WIDGET, async (widgetActionProps: IWidgetActionProps) => {
            this.onCopyWidget(widgetActionProps);
        });

        EventService.on(DELETE_SECTION, async (index) => {
            this.onDeleteSection(index);
        });

        EventService.on(DELETE_WIDGET, async (widgetActionProps: IWidgetActionProps) => {
            this.onDeleteWidget(widgetActionProps);
        });

        EventService.on(EDIT_SECTION, async (index) => {
            this.onEditSection(index);
        });

        EventService.on(EDIT_WIDGET, async (widgetActionProps: IWidgetActionProps) => {
            this.onEditWidget(widgetActionProps);
        });

        // When moving to functional component inject from state
        FormService.getForms().then(({forms}) => {
            this.setState({ forms: forms });
        });

        PropertyConfigService.subscribe(async (event: IPropertyEvent) => {
            const selectedValues = this.state.selectedValues;

            if (!has(selectedValues, event.propertyGroup)) {
                selectedValues[event.propertyGroup] = {};
            }
            selectedValues[event.propertyGroup][event.propertyId] = event.value;

            if (event.propertyId === 'columns') {
                this.updateColumnsForSection(this.selectedSection, event.value, selectedValues);
            } else {
                this.setState({ selectedValues });
            }
        });
    }

    componentWillUnmount(): void {
        EventService.off(EDIT_WIDGET);
        EventService.off(COPY_SECTION);
        EventService.off(COPY_WIDGET);
        EventService.off(DELETE_SECTION);
        EventService.off(DELETE_WIDGET);
        EventService.off(EDIT_SECTION);
        EventService.off(EDIT_WIDGET);
        PropertyConfigService.unsubscribe();
    }

    render() {
        return (
            <>
                <header className="page-header d-flex align-items-center sticky-top border-bottom">
                    <h4 className="m-0">
                        {this.props.isNew ? 'Create page' : 'Edit page'}
                    </h4>
                    <ButtonToolbar className="page-actions ml-auto">
                        <Button outline onClick={onBackToList}>Back to list</Button>
                        <Button
                            color="primary"
                            className="ml-1"
                            onClick={() => this.props.onSave(this.state.sections, this.state.title, this.state.slug, this.state.relations)}>
                            {this.props.isNew ? 'Create' : 'Update'}
                        </Button>
                    </ButtonToolbar>
                </header>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Container fluid>
                        <Row className="page-container">
                            <Col className="preview-panel" xl={10}>
                                <PreviewPanel
                                    droppableId="preview"
                                    type="section"
                                    sections={this.state.sections}
                                    selectedSection={this.selectedSection}
                                    selectedWidget={this.selectedWidget}
                                />
                            </Col>
                            <Col className="d-none d-xl-block tools-panel" xl={2} md={3}>
                                <WidgetsPanel
                                    title={this.props.title}
                                    slug={this.state.slug}
                                    onChange={this.setPageConfigFields}
                                    forms={this.state.forms}
                                />
                                <ConfigPanel
                                    isVisible={this.state.configPanelVisible}
                                    onClose={this.onConfigPanelClose}
                                    selectedValues={this.state.selectedValues}
                                    selectedWidgetType={this.state.selectedWidgetType}
                                    selectedSectionType={this.state.selectedSectionType}
                                />
                            </Col>
                        </Row>
                    </Container>
                </DragDropContext>
            </>
        );
    }

    protected onDragEnd(result) {
        const { destination, source, draggableId, type } = result;

        if (!destination) {
            return;
        }

        // Existing form drop
        if (isNumber(draggableId)) {
            const selectedForm = find(this.state.forms, { id: draggableId });

            this.addExistingForm(selectedForm, destination.droppableId, destination.index);

            return;
        }

        switch (source.droppableId) {
            case destination.droppableId:
                if (type === 'section') {
                    this.reorderSections(source.index, destination.index);
                } else {
                    this.reorderWidgets(destination.droppableId, source.index, destination.index);
                }
                break;

            case 'sections':
                const section = this.getSection(draggableId);
                this.addSection(section, destination.index);
                break;

            case 'widgets':
                const widget = this.getWidget(draggableId);
                this.addWidget(widget, destination.droppableId, destination.index);
                break;

            default:
                this.moveWidget(draggableId, destination, source);
                return false;
        }
    }

    protected addSection(section, sectionIndex): void {
        const destSections = cloneDeep(this.state.sections);
        const defaultValues = getSectionDefaultValues();
        const values = {
            ...defaultValues,
            ...section.values
        };
        const sectionId = uuid();

        destSections.splice(sectionIndex, 0, { content: section.content, id: sectionId, type: section.type, values: values });

        this.setState({ sections: destSections });
    }

    protected addWidget(widget: IWidget, droppableId, widgetIndex): void {
        const [sectionId, contentIndex] = split(droppableId, '_');
        const destSections = cloneDeep(this.state.sections);
        const destSection = find(destSections, { id: sectionId });

        // In case the widget already has some values (ex on copy) then
        // make sure these values will be copied as well
        const defaultValues = getWidgetDefaultValues(widget.type);
        const values = {
            ...defaultValues,
            ...widget.values
        };

        const content = cloneDeep(destSection.content[contentIndex]);
        content.splice(widgetIndex, 0, { id: uuid(), type: widget.type, values: values });

        const destIndex = findIndex(destSections, { id: sectionId });
        destSections[destIndex]['content'][contentIndex] = content;

        this.setState({ sections: destSections });
    }

    protected addExistingForm = (form: IForm, droppableId, widgetIndex) => {

        const [sectionId, contentIndex] = split(droppableId, '_');
        const destSections = cloneDeep(this.state.sections);
        const destSection = find(destSections, { id: sectionId });

        // In case the widget already has some values (ex on copy) then
        // make sure these values will be copied as well
        // const defaultValues = getWidgetDefaultValues('form');
        // const values = {
        //     ...defaultValues,
        //     ...widget.values
        // };

        const content = cloneDeep(destSection.content[contentIndex]);

        //TODO - BUILD HERE FORM HTML. How ????? Move to existing form widget
        const formContent = buildExistingFormContent(form);

        content.splice(widgetIndex, 0, {
            id        : uuid(),
            type      : 'form',
            values    : formContent,
            formId    : form.id,
            references: [{ type: FORM, id: form.id}],
        });

        const destIndex = findIndex(destSections, { id: sectionId });
        destSections[destIndex]['content'][contentIndex] = content;

        // Push recently added form in page relations
        const pageRelations = cloneDeep(this.state.relations);
        pageRelations.push({ type: FORM, id: form.id });

        this.setState({ sections: destSections, relations: pageRelations });
    };

    protected getSection(sectionId: string) {
        return cloneDeep(find(sections, { id: sectionId }));
    }

    protected getWidget(widgetId: string) {
        return cloneDeep(find(widgets, { id: widgetId }));
    }

    protected onConfigPanelClose(): void {
        this.selectedSection = null;
        this.selectedWidget = null;

        this.setState({ configPanelVisible: false });
    }

    protected onCopySection(sectionIndex): void {

        const section = cloneDeep(this.state.sections[sectionIndex]);
        const newRelations = this.computeRelationsOnCopySection(section);

        this.addSection(section, sectionIndex + 1);
        this.selectedSection = null;
        this.setState({ configPanelVisible: false, relations: newRelations });
    }

    protected onCopyWidget(widgetActionProps: IWidgetActionProps): void {
        const sections = cloneDeep(this.state.sections);
        const sectionIndex = findIndex(sections, { id: widgetActionProps.sectionId });
        const content = cloneDeep(sections[sectionIndex].content[widgetActionProps.columnIndex]);
        const widgetIndex = findIndex(content, { id: widgetActionProps.widgetId });
        const widget = content[widgetIndex] as IWidget;
        const droppableId = `${widgetActionProps.sectionId}_${widgetActionProps.columnIndex}`;

        this.addWidget(widget, droppableId, widgetIndex + 1);

        this.selectedWidget = null;
        this.setState({ configPanelVisible: false });
    }

    // Copy section case
    protected computeRelationsOnCopySection(section) {

        let newRelations = cloneDeep(this.state.relations);

        each(section.content, (widgetsList, index) => {
            each(widgetsList, (widget, widgetIndex) => {
                if (widget.type === 'form') {
                    each(widget.references, (widgetReference) => {
                        // If having existing form widget in the section
                        if (widgetReference.type === FORM) {
                            newRelations.push(widgetReference)
                        }
                    });
                }
            });
        });

        return newRelations
    }

    // Delete section case
    protected computeRelationsToRemoveForDeleteSection(section) {

        const relationsToDelete = [];

        each(section.content, (widgetsList, index) => {
            each(widgetsList, (widget, widgetIndex) => {
                if (widget.type === 'form') {
                    each(widget.references, (widgetReference) => {
                        // If having existing form widget in the section
                        if (widgetReference.type === FORM) {
                            // @TODO Now enough, we need to have also a section id reference to know what form ro remove. Can have the same
                            // form in multiple sections, will remove all the references in this case
                            relationsToDelete.push(widgetReference)
                        }
                    });
                }
            });
        });

        let newRelations = cloneDeep(this.state.relations);
        newRelations = differenceWith(newRelations, relationsToDelete, isEqual);

        return newRelations
    }

    // Delete widget case
    protected computeRelationsToRemoveForDeleteWidget(sectionContent, widgetIndexToRemove) {

        const relationsToDelete = [];

        each(sectionContent, (widget, widgetIdx) => {
            // Push in relationsToDelete just the widget reference of the widget we are deleting
            if (widget.type === 'form' && widgetIndexToRemove === widgetIdx) {
                each(widget.references, (widgetReference) => {
                    // If having existing form widget
                    if (widgetReference.type === FORM) {
                        // @TODO Now enough, we need to have also a section id reference to know what form ro remove. Can have the same
                        // form in multiple sections, will remove all the references in this case
                        relationsToDelete.push(widgetReference)
                    }
                });
            }
        });

        let newRelations = cloneDeep(this.state.relations);
        newRelations = differenceWith(newRelations, relationsToDelete, isEqual);

        return newRelations
    }

    protected onDeleteSection(sectionIndex): void {
        const sections = cloneDeep(this.state.sections);

        if (sections.length === 1) {
            MessageHandler.displayErrorMessage('There must always be at least one section.');
            return;
        }

        const newRelations = this.computeRelationsToRemoveForDeleteSection(sections[sectionIndex]);

        sections.splice(sectionIndex, 1);

        this.selectedSection = null;
        this.setState({
            configPanelVisible: false,
            sections          : sections,
            relations         : newRelations
        });
    }

    protected onDeleteWidget(widgetActionProps: IWidgetActionProps): void {

        const sections = cloneDeep(this.state.sections);
        const sectionIndex = findIndex(sections, { id: widgetActionProps.sectionId });
        const content = cloneDeep(sections[sectionIndex].content[widgetActionProps.columnIndex]);
        const widgetIndex = findIndex(content, { id: widgetActionProps.widgetId });
        const newRelations = this.computeRelationsToRemoveForDeleteWidget(content, widgetIndex);

        content.splice(widgetIndex, 1);
        sections[sectionIndex].content[widgetActionProps.columnIndex] = content;

        this.selectedWidget = null;
        this.setState({
            configPanelVisible: false,
            sections          : sections,
            relations         : newRelations
        });
    }

    protected onEditSection(sectionIndex): void {
        const section = this.state.sections[sectionIndex];

        this.selectedSection = sectionIndex;
        this.selectedWidget = null;

        this.setState({
            configPanelVisible : true,
            selectedWidgetType : null,
            selectedSectionType: 'full_width',
            selectedValues     : section.values
        });
    }

    protected onEditWidget(widgetActionProps: IWidgetActionProps): void {
        const section = find(this.state.sections, { id: widgetActionProps.sectionId });
        const widgets = keyBy(flatten(section.content), 'id');
        const widget = widgets[widgetActionProps.widgetId] as IWidget;

        this.selectedSection = null;
        this.selectedWidget = widgetActionProps.widgetId;

        this.setState({
            configPanelVisible : true,
            selectedSectionType: null,
            selectedWidgetType : widget.type,
            selectedValues     : widget.values
        });
    }

    private moveWidget(draggableId, destination, source) {
        const sections = cloneDeep(this.state.sections);

        // get source details
        const [sourceSectionId, sourceContentIndex] = split(source.droppableId, '_');
        const sourceSection = find(sections, { id: sourceSectionId });
        const sourceContent = cloneDeep(sourceSection.content[sourceContentIndex]);
        const sourceSectionIndex = findIndex(sections, { id: sourceSectionId });

        // get widget details
        const widget = find(sourceContent, { id: draggableId }) as IWidget;

        // get destination details
        const [destinationSectionId, destinationContentIndex] = split(destination.droppableId, '_');
        const destinationSection = find(sections, { id: destinationSectionId });
        const destinationContent = cloneDeep(destinationSection.content[destinationContentIndex]);
        const destinationSectionIndex = findIndex(sections, { id: destinationSectionId });

        // add widget in destination
        destinationContent.splice(destination.index, 0, { ...widget, id: uuid() });
        sections[destinationSectionIndex]['content'][destinationContentIndex] = destinationContent;

        // remove widget from source
        sourceContent.splice(source.index, 1);
        sections[sourceSectionIndex].content[sourceContentIndex] = sourceContent;

        // sync state
        this.setState({ sections });
    }

    private reorderSections(startIndex, endIndex) {
        const reorderedSections = reorder(this.state.sections, startIndex, endIndex);

        this.setState({ sections: reorderedSections });
    }

    private reorderWidgets(droppableId, startIndex: number, endIndex: number) {
        const [sectionId, contentIndex] = split(droppableId, '_');
        const destSections = cloneDeep(this.state.sections);
        const destSection = find(destSections, { id: sectionId });
        const sectionIndex = findIndex(destSections, { id: sectionId });
        const content = cloneDeep(destSection.content[contentIndex]);

        destSections[sectionIndex]['content'][contentIndex] = reorder(content, startIndex, endIndex);

        this.setState({ sections: destSections });
    }

    private setData(data: any) {
        let sections = [];
        const defaultValues = getSectionDefaultValues();

        each(data, (item) => {
            const values = {
                ...defaultValues,
                ...item.values
            };

            const section = { ...item, id: item.id || uuid(), values: values };

            sections.push(section);
        });

        this.setState({ sections });
    }

    private setPageConfigFields(field, value: string) {
        switch (field) {
            case 'title': {
                this.setState({
                    title: value,
                    slug : urlSlug(value, {
                        separator  : '-',
                        transformer: urlSlug.transformers.lowercase
                    })
                });
                break;
            }
            case 'slug':
                const processedSlug = urlSlug(value, {
                    separator  : '-',
                    transformer: urlSlug.transformers.lowercase
                });
                if (value !== processedSlug) {
                    MessageHandler.displayInfoMessage('Desired slug contains invalid characters so it was processed to a valid one !', 3);
                }
                this.setState({
                    slug: processedSlug
                });
                break;
            default:
                break;
        }
    }

    private updateColumnsForSection(selectedSectionIdx: number, columnsNumber: number, selectedValues: any) {
        const sections = cloneDeep(this.state.sections);
        const selectedSection = sections[selectedSectionIdx];
        let content = cloneDeep(selectedSection.content);

        if (columnsNumber > content.length) {
            const columnsToAdd = columnsNumber - content.length;
            const newColumns = times(columnsToAdd, stubArray);

            content.push(...newColumns);
        } else {
            const columnsToRemove = content.length - columnsNumber;

            content.splice(-columnsToRemove, columnsToRemove);
        }

        selectedSection.content = content;
        selectedSection.values = selectedValues;

        this.setState({ sections });
    }
}

export default PageEditor;
