import has from 'lodash/has';
import map from 'lodash/map';
import React, { CSSProperties } from 'react';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import { getValueForProperty } from '../../../Core/Page/properties';
import CheckboxField from '../../FormFields/CheckboxField';
import IField from '../../FormFields/IField';
import InputField from '../../FormFields/InputField';
import RadioField from '../../FormFields/RadioField';
import SelectField from '../../FormFields/SelectField';
import DraggableWidget from './DraggableWidget';

interface ISubmitButton {
    containerStyle: CSSProperties;
    label: string;
    style: CSSProperties;
}

const fieldComponentMap = {
    checkbox : CheckboxField,
    date     : InputField,
    email    : InputField,
    number   : InputField,
    password : InputField,
    plaintext: InputField,
    radio    : RadioField,
    search   : InputField,
    select   : SelectField,
    text     : InputField,
    time     : InputField,
    textarea : InputField,
};

function componentForFormFieldType(fieldType: string) {
    if (!has(fieldComponentMap, fieldType)) {
        throw new Error(`Field type "${fieldType}" has no associated component.`);
    }

    return fieldComponentMap[fieldType];
}

const Form = (props: { button: ISubmitButton, fields: IField[], style: CSSProperties }) => {
    return (
        <div style={props.style}>
            {
                map(props.fields, (field, index) => {
                    const ComponentName = componentForFormFieldType(field.type);

                    return (
                        <ComponentName
                            {...field}
                            key={index}
                        />
                    );
                })
            }
            <FormGroup style={props.button.containerStyle}>
                <Button style={props.button.style}>{props.button.label}</Button>
            </FormGroup>
        </div>
    );
};

const Widget = DraggableWidget(Form);

const FormWidget = (props) => {

    const button = {
        containerStyle: {
            textAlign: getValueForProperty(props.values, 'button', 'alignment'),
        },
        label         : getValueForProperty(props.values, 'button', 'label'),
        style         : {
            backgroundColor: getValueForProperty(props.values, 'button', 'color'),
            borderColor    : getValueForProperty(props.values, 'button', 'borderColor'),
            color          : getValueForProperty(props.values, 'button', 'textColor'),
            margin         : getValueForProperty(props.values, 'button', 'margin'),
        },
    };
    const fields = getValueForProperty(props.values, 'fields', 'fieldsList');
    // const style = {
    //     color: getValueForProperty(props.values, 'general', 'color')
    // };

    return (
        <Widget
            {...props.draggableWidgetProps}
            button={button}
            fields={fields}
            // style={style}
            type="form"
        />
    );
};

export default FormWidget;
