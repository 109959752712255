import forEach from 'lodash/forEach';
import has from 'lodash/has';
import IProperty from './IProperty';

interface ISectionColumns {
    columns: number;
}

interface ISectionValues {
    columns: ISectionColumns;
}

export interface ISection {
    content: any[];
    id: string;
    label: string;
    type: string;
    values: ISectionValues;
}

export function getSectionDefaultValues() {
    const defaultValues = {};
    const sectionProperties = sectionsProperties['full_width'];

    forEach(sectionProperties, (group, groupKey) => {
        defaultValues[groupKey] = {};

        forEach(group.items, (property: IProperty, propertyKey: string) => {
            if (!has(property, 'defaultValue')) {
                throw new Error(`No default value for section "full_width.${groupKey}.${propertyKey}".`);
            }

            defaultValues[groupKey][propertyKey] = property.defaultValue;
        });
    });

    return defaultValues;
}

export const sections = [
    {
        id     : 'section',
        label  : 'Full width section',
        type   : 'full_width', // @todo Check if this can be removed
        values : {
            columns: {
                columns: 1
            }
        },
        content: [
            []
        ]
    },
    {
        id     : 'section2',
        label  : 'Section with 2 columns',
        type   : 'full_width', // @todo Check if this can be removed
        values : {
            columns: {
                columns: 2
            }
        },
        content: [
            [],
            []
        ]
    },
    {
        id     : 'section3',
        label  : 'Section with 3 columns',
        type   : 'full_width', // @todo Check if this can be removed
        values : {
            columns: {
                columns: 3
            }
        },
        content: [
            [],
            [],
            []
        ]
    },
    {
        id     : 'section4',
        label  : 'Section with 4 columns',
        type   : 'full_width', // @todo Check if this can be removed
        values : {
            columns: {
                columns: 4
            }
        },
        content: [
            [],
            [],
            [],
            []
        ]
    }
];

export const sectionsProperties = {
    full_width: {
        columns: {
            label   : 'Columns',
            position: 1,
            items   : {
                columns: {
                    defaultValue: '1',
                    id          : 'columns',
                    label       : 'Number of columns',
                    type        : 'columnsNumber'
                } as IProperty
            }
        },
        general: {
            label   : 'General',
            position: 2,
            items   : {
                bgColor: {
                    defaultValue: 'rgba(0, 0, 0, 0)',
                    id          : 'bgColor',
                    label       : 'Background Color',
                    type        : 'colorPicker'
                } as IProperty,
                color  : {
                    defaultValue: 'rgba(0, 0, 0, 0)',
                    id          : 'color',
                    label       : 'Content Background Color',
                    type        : 'colorPicker'
                } as IProperty,
                margin : {
                    defaultValue: '0px',
                    id          : 'margin',
                    label       : 'Margin',
                    type        : 'margin'
                } as IProperty
            }
        }
    }
};
