import size from 'lodash/size';
import React from 'react';
import PageEditor from '../Components/PageEditor/PageEditor';
import MessageHandler from '../Core/MessageHandler';
import PageService from '../Core/Page/PageService';
import Router from '../Core/Router';

const PageCreate = () => {
    const pageData = [];

    const onSave = (content: any, title: string, slug: string, relations: Array<{ type, id }>) => {

        if (!content.length) {
            MessageHandler.displayErrorMessage('Please add at least one section to the page content!');

            return false;
        }

        // If relations is empty array, then make it null. For the API to work.
        const pageRelations = size(relations) > 0 ? relations : null;

        PageService.createPage({ content, title, slug, relations: pageRelations })
                   .then(({ id }) => {
                       MessageHandler.displaySuccessMessage('The page has been created successfully.', 5);

                       Router.stateService.go('pages.edit', { id: id });
                   })
                   .catch(error => {
                       console.log('error on create page: %o', error);
                       // if (error.response.status === 404) {
                       //     MessageHandler.displayErrorMessage('Page not found.');
                       //     Router.stateService.go('pages');
                       // }
                       // if (error.response.status === 400) {
                       //     MessageHandler.displayException(error.response.data['violations']);
                       // }
                   });
    };

    return (<PageEditor data={pageData} isNew={true} onSave={onSave} title="New page" slug="" relations={[]} />);
};

export default PageCreate;
