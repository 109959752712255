import {faAddressCard} from '@fortawesome/free-solid-svg-icons/faAddressCard';
import {faCode} from '@fortawesome/free-solid-svg-icons/faCode';
import {faCrosshairs} from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import {faDesktop} from '@fortawesome/free-solid-svg-icons/faDesktop';
import {faFont} from '@fortawesome/free-solid-svg-icons/faFont';
import {faHandPointUp} from '@fortawesome/free-solid-svg-icons/faHandPointUp';
import {faImage} from '@fortawesome/free-solid-svg-icons/faImage';
import {faMap} from '@fortawesome/free-solid-svg-icons/faMap';
import forEach from 'lodash/forEach';
import has from 'lodash/has';
import uuid from 'uuid/v4';
import IProperty from './IProperty';
import IWidget from './IWidget';

const DEFAULT_IMAGE = 'https://via.placeholder.com/500x100?text=IMAGE';
const ALIGNMENT_CENTER = 'center';
const ALIGNMENT_LEFT = 'left';

export const widgets = [
    {
        id    : 'button',
        icon  : faHandPointUp,
        label : 'Button',
        type  : 'button',
        values: {}
    },
    {
        id    : 'contactInfo',
        icon  : faAddressCard,
        label : 'Contact',
        type  : 'contactInfo',
        values: {}
    },
    {
        id    : 'divider',
        icon  : faFont,
        label : 'Divider',
        type  : 'divider',
        values: {}
    },
    {
        id    : 'form',
        icon  : faDesktop,
        label : 'Form',
        type  : 'form',
        values: {}
    },
    {
        id    : 'googleMap',
        icon  : faMap,
        label : 'Map',
        type  : 'googleMap',
        values: {}
    },
    {
        id    : 'html',
        icon  : faCode,
        label : 'HTML',
        type  : 'html',
        values: {}
    },
    {
        id    : 'image',
        icon  : faImage,
        label : 'Image',
        type  : 'image',
        values: {}
    },
    {
        id    : 'prato',
        icon  : faCrosshairs,
        label : 'Prato',
        type  : 'prato',
        values: {}
    },
    {
        id    : 'text',
        icon  : faFont,
        label : 'Text',
        type  : 'text',
        values: {}
    }
] as IWidget[];

export const widgetsProperties = {
    button     : {
        general: {
            label   : 'General',
            position: 1,
            items   : {
                label: {
                    id          : 'label',
                    label       : 'Label',
                    defaultValue: 'Button text',
                    type        : 'text'
                } as IProperty,
                url  : {
                    id          : 'url',
                    label       : 'URL',
                    defaultValue: '',
                    type        : 'text'
                } as IProperty,
            }
        },
        styles : {
            label   : 'Style',
            position: 2,
            items   : {
                color      : {
                    defaultValue: 'rgb(35,139,198, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                borderColor: {
                    defaultValue: 'rgb(40,135,198,1)',
                    id          : 'color',
                    label       : 'Border color',
                    type        : 'colorPicker'
                } as IProperty,
                textColor  : {
                    defaultValue: 'rgb(255,255,255, 1)',
                    id          : 'textColor',
                    label       : 'Text color',
                    type        : 'colorPicker'
                } as IProperty,
                alignment  : {
                    defaultValue: ALIGNMENT_LEFT,
                    id          : 'alignment',
                    label       : 'Align',
                    type        : 'alignmentPicker'
                } as IProperty,
                margin     : {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
            }
        },
    },
    contactInfo: {
        contactInfo: {
            label   : 'Contact info',
            position: 1,
            items   : {
                name   : {
                    defaultValue: 'Contact name',
                    id          : 'name',
                    label       : 'Name',
                    type        : 'text'
                } as IProperty,
                address: {
                    defaultValue: {
                        icon : {prefix: 'fas', iconName: 'map-marker'},
                        value: ''
                    },
                    id          : 'address',
                    label       : 'Address',
                    type        : 'textWithIcon'
                } as IProperty,
                email  : {
                    defaultValue: {
                        icon : {prefix: 'fas', iconName: 'at'},
                        value: ''
                    },
                    id          : 'email',
                    label       : 'Email',
                    type        : 'textWithIcon'
                } as IProperty,
                mobile : {
                    defaultValue: {
                        icon : {prefix: 'fas', iconName: 'mobile-alt'},
                        value: ''
                    },
                    id          : 'mobile',
                    label       : 'Mobile',
                    type        : 'textWithIcon'
                } as IProperty,
                phone  : {
                    defaultValue: {
                        icon : {prefix: 'fas', iconName: 'phone'},
                        value: ''
                    },
                    id          : 'phone',
                    label       : 'Phone',
                    type        : 'textWithIcon'
                } as IProperty,
                website: {
                    defaultValue: {
                        icon : {prefix: 'fas', iconName: 'globe'},
                        value: ''
                    },
                    id          : 'website',
                    label       : 'Website',
                    type        : 'textWithIcon'
                } as IProperty,
            }
        }
    },
    divider    : {
        styles: {
            label   : 'Style',
            position: 1,
            items   : {
                color : {
                    id          : 'color',
                    label       : 'Color',
                    defaultValue: 'rgba(0, 0, 0, 1)',
                    type        : 'colorPicker'
                } as IProperty,
                height: {
                    id          : 'height',
                    label       : 'Height',
                    defaultValue: 1,
                    type        : 'range'
                } as IProperty,
                margin: {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
            }
        }
    },
    form       : { // Widget type
        fields : { // Property group
            label   : 'FormFields',
            position: 1,
            items   : { // Group items
                fieldsList: {
                    id          : 'fieldsList',
                    label       : '',
                    defaultValue: [
                        {
                            id         : uuid(),
                            isRequired : false,
                            label      : 'Text field',
                            name       : 'field_name',
                            placeholder: 'Placeholder',
                            type       : 'text',
                        }
                    ],
                    type        : 'formFieldsList',
                } as IProperty,
            }
        },
        button : {
            label   : 'Button',
            position: 2,
            items   : {
                label      : {
                    id          : 'label',
                    label       : 'Label',
                    defaultValue: 'Submit',
                    type        : 'text',
                } as IProperty,
                color      : {
                    defaultValue: 'rgb(35,139,198, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                borderColor: {
                    defaultValue: 'rgb(40,135,198,1)',
                    id          : 'color',
                    label       : 'Border color',
                    type        : 'colorPicker'
                } as IProperty,
                textColor  : {
                    defaultValue: 'rgb(255,255,255, 1)',
                    id          : 'textColor',
                    label       : 'Text color',
                    type        : 'colorPicker'
                } as IProperty,
                alignment  : {
                    defaultValue: ALIGNMENT_LEFT,
                    id          : 'alignment',
                    label       : 'Align',
                    type        : 'alignmentPicker'
                } as IProperty,
                margin     : {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
            }
        },
        general: {
            label   : 'General',
            position: 3,
            items   : {
                color: {
                    id          : 'color',
                    label       : 'Text color',
                    defaultValue: 'rgba(0, 0, 0, 1)',
                    type        : 'colorPicker'
                } as IProperty
            }
        }
    },
    googleMap  : {
        coordinates: {
            label   : 'Map options',
            position: 1,
            items   : {
                latitude : {
                    id          : 'latitude',
                    label       : 'Latitude',
                    defaultValue: 50.8549541,
                    type        : 'text'
                } as IProperty,
                longitude: {
                    id          : 'longitude',
                    label       : 'Longitude',
                    defaultValue: 4.3053509,
                    type        : 'text'
                } as IProperty,
                zoom     : {
                    id          : 'zoom',
                    label       : 'Zoom',
                    defaultValue: 7,
                    type        : 'range',
                    minValue    : 2,
                    maxValue    : 21
                } as IProperty
            }
        }
    },
    html       : { // Widget type
        html   : {
            label   : 'HTML',
            position: 1,
            items   : {
                content: {
                    id          : 'content',
                    label       : 'Content',
                    defaultValue: '<strong>Hello, world!</strong>',
                    type        : 'html'
                } as IProperty
            }
        },
        general: {
            label   : 'General',
            position: 2,
            items   : {
                bgColor: {
                    id          : 'bgColor',
                    label       : 'Background color',
                    defaultValue: 'rgba(0, 0, 0, 0)',
                    type        : 'colorPicker'
                } as IProperty,
                color  : {
                    id          : 'color',
                    label       : 'Text color',
                    defaultValue: 'rgba(0, 0, 0, 1)',
                    type        : 'colorPicker'
                } as IProperty,
                margin : {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
                padding: {
                    id          : 'padding',
                    label       : 'Padding',
                    defaultValue: '0px',
                    type        : 'padding'
                } as IProperty,
            }
        }
    },
    image      : { // Widget type
        image        : {
            label   : 'Image',
            position: 1,
            items   : {
                imageSource: {
                    defaultValue: DEFAULT_IMAGE,
                    id          : 'imageSource',
                    label       : 'Image URL',
                    type        : 'imageSource'
                } as IProperty,
                alignment  : {
                    defaultValue: ALIGNMENT_CENTER,
                    id          : 'alignment',
                    label       : 'Align',
                    type        : 'alignmentPicker'
                } as IProperty,
                margin     : {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
                width      : {
                    defaultValue: '100',
                    id          : 'width',
                    label       : 'Width',
                    type        : 'range'
                } as IProperty
            }
        },
        accessibility: {
            label   : 'Accessibility',
            position: 1,
            items   : {
                altText: {
                    defaultValue: '',
                    id          : 'altText',
                    label       : 'Alternate Text',
                    type        : 'text'
                } as IProperty
            }
        }
    },
    prato      : {
        backgroundImage: {
            label   : 'Background image',
            position: 1,
            items   : {
                imageSource: {
                    defaultValue: DEFAULT_IMAGE,
                    id          : 'imageSource',
                    label       : 'Image URL',
                    type        : 'imageSource'
                } as IProperty,
            }
        }
    },
    // Previous text widget
    text       : {
        text: {
            label   : 'Text',
            position: 1,
            items   : {
                content  : {
                    defaultValue: 'This is a new Text block.',
                    id          : 'content',
                    label       : 'Content',
                    type        : 'textarea'
                } as IProperty,
                color    : {
                    defaultValue: 'rgba(0, 0, 0, 1)',
                    id          : 'color',
                    label       : 'Color',
                    type        : 'colorPicker'
                } as IProperty,
                alignment: {
                    defaultValue: ALIGNMENT_LEFT,
                    id          : 'alignment',
                    label       : 'Align',
                    type        : 'alignmentPicker'
                } as IProperty,
                margin   : {
                    id          : 'margin',
                    label       : 'Margin',
                    defaultValue: '0px',
                    type        : 'margin'
                } as IProperty,
                padding  : {
                    id          : 'padding',
                    label       : 'Padding',
                    defaultValue: '0px',
                    type        : 'padding'
                } as IProperty,
            }
        }
    }
};

export function getWidgetDefaultValues(widgetType: string) {
    const defaultValues = {};
    const widgetProperties = widgetsProperties[widgetType];

    forEach(widgetProperties, (group, groupKey: string) => {
        defaultValues[groupKey] = {};

        forEach(group.items, (property: IProperty, propertyKey: string) => {
            if (!has(property, 'defaultValue')) {
                throw new Error(`No default value for "${widgetType}.${groupKey}.${propertyKey}".`);
            }

            defaultValues[groupKey][propertyKey] = property.defaultValue;
        });
    });

    return defaultValues;
}
