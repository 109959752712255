import axios from 'axios';
import MessageHandler from './MessageHandler';

class AxiosClient {

    init() {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        axios.interceptors.request.use(config => config, AxiosClient.errorHandler);
        axios.interceptors.response.use((response) => {
            const {data, status} = response;

            if (status === 200) {
                if (data.payload) {
                    return Promise.resolve(data.payload);
                }

                return Promise.resolve(response);
            }
            // Stupid resolve because API return NO_RESULTS. everything is ok but no data,
            if (status === 204) {
                return Promise.resolve([]);
            }

            // By default resolve with response.data (avoid useless checks on promise.then)
            return Promise.resolve(data);

        }, AxiosClient.errorHandler);
    }

    private static errorHandler(error) {
        // check for errorHandle config
        // eg. axios.get(url, {errorHandle: true|false})
        if (!error.config.hasOwnProperty('errorHandle') || error.config.errorHandle === false) {
            return Promise.reject(error);
        }

        if (error.response) {
            MessageHandler.displayErrorMessage(error.response.data.message);
        }
    }
}

export default new AxiosClient();



