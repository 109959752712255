import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UISref} from '@uirouter/react';
import isEmpty from 'lodash/isEmpty';
import React, {useEffect, useState} from 'react';
import {FileRejection} from 'react-dropzone';
import FilesCard from '../Components/AppUser/AppUserFiles/FilesCard';
import UploadFilesCard from '../Components/AppUser/AppUserFiles/UploadFilesCard';
import LoadingIndicator from '../Components/LoadingIndicator';
import AppUserService from '../Core/AppUser/AppUserService';
import MessageHandler from '../Core/MessageHandler';
import Router from '../Core/Router';
import IUser from '../Core/User/IUser';

const AppUserFiles = (props) => {
    const appUserId = Number(props.$stateParams.id);
    const [appUser, setAppUser] = useState({} as IUser);
    const [showUploadCard, setShowUploadCard] = useState(false);
    const [files, setFiles] = useState({acceptedFiles: [], fileRejections: []});
    const [isLoading, setIsLoading] = useState(false);
    const [reloadGrid, setReloadGrid] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        AppUserService
            .getUser(appUserId)
            .then(({user}) => {
                if (!user) {
                    throw new Error('No user found.');
                }
                setAppUser(user);
                setIsLoading(false);
            })
            .catch((e: Error) => {
                MessageHandler.displayErrorMessage(e.message);
                Router.stateService.go('app_users');
            });
    }, [appUserId]);

    const onAddFiles = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        setShowUploadCard(true);
        setFiles({acceptedFiles, fileRejections});
    };

    const onUploaded = () => {
        setShowUploadCard(false);
        setReloadGrid(true);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <header className="page-header d-flex align-items-center border-bottom mb-3">
                <h4 className="m-0">Manage user files</h4>
                <UISref to="app_users" className="btn btn-info btn-sm ml-auto">
                    <button type="button">Back to list</button>
                </UISref>
            </header>
            <div className="content">
                <section className="user-info d-flex align-items-center mb-5">
                    <div className="mr-3">
                        <span className="fa-layers fa-fw fa-4x">
                            <FontAwesomeIcon icon={faCircle} color="#fff" />
                            <FontAwesomeIcon icon={faUser} transform="shrink-10" />
                        </span>
                    </div>
                    <h5 className="mb-0">
                        {appUser.firstName} {appUser.lastName}
                        <small className="d-block">{appUser.email}</small>
                    </h5>
                </section>

                {showUploadCard && (
                    <section className="mb-3">
                        <UploadFilesCard
                            files={files}
                            appUserId={appUserId}
                            onClose={() => setShowUploadCard(false)}
                            onUpload={onUploaded}
                        />
                    </section>
                )}

                {!isEmpty(appUser) ? (
                    <section>
                        <FilesCard onAddFiles={onAddFiles} appUserId={appUserId} reloadGrid={reloadGrid} />
                    </section>
                ) : null}
            </div>
        </>
    );
};

export default AppUserFiles;
