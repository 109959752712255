import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

class IconFonts {
    init() {
        library.add(fas);
    }
}

export default new IconFonts();
