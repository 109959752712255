// @ts-ignore
import zxcvbn from 'zxcvbn';
import ClassNames from 'classnames';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

class PasswordMeterInput extends React.Component<any, { strength: number; }> {
    protected suggestions: string[];

    constructor(props: any) {
        super(props);

        this.state = { strength: 0 };
    }

    render() {
        const { id, label, value, className, field, form, withSuggestions, ...props } = this.props;
        const { strength } = this.state;
        const strengthClass = ClassNames({
            'strength-meter': true,
            'd-block'       : strength > 0,
            'd-none'        : strength === 0
        });

        return (
            <FormGroup className="position-relative">
                <Label for={id} className="label-color">{label}</Label>
                <div className={strengthClass}>
                    <div className="strength-meter-fill" data-strength={strength} />
                </div>
                <Input
                    type="password"
                    className={className}
                    id={id}
                    invalid={Boolean(form.touched[field.name] && form.errors[field.name])}
                    value={value}
                    onChange={this.handleOnChange.bind(this)}
                    maxLength={32}
                    {...props}
                />
                {
                    form.touched[field.name] && form.errors[field.name] &&
                    <div className="invalid-feedback">{form.errors[field.name]}</div>
                }
                {
                    withSuggestions && !isEmpty(this.suggestions) ? (
                        <ul className="list-unstyled">
                            {
                                map(this.suggestions, (suggestion, index) => (
                                    <li key={index}>
                                        <small>{suggestion}</small>
                                    </li>
                                ))
                            }
                        </ul>
                    ) : null
                }
            </FormGroup>
        );
    }

    protected handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        let strength = zxcvbn(value);

        if (value) {
            if (this.props.withSuggestions) {
                this.suggestions = strength.feedback.suggestions;
            }

            this.setState({ strength: strength.score });
        } else {
            this.suggestions = [];
            this.setState({ strength: 0 });
        }

        this.props.field.onChange(event);
    }
}

export default PasswordMeterInput;
