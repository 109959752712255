import React from 'react';
import Async from 'react-select/async';
import FormGroup from 'reactstrap/lib/FormGroup';
import OfficeService from '../../Core/Office/OfficeService';

interface IOfficesFilterProps {
    isDisabled: boolean;
    onChange: (val) => void;
    value: any[];
}

const OfficesFilter = (props: IOfficesFilterProps) => {
    const loadOptions = async () => {
        const resp = await OfficeService.getOffices();

        return resp.offices;
    };

    return (
        <FormGroup>
            <label>Offices</label>
            <Async
                cacheOptions
                defaultOptions
                getOptionLabel={opt => opt.name}
                getOptionValue={opt => opt.id}
                isDisabled={props.isDisabled}
                isMulti
                loadOptions={loadOptions}
                onChange={props.onChange}
                value={props.value}
            />
        </FormGroup>
    );
};

export default OfficesFilter;
