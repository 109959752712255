import {Editor} from '@tinymce/tinymce-react';
import React from 'react';

const plugins = [
    'advlist autolink lists link visualblocks insertdatetime paste help wordcount'
];

const toolbar = [
    {
        name: 'history', items: ['undo', 'redo']
    },
    {
        name: 'styles', items: ['styleselect']
    },
    {
        name: 'formatting', items: ['bold', 'italic', 'backcolor', 'forecolor']
    },
    {
        name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify']
    },
    {
        name: 'removeformat', items: ['removeformat']
    }
];

const initOptions = {
    branding     : false,
    height       : 400,
    menubar      : false,
    plugins      : plugins,
    toolbar      : toolbar,
    toolbar_mode : 'floating',
    paste_as_text: true
};

const NotificationMessage = (props: { defaultValue: string, onChange: (message: string) => void }) => {
    return (
        <>
            <Editor
                value={props.defaultValue}
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                init={initOptions}
                onEditorChange={props.onChange}
            />
        </>
    );
};

export default NotificationMessage;
