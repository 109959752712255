import map from 'lodash/map';
import React, { ReactElement } from 'react';
import ListGroup from 'reactstrap/lib/ListGroup';
import { IMenuItem } from '../../Core/Menu/IMenu';
import DroppableHoc from '../DroppableHoc';
import { DraggableDroppableMenuItem, DraggableMenuItem } from './MenuItem';

interface IMenuItemsListProps {
    items: IMenuItem[];
    menuId: string;
    placeholder?: ReactElement;
}

const MenuItemsList = ({ items, menuId, placeholder }: IMenuItemsListProps) => {
    if (!items.length && placeholder) {
        return <>{placeholder}</>;
    }

    return (
        <ListGroup tag="div" flush>
            {map(items, (menuItem, idx) => {
                const draggableProps = {
                    draggableId: `${menuId}_${menuItem.id}`,
                    index      : idx
                };

                if (menuItem.depth < 1) {
                    const droppableProps = {
                        droppableId: `${menuId}_${menuItem.id}`,
                        type       : 'menuItem'
                    };

                    return (
                        <DraggableDroppableMenuItem
                            className="list-group-item"
                            key={menuItem.id}
                            menuId={menuId}
                            menuItem={menuItem}
                            {...draggableProps}
                            {...droppableProps}
                        />
                    )
                }

                return (
                    <DraggableMenuItem
                        className="list-group-item"
                        key={menuItem.id}
                        menuId={menuId}
                        menuItem={menuItem}
                        {...draggableProps}
                    />
                )
            })}
        </ListGroup>
    )
};

export const DroppableMenuItems = DroppableHoc(MenuItemsList);

export default MenuItemsList;
