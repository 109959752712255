import React from 'react';
import DraggableWidget from './DraggableWidget';

const PratoApi = () => {
    return (
        <div>PRATO API</div>
    );
};

const Widget = DraggableWidget(PratoApi);

const PratoWidget = (props) => {
    return (
        <Widget {...props.draggableWidgetProps} />
    );
};

export default PratoWidget;
