import React from 'react';
import DraggableSection from './DraggableSection';
import DroppableHoc from '../../DroppableHoc';
import WidgetsList from '../Widgets/WidgetsList';

const DraggableDroppableSection = DraggableSection(DroppableHoc(WidgetsList));

const Section = (props: any) => {
    return (
        <>
            <DraggableDroppableSection
                droppableId={props.id}
                id={props.id}
                index={props.index}
                sectionId={props.id}
                isSelected={props.isSelected}
                selectedWidget={props.selectedWidget}
                type={'widget'}
                widgets={props.widgets}
                values={props.values}
            />
        </>
    );
};

// export default React.memo(Section);
export default Section;
