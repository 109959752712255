import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

export interface IProps {
    droppableId: string;
    isDropDisabled?: boolean;
    type: string;

    [propName: string]: any;
}

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#f8f8f8' : 'transparent',
    zIndex    : 1,
});

export default (WrappedComponent) => {
    const DroppableHoc = ({ droppableId, isDropDisabled, type, ...props }: IProps) => {
        return (
            <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled} type={type}>
                {({ droppableProps, innerRef, placeholder }, snapshot) => (
                    <div
                        className="h-100"
                        {...droppableProps}
                        ref={innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        <WrappedComponent {...props} />
                        {placeholder}
                    </div>
                )}
            </Droppable>
        );
    };

    DroppableHoc.displayName = `DroppableHoc(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return DroppableHoc;
};
