// @ts-ignore
import React from 'react';
import autoBind from 'react-autobind';
import ReactTable, {RowInfo} from 'react-table';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import AppUserService from '../../Core/AppUser/AppUserService';
import FilterDropdown from '../../Core/FilterDropdown';
import {
    extractGridFilters,
    extractGridSorters,
    GridDefaultPageSize,
    IGridState,
    IReactTableRequestParams
} from '../../Core/Grid/IDataGrid';
import OfficeService from '../../Core/Office/OfficeService';
import IUser from '../../Core/User/IUser';
import GridActions from './GridActions';

interface IProps {
    onAssignFiles?: Function;
    onChangePassword?: Function;
    onDelete: Function;
    onReady: Function;
    reload?: boolean;
}

class AppUserGrid extends React.Component<IProps, IGridState> {
    protected data: Array<IUser>;
    protected pages: number;
    protected offices: Array<{ id, name }>;

    protected onAssignFilesDelegate: Function;
    protected onChangePasswordDelegate: Function;
    protected onDeleteDelegate: Function;
    protected onReady: Function;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.onAssignFilesDelegate = props.onAssignFiles;
        this.onChangePasswordDelegate = props.onChangePassword;
        this.onDeleteDelegate = props.onDelete;
        this.onReady = props.onReady;
        this.pages = null;
        this.offices = [];

        this.state = {
            isReady      : false,
            reload       : false,
            pageSize     : GridDefaultPageSize,
            requestParams: {
                page    : 1,
                pageSize: GridDefaultPageSize,
                filtered: null,
                sorted  : null
            },
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.reload !== prevState.reload) {
            return {reload: nextProps.reload};
        }

        return null;
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.reload && !prevState.reload) {
            this.getData(this.state.requestParams);
        }
    }

    componentDidMount() {
        // Get offices request  for filter
        OfficeService.getOffices().then(({offices}) => {
            offices.unshift({id: '', name: 'Any'})
            this.offices = offices;
        });
    }

    render() {
        const columns = [
            {
                Header    : 'First name',
                accessor  : 'firstName',
                filterable: true,
                sortable  : true,
                width     : 160
            },
            {
                Header    : 'Last name',
                accessor  : 'lastName',
                filterable: true,
                sortable  : true,
                width     : 160
            },
            {
                Header    : 'Email',
                accessor  : 'email',
                filterable: true,
                sortable  : true,
            },
            {
                Header    : 'Office',
                accessor  : 'officeName',
                filterable: true,
                sortable  : false,
                width     : 220,
                Filter    : ({filter, onChange}) => {
                    return (
                        <FilterDropdown options={this.offices}
                                        value={filter ? filter.value : ''}
                                        onChange={(value) => onChange(value)} />
                    )
                }
            },
            {
                Header    : 'Role',
                accessor  : 'role',
                filterable: false,
                sortable  : false,
                width     : 120,
            },
            {
                Header    : 'Actions',
                accessor  : 'actions',
                filterable: false,
                sortable  : false,
                width     : 240,
                Cell      : ({original}: RowInfo) => (
                    <GridActions
                        onManageFiles={() => this.onAssignFiles(original)}
                        onChangePassword={() => this.onChangePassword(original)}
                        onDelete={() => this.onDelete(original)}
                    />
                )
            },
        ];

        return (
            <ReactTable
                manual
                columns={columns}
                data={this.data}
                defaultPageSize={this.state.pageSize}
                pages={this.pages}
                onFetchData={this.onFetchData}
                getTdProps={() => {
                    return {
                        style: {
                            overflow: 'visible'
                        }
                    }
                }}
            />
        );
    }

    protected getData(params: any): void {
        this.setState({isReady: false});

        // @ts-ignore
        this.props.registerPromise(
            AppUserService.usersDs({
                filters : extractGridFilters(params.filtered),
                sorters : extractGridSorters(params.sorted),
                page    : params.page,
                pageSize: params.pageSize,
            })
        ).then(({data, pages}) => {
            this.data = data;
            this.pages = pages;
            this.setState({isReady: true, reload: false});
            this.onReady();
        });
    }

    protected onAssignFiles(user: IUser) {
        this.onAssignFilesDelegate(user);
    }

    protected onChangePassword(user: IUser) {
        this.onChangePasswordDelegate(user);
    }

    protected onDelete(user: IUser) {
        if (window.confirm('Are you sure you want to delete this user?')) {
            this.onDeleteDelegate(user);
        }
    }

    protected onFetchData(state: any): void {
        const requestParams = {
            page    : state.page + 1,
            pageSize: state.pageSize,
            filtered: state.filtered,
            sorted  : state.sorted,
        } as IReactTableRequestParams;

        this.setState({requestParams: requestParams});
        this.getData(requestParams);
    }
}

/**
 * Prevent React setState on unmounted Component.
 *
 * @see https://github.com/hjylewis/trashable-react
 */
export default makeComponentTrashable(AppUserGrid);
