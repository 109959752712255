import React, { useState } from 'react';
import ScrollbarsCustom from 'react-scrollbars-custom';

const Scrollbar = ({ autoHide = true, children, ...props }) => {
    const [inUse, setInUse] = useState(false);
    const style = { style: autoHide && { display: inUse ? null : 'none' } };

    return <ScrollbarsCustom
        trackXProps={style}
        trackYProps={style}
        onMouseEnter={autoHide && (() => setInUse(true))}
        onMouseLeave={autoHide && (() => setInUse(false))}
        scrollDetectionThreshold={500}
        {...props}
    >{children}</ScrollbarsCustom>;
};

export default Scrollbar;
