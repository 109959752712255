import axios from 'axios';
import {IGridRequestParams} from '../Grid/IDataGrid';
import HttpRouter from '../HttpRouter';
import IOffice from './IOffice';

class OfficeService {

    createOffice(office: IOffice): Promise<any> {
        const payload = {
            id  : null,
            name: office.name
        };

        const url = HttpRouter.generate('save_office');

        return axios.post(url, {office: payload});
    }

    deleteOffice(officeId: number): Promise<any> {
        const url = HttpRouter.generate('delete_office', {id: officeId});

        return axios.delete(url);
    }

    officesDs(params: IGridRequestParams): Promise<any> {
        const url = HttpRouter.generate('office_ds');

        return axios.post(url, params);
    }

    getOffices(): Promise<any> {
        const url = HttpRouter.generate('get_all_offices');

        return axios.get(url);
    }

    updateOffice(officeId: number, office: IOffice): Promise<any> {
        const payload = {
            id  : officeId,
            name: office.name,
        };

        const url = HttpRouter.generate('save_office');

        return axios.post(url, {office: payload});
    }
}

export default new OfficeService();
