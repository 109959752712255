import classNames from 'classnames';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";
import ButtonCopy from '../ActionButtons/ButtonCopy';
import ButtonDelete from '../ActionButtons/ButtonDelete';
import ButtonDrag from '../ActionButtons/ButtonDrag';
import ButtonEdit from '../ActionButtons/ButtonEdit';
import IWidgetActionProps from './IWidgetActionProps';

const getElementClasses = (isSelected: boolean) => {
    return classNames({
        'preview-element__selectable': true,
        'preview-element--selected'  : isSelected
    });
};

export default (WrappedComponent) => {

    const DraggableInput = ({ id, index, isSelected, ...props }) => {

        const dispatch = useFormDispatch();
        const widgetActionProps = {
            widgetId: id
        } as IWidgetActionProps;

        const onEdit = () => {
            dispatch({
                type  : actionTypes.editWidget,
                params: widgetActionProps
            })
        };

        const onCopy = () => {
            dispatch({
                type  : actionTypes.copyWidget,
                params: widgetActionProps
            })
        };

        const onDelete = () => {
            dispatch({
                type  : actionTypes.deleteWidget,
                params: widgetActionProps
            })
        };

        const isButton = (props.type === 'button');
        // Don't wrap submit button in a draggable component (should be the last one)
        if (isButton) {
            return (
                <>
                    <WrappedComponent {...props} />

                    <div className={getElementClasses(isSelected)}>
                        <div className="preview-element__actions">
                            <ButtonEdit onClick={onEdit} />
                        </div>
                    </div>
                </>
            );
        }

        return (
            <Draggable draggableId={id} index={index}>
                {({ innerRef, draggableProps, dragHandleProps }) => (
                    <div {...draggableProps} ref={innerRef}>

                        <WrappedComponent {...props} />

                        <div className={getElementClasses(isSelected)}>
                            <div className="preview-element__actions">
                                <ButtonDrag dragHandleProps={dragHandleProps} />
                                <ButtonEdit onClick={onEdit} />
                                <ButtonCopy onClick={onCopy} />
                                <ButtonDelete onClick={onDelete} />
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    };

    DraggableInput.displayName = `DraggableWidgetHoc(${WrappedComponent.name || 'Component'})`;

    return DraggableInput;
};
