import React, { CSSProperties } from 'react';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

function Text(props: { style: CSSProperties, content: string }) {
    return (
        <div style={props.style}>{props.content}</div>
    );
}

const Widget = DraggableWidget(Text);

const TextWidget = (props: any) => {
    const style = {
        textAlign: getValueForProperty(props.values, 'text', 'alignment'),
        color    : getValueForProperty(props.values, 'text', 'color'),
        margin   : getValueForProperty(props.values, 'text', 'margin'),
        padding  : getValueForProperty(props.values, 'text', 'padding'),

    };

    const content = getValueForProperty(props.values, 'text', 'content');

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
            content={content}
        />
    );
};

export default TextWidget;
