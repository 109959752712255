import React, { useEffect, useState } from 'react';
import FormEditor from '../Components/FormEditor/FormEditor';
import LoadingIndicator from '../Components/LoadingIndicator';
import FormService from '../Core/Form/FormService';
import MessageHandler from '../Core/MessageHandler';

const FormEdit = (props: any) => {

    const [form, setForm] = useState({
        content : [],
        isLoaded: false,
        name    : '',
    });

    const formId = Number(props.$stateParams.id);

    const onSave = (content: any, name: string) => {

        FormService.updateForm(formId, { content: content, name: name })
                   .then(({ data }) => {
                       MessageHandler.displaySuccessMessage('The form has been updated successfully.', 5);
                   })
                   .catch(error => {
                       // if (error.response.status === 404) {
                       //     MessageHandler.displayErrorMessage('Form not found.');
                       //     Router.stateService.go('forms');
                       // }
                       // if (error.response.status === 400) {
                       //     MessageHandler.displayException(error.response.data['violations']);
                       // }
                   });
    };

    useEffect(() => {
        FormService.getForm(formId)
                   .then(({ form }) => {
                       setForm({
                           content : form.content,
                           name    : form.name,
                           isLoaded: true
                       });
                   })
                   .catch(error => {
                       // if (error.response.status === 404) {
                       //     MessageHandler.displayErrorMessage('Form not found.');
                       //     Router.stateService.go('forms');
                       // }
                   });
    }, [props.$stateParams.id]);

    return (
        <>
            {form.isLoaded
                ? <FormEditor
                    data={form.content}
                    isNew={false}
                    onSave={onSave}
                    name={form.name}
                />
                : <LoadingIndicator />
            }
        </>
    );
};

export default FormEdit;
