import EventService from 'eventservice';
import IPropertyEvent from './IPropertyEvent';
import { CHANGE_PROPERTY_VALUE } from './events';

class PropertyConfigService {

    notifyPropertyChange(propertyId: string, propertyGroup: string, value: any) {
        const event: IPropertyEvent = {
            name         : CHANGE_PROPERTY_VALUE,
            propertyId   : propertyId,
            propertyGroup: propertyGroup,
            value        : value
        };

        EventService.fire<IPropertyEvent>(CHANGE_PROPERTY_VALUE, event);
    }

    subscribe(callback) {
        EventService.on(CHANGE_PROPERTY_VALUE, callback);
    }

    unsubscribe() {
        EventService.off(CHANGE_PROPERTY_VALUE);
    }
}

export default new PropertyConfigService();
