import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from './IField';

const createMarkup = (value) => ({ __html: value });

const CheckboxField = (props: IField) => {
    return (
        <FormGroup>
            <div className="custom-control custom-checkbox">
                <Input type="checkbox" className="custom-control-input" id={props.id} name={props.name} />
                <Label
                    className="custom-control-label"
                    dangerouslySetInnerHTML={createMarkup(props.label)}
                    htmlFor={props.id}
                />
            </div>
        </FormGroup>
    );
};

export default CheckboxField;
