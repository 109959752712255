import React, { useEffect, useState } from 'react';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Label from 'reactstrap/lib/Label';
import PropertyConfigService from '../../Core/Page/PropertyConfigService';
import CssSpaceProperty from '../../Core/ValueObject/CssSpaceProperty';
import IPropertyConfigProps from './IPropertyConfigProps';

const marginOptions = [
    {
        label: 'Top',
        name : 'top',
    }, {
        label: 'Right',
        name : 'right',
    }, {
        label: 'Bottom',
        name : 'bottom',
    }, {
        label: 'Left',
        name : 'left',
    }
];

const MarginOption = (props: any) => {
    return (
        <>
            <FormGroup row>
                <Label sm={4}>
                    <span className="pl-3">{props.label}:</span>
                </Label>
                <Col sm={8}>
                    <InputGroup>
                        <Input
                            onChange={props.onChange}
                            min={0}
                            name={props.name}
                            step={5}
                            type="number"
                            value={props.value}
                        />
                        <InputGroupAddon addonType="append">px</InputGroupAddon>
                    </InputGroup>
                </Col>
            </FormGroup>
        </>
    );
};

const generateMarginProperties = (value: string) => {
    const marginProperties = value.split(' ').map(i => parseInt(i));

    return new CssSpaceProperty(marginProperties).toCss();
};

const generateShorthand = (options: object) => {
    return new CssSpaceProperty(Object.values(options)).toShorthand();
};

const Margin = (props: IPropertyConfigProps) => {
    const [state, setState] = useState(props.value);
    const paddingGroup = generateMarginProperties(state);

    const handleOnChange = (event: any) => {
        const position = event.target.name;
        const target = {};

        target[position] = parseInt(event.target.value) || 0;

        setState(generateShorthand({ ...paddingGroup, ...target }));
    };

    useEffect(() => {
        PropertyConfigService.notifyPropertyChange(props.id, props.group, state);
    }, [props.id, props.group, state]);

    return (
        <>
            <hr />
            <FormGroup row>
                <Label sm={4}>{props.label}</Label>
                <Col sm={8}>
                    <Input
                        onChange={handleOnChange}
                        name="shorthand"
                        readOnly
                        value={props.value}
                    />
                </Col>
            </FormGroup>

            {
                marginOptions.map((option, index) => (
                    <MarginOption
                        key={index}
                        label={option.label}
                        name={option.name}
                        onChange={handleOnChange}
                        value={paddingGroup[option.name]}
                    />
                ))
            }
        </>
    );
};

export default Margin;
