import React from 'react';
import {Field, Form, Formik} from 'formik';
import {ReactstrapInput} from 'reactstrap-formik';
import Button from 'reactstrap/lib/Button';
import UserService from '../../Core/AppUser/AppUserService';
import MessageHandler from '../../Core/MessageHandler';
import Yup from '../../Core/YupValidator';
import ButtonWithLoader from '../ButtonWithLoader';
import PasswordMeterInput from '../PasswordMeterInput';

const formValues = {
    password       : '',
    passwordConfirm: ''
};

const PasswordSchema = Yup.object().shape({
    password       : Yup.string()
                        .min(6, 'The field must have min. 6 characters!')
                        .required('Required')
                        .strength('The password is weak!'),
    passwordConfirm: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                        .required('Required'),
});

const PasswordForm = (props: { onCancel: Function, onSuccess: Function, userId: number }) => {
    const {onCancel, onSuccess, userId} = props;

    return (
        <Formik initialValues={formValues}
                validationSchema={PasswordSchema}
                onSubmit={(values, {setSubmitting}) => {
                    UserService.updatePassword(userId, values)
                               .then((response) => {
                                   setSubmitting(false);
                                   MessageHandler.displaySuccessMessage('The user password been updated successfully.', 5);
                                   onSuccess();
                               })
                               .catch((error) => {
                                   setSubmitting(false);
                               });
                }}
        >
            {
                (props) => {
                    const {isSubmitting} = props;

                    return (
                        <Form noValidate autoComplete="off">
                            <Field
                                type="password"
                                id="password"
                                label="Password"
                                name="password"
                                withSuggestions={false}
                                component={PasswordMeterInput}
                            />
                            <Field
                                type="password"
                                id="passwordConfirm"
                                label="Confirm password"
                                name="passwordConfirm"
                                component={ReactstrapInput}
                            />

                            <div className="form-actions d-flex align-items-center justify-content-end">
                                <Button
                                    color="link"
                                    className="text-secondary"
                                    onClick={() => onCancel()}
                                >Cancel</Button>{' '}
                                <ButtonWithLoader
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    text="Save password"
                                />
                            </div>
                        </Form>
                    );
                }
            }
        </Formik>
    );
};

export default PasswordForm;
