import has from 'lodash/has';
import map from 'lodash/map';
import React from 'react';
import ButtonWidget from './ButtonWidget';
import ContactInfoWidget from './ContactInfo';
import DividerWidget from './DividerWidget';
import FormWidget from './FormWidget';
import GoogleMapWidget from './GoogleMapWidget';
import HtmlWidget from './HtmlWidget';
import IDraggableWidgetProps from './IDraggableWidgetProps';
import ImageWidget from './ImageWidget';
import PratoWidget from './PratoWidget';
import TextWidget from './TextWidget';

interface IWidgetsListProps {
    columnIndex: number;
    sectionId: string;
    selectedWidget: string;
    widgets: any;
}

const widgetComponentMap = {
    button      : ButtonWidget,
    contactInfo : ContactInfoWidget,
    divider     : DividerWidget,
    form        : FormWidget,
    googleMap   : GoogleMapWidget,
    html        : HtmlWidget,
    image       : ImageWidget,
    prato       : PratoWidget,
    text        : TextWidget,
    // Similar with FormWidget structure, keep it as a fallback, remove later
    // existingForm: ExistingFormWidget,
};

const componentForWidgetType = (widgetType: string) => {
    if (!has(widgetComponentMap, widgetType)) {
        throw new Error(`Widget type "${widgetType}" has no associated component`);
    }

    return widgetComponentMap[widgetType];
};

const getWidgets = (widgets, selectedWidget, sectionId, columnIndex) => map(widgets, (widget, idx) => {
    const draggableWidgetProps: IDraggableWidgetProps = {
        columnIndex: columnIndex,
        id         : widget.id,
        index      : parseInt(idx),
        isSelected : selectedWidget === widget.id,
        sectionId  : sectionId
    };
    const Widget = componentForWidgetType(widget.type);

    return (
        <div key={widget.id} className="preview-element">
            <Widget
                draggableWidgetProps={draggableWidgetProps}
                values={widget.values}
            />
        </div>
    );
});

const WidgetsList = (props: IWidgetsListProps) => {
    const { columnIndex, sectionId, selectedWidget, widgets } = props;

    return (
        <>
            {
                widgets && widgets.length
                ? getWidgets(widgets, selectedWidget, sectionId, columnIndex)
                : (
                    <div className="bg-info py-3 text-center text-white">No content. Drag widgets here.</div>
                )
            }
        </>
    );
};

export default WidgetsList;
