import store from 'store';

class SessionStore {
    public get(name: string, defaultValue?: any): any {
        return !!store.get(name) ? store.get(name) : defaultValue;
    }

    public has(name: string): boolean {
        return !!store.get(name);
    }

    public remove(name: string): void {
        store.remove(name);
    }

    public set(name: string, value: any): void {
        store.set(name, value);
    }
}

const Session = new SessionStore();
export default Session;
