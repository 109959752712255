import { pushStateLocationPlugin, servicesPlugin, StateObject, Transition, UIRouterReact } from '@uirouter/react';
import AuthService from '../Security/AuthenticationService';
import EventService from 'eventservice';
import isUndefined from 'lodash/isUndefined';
import { CLOSE_SIDEBAR } from '../../Components/Sidebar/events';
import states from './states';

/**
 * Transition Hook which protects a route that requires authentication.
 *
 * This hook redirects to /login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
const firewall = {
    // Determine if the destination state's requires authentication
    criteria: {
        to: (state: StateObject) => {
            // by default all pages will require authentication
            let authRequired = true;

            if (!isUndefined(state.data) && !isUndefined(state.data.auth)) {
                return authRequired && state.data.auth;
            }

            return authRequired;
        }
    },
    // returns a redirect for the current transition to the login state
    // if the user is not currently authenticated (according to the AuthService)
    action  : (transition: Transition) => {
        let $state = transition.router.stateService;

        if (!AuthService.isAuthenticated()) {
            return $state.target('login', undefined, { location: false });
        }

        EventService.fire(CLOSE_SIDEBAR);
    }
};

// redirect from login in case user navigates to /login
const redirectLogin = {
    criteria: {
        to: (state: StateObject) => {
            return state.name === 'login' && AuthService.isAuthenticated();
        }
    },
    action  : (transition: Transition) => {
        const $state = transition.router.stateService;
        const from = transition.$from();

        return $state.target(from.name === '' ? 'pages' : from.name, undefined, { location: false });
    }
};

const Router = new UIRouterReact();
Router.plugin(servicesPlugin);
Router.plugin(pushStateLocationPlugin);

states.forEach(state => Router.stateRegistry.register(state));

Router.urlService.rules.initial({ state: 'login' });
Router.transitionService.onBefore(redirectLogin.criteria, redirectLogin.action, { priority: 10 });
Router.transitionService.onBefore(firewall.criteria, firewall.action, { priority: 10 });

export default Router;
