import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import IField from "../../FormFields/IField";
import DraggableWidget from "./DraggableWidget";
import { getValueForProperty } from "../../../Core/Form/properties";

interface IInputField extends IField {
    label: string;
    name: string;
    style: Object;
    placeholder: string;
}

const InputField = (props: IInputField) => {

    return (
        <FormGroup style={props.style}>
            <Label for={props.id}>{props.label}</Label>{props.required ? (<span>*</span>): ''}
            <Input
                type={props.type}
                id={props.id}
                placeholder={props.placeholder}
                name={props.name}
            />
        </FormGroup>
    );
};

const Widget = DraggableWidget(InputField);

const TextWidget = (props) => {

    const style = {
        color  : getValueForProperty(props.values, 'styles', 'color'),
        // margin : getValueForProperty(props.values, 'styles', 'margin'),
        // padding: getValueForProperty(props.values, 'styles', 'padding'),
    };

    const label = getValueForProperty(props.values, 'general', 'label');
    const name = getValueForProperty(props.values, 'general', 'name');
    const placeholder = getValueForProperty(props.values, 'general', 'placeholder');
    const required = getValueForProperty(props.values, 'general', 'required');

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
            placeholder={placeholder}
            label={label}
            name={name}
            required={required}
        />
    );
};

export default TextWidget;

