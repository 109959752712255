import axios from 'axios';
import HttpRouter from '../HttpRouter';

const saveNotification = (data): Promise<any> => {
    const url = HttpRouter.generate('save_notification');

    return axios.post(url, {notificationOrder: data});
};

export {saveNotification}
