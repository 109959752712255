import React, { Dispatch, useReducer } from 'react';
import { reducer } from './PageReducer';

export const defaultState = {
    forms: [],
};


const StateContext = React.createContext<any | null>(null);
const DispatchContext = React.createContext<Dispatch<any> | undefined>(undefined);

const PageProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export {
    DispatchContext,
    StateContext,
    PageProvider,
};
