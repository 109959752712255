// @ts-ignore
import EventService from 'eventservice';
import map from 'lodash/map';
import React from 'react';
import autoBind from 'react-autobind';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';
import AuthService from '../../Core/Security/AuthenticationService';
import IAuthenticationRequest from '../../Core/Security/IAuthenticationRequest';
import { FAIL_USER_AUTH } from '../../Core/Security/SecurityEvents';
import ButtonWithLoader from '../ButtonWithLoader';

export interface IApiErrorMessage {
    context: string;
    message: string;
}

class LoginForm extends React.Component<any, any> {
    state = {
        errorMessage: '',
        hasError    : false,
        loading     : false
    };

    private authData = {
        password: '',
        remember: true,
        email   : '',
    } as IAuthenticationRequest;

    constructor(props: any) {
        super(props);

        autoBind(this, 'doAuthentication', 'onPasswordChange', 'onRememberChange', 'onEmailChange');

        EventService.on(FAIL_USER_AUTH, async (errors) => {
            //TODO Reactstrap alert with html as message
            let message = '';
            map(errors, (error: IApiErrorMessage) => {
                message += error.message;
            });

            this.setState({
                errorMessage: message,
                hasError    : true,
                loading     : false,
            });
        });
    }

    componentWillUnmount() {
        EventService.off(FAIL_USER_AUTH);
    }

    render() {
        return (
            <>
                <Form className="form form--login" method="post">
                    <div className="card">
                        <div className="card-header bg-transparent">
                            <h4 className="m-0 text-center">Please login into your account.</h4>
                        </div>
                        <div className="card-body">
                            {this.state.hasError ? <Alert color={'danger'}>
                                {this.state.errorMessage}
                            </Alert> : null}
                            <FormGroup>
                                <Label for="loginEmail" className="sr-only">Email</Label>
                                <Input type="email"
                                       id="loginEmail"
                                       className="form-control"
                                       placeholder="Email"
                                       onChange={(event) => {
                                           this.onEmailChange(event.target.value);
                                       }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="loginPassword" className="sr-only">Password</Label>
                                <Input type="password"
                                       id="loginPassword"
                                       className="form-control"
                                       placeholder="Password"
                                       onChange={(event) => {
                                           this.onPasswordChange(event.target.value);
                                       }}
                                />
                            </FormGroup>
                            {/*<Row className="mt-4">*/}
                            {/*    <Col sm="6" className="mb-3">*/}
                            {/*        <CustomInput type="checkbox"*/}
                            {/*                     id="rememberMe"*/}
                            {/*                     inline*/}
                            {/*                     label="Remember me"*/}
                            {/*                     defaultChecked={true}*/}
                            {/*                     onChange={(event) => {*/}
                            {/*                         this.onRememberChange(event.target.checked);*/}
                            {/*                     }}*/}
                            {/*        />*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </div>
                        <div className="card-footer bg-transparent p-0 mt-2">
                            <ButtonWithLoader block
                                              className="form__submit"
                                              disabled={this.state.loading}
                                              isLoading={this.state.loading}
                                              onClick={this.doAuthentication}
                                              text="Login"
                            />
                        </div>
                    </div>
                </Form>
            </>
        );
    }

    private doAuthentication() {
        this.setState({
            errorMessage: '',
            hasError    : false,
            loading     : true,
        });

        AuthService.authenticate(this.authData);
    }

    private onPasswordChange(value: string) {
        this.authData['password'] = value;

        this.setSuccess();
    }

    private onRememberChange(value: boolean) {
        this.authData['remember'] = value;

        this.setSuccess();
    }

    private onEmailChange(value: string) {
        this.authData['email'] = value;

        this.setSuccess();
    }

    private setSuccess() {
        this.setState({
            errorMessage: '',
            hasError    : false
        });
    }
}

export default LoginForm;
