import React, { useState } from 'react';
import FormGrid from '../Components/Form/FormGrid';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import Router from "../Core/Router";
import { UIView } from "@uirouter/react";
import { FormProvider } from "../Core/Form/FormContext";
import FormService from "../Core/Form/FormService";
import MessageHandler from "../Core/MessageHandler";

const Form = () => {

    const [reloadGrid, setReloadGrid] = useState(false);

    const onAddForm = () => {
        Router.stateService.go('forms.create');
    };

    const onEditForm = (form) => {
        Router.stateService.go('forms.edit', {id: form.id});
    };

    const onDeleteForm = (form) => {
        FormService.deleteForm(form.id).then(({data}) => {
            //TODO Delete request doesn't give any answer
            if (data.success) {
                setReloadGrid(true);
                MessageHandler.displaySuccessMessage(data.message);
            } else {
                MessageHandler.displayErrorMessage(data.message);
            }
        });
    };

    const onReady = () => {
        setReloadGrid(true);
    };

    return (
        <FormProvider>
            <UIView>
                <>
                    <div className="content">
                        <Card>
                            <CardHeader className="card-header bg-transparent border-bottom d-flex align-items-center">
                                <h4 className="m-0">Forms</h4>
                                <Button
                                    className="ml-auto"
                                    color="info"
                                    size="sm"
                                    onClick={onAddForm}
                                >Create form</Button>
                            </CardHeader>
                            <CardBody>
                                <FormGrid
                                    onDelete={onDeleteForm}
                                    onEdit={onEditForm}
                                    onReady={onReady}
                                    reload={reloadGrid}
                                />
                            </CardBody>
                        </Card>
                    </div>
                </>
            </UIView>
        </FormProvider>
    );
};

export { Form };
