import RcColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import React from 'react';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import { actionTypes } from "../../../Core/Form/FormReducer";
import { useFormDispatch } from "../../../Core/Form/useFormContext";
import IPropertyConfigProps from './IPropertyConfigProps';

const DEBOUNCE_INTERVAL = 300; // ms

type decimalRGBA = {
    r: number,
    g: number,
    b: number,
    a: number
}
type hexRGBA = {
    r: string,
    g: string,
    b: string,
    a: string
}

/**
 * Turns an old-fashioned css hex color value into a rgb color value.
 *
 * If you specify an alpha value, you'll get a rgba() value instead.
 *
 * @param hexColor The hex value to convert. ('123456'. '#123456', ''123', '#123')
 * @param alpha An alpha value to apply. (optional) ('0.5', '0.25')
 * @return An rgb or rgba value. ('rgb(11, 22, 33)'. 'rgba(11, 22, 33, 0.5)')
 */
const hexToRgba = (hexColor, alpha): string => {
    const removeHash = (hex: string): string => (hex.charAt(0) === '#' ? hex.slice(1) : hex);
    const parseHex = (nakedHex: string): hexRGBA => {
        const isShort = nakedHex.length === 3 || nakedHex.length === 4;

        return {
            r: isShort ? `${nakedHex.slice(0, 1)}${nakedHex.slice(0, 1)}` : nakedHex.slice(0, 2),
            g: isShort ? `${nakedHex.slice(1, 2)}${nakedHex.slice(1, 2)}` : nakedHex.slice(2, 4),
            b: isShort ? `${nakedHex.slice(2, 3)}${nakedHex.slice(2, 3)}` : nakedHex.slice(4, 6),
            a: (isShort ? `${nakedHex.slice(3, 4)}${nakedHex.slice(3, 4)}` : nakedHex.slice(6, 8)) || 'ff'
        };
    };
    const isNumeric = (n: any) => !isNaN(parseFloat(n)) && isFinite(n); // eslint-disable-line no-restricted-globals, max-len
    const formatRgb = (decimalObject: decimalRGBA, parameterA: number | string): string => {
        const {r, g, b, a: parsedA} = decimalObject;
        const a = isNumeric(parameterA) ? parameterA : parsedA;

        return `rgba(${r}, ${g}, ${b}, ${a})`;
    };
    const hexToDecimals = ({r, g, b, a}: hexRGBA): decimalRGBA => ({
        r: parseInt(r, 16),
        g: parseInt(g, 16),
        b: parseInt(b, 16),
        a: +((parseInt(a, 16) / 255).toFixed(2))
    });

    const hexObject = parseHex(removeHash(hexColor));
    const decimalObject = hexToDecimals(hexObject);

    return formatRgb(decimalObject, alpha / 100);
};

const rgbaToHex = (rgbColor) => {
    const rgb = rgbColor.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
    const alpha = ((rgb && rgb[4]) || '0').trim();
    const hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) + (rgb[2] | (1 << 8)).toString(16).slice(1) + (rgb[3] | (1 << 8)).toString(16).slice(1)
        : rgbColor;

    let a;
    if (alpha !== '') {
        a = parseInt((parseFloat(alpha) * 100).toFixed(0));
    } else {
        a = 100;
    }

    return {
        alpha: a,
        color: `#${hex}`
    };
};

const ColorPicker = (props: IPropertyConfigProps) => {
    const colorInfo = rgbaToHex(props.value);

    const dispatch = useFormDispatch();

    const onChange = (colorInfo) => {
        const rgba = hexToRgba(colorInfo.color, colorInfo.alpha);

        //IT DOESN't WORK WITH THROTTLE?????
       // throttle(() => {
            dispatch({
                type  : actionTypes.propertyChange,
                params: {
                    propertyId   : props.id,
                    propertyGroup: props.group,
                    value        : rgba
                }
            })
        // }, 300, {'trailing': false});
    };

    return (
        <FormGroup className="d-flex">
            <Label>{props.label}</Label>
            <RcColorPicker
                alpha={colorInfo.alpha}
                className="ml-auto"
                color={colorInfo.color}
                onChange={onChange} />
        </FormGroup>
    );
};

export default ColorPicker;
