import React, { CSSProperties } from 'react';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

const Html = (props: { style: CSSProperties, content: string }) => {
    const createMarkup = (content) => ({ __html: content });

    return (
        <div style={props.style} dangerouslySetInnerHTML={createMarkup(props.content)} />
    );
};

const Widget = DraggableWidget(Html);

const HtmlWidget = (props) => {
    const style = {
        backgroundColor: getValueForProperty(props.values, 'general', 'bgColor'),
        color          : getValueForProperty(props.values, 'general', 'color'),
        margin         : getValueForProperty(props.values, 'general', 'margin'),
        padding        : getValueForProperty(props.values, 'general', 'padding'),
    };

    const content = getValueForProperty(props.values, 'html', 'content');

    return (
        <Widget
            {...props.draggableWidgetProps}
            style={style}
            content={content}
        />
    );
};

export default HtmlWidget;
