import React, { CSSProperties } from 'react';
import Button from 'reactstrap/lib/Button';
import { getValueForProperty } from '../../../Core/Page/properties';
import DraggableWidget from './DraggableWidget';

interface IButtonProps {
    label: string;
    buttonStyle: CSSProperties;
    containerStyle: CSSProperties;
}

const ButtonComponent = (props: IButtonProps) => {
    return (
        <div style={props.containerStyle}>
            <Button style={props.buttonStyle}>{props.label}</Button>
        </div>
    );
};

const Widget = DraggableWidget(ButtonComponent);

const ButtonWidget = (props) => {
    const buttonStyle = {
        backgroundColor: getValueForProperty(props.values, 'styles', 'color'),
        borderColor    : getValueForProperty(props.values, 'styles', 'borderColor'),
        color          : getValueForProperty(props.values, 'styles', 'textColor'),
        margin         : getValueForProperty(props.values, 'styles', 'margin'),
    };
    const containerStyle = {
        textAlign: getValueForProperty(props.values, 'styles', 'alignment'),
    };
    const label = getValueForProperty(props.values, 'general', 'label');

    return (
        <Widget
            {...props.draggableWidgetProps}
            buttonStyle={buttonStyle}
            containerStyle={containerStyle}
            label={label}
            type={'button'}
        />
    );
};

export default ButtonWidget;
