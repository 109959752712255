import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { IMenu, IMenuItem } from './IMenu';
import { IPage } from './MenuContext';

function getMenuById(menus: IMenu[], menuId: string): IMenu {
    return find(menus, { id: menuId });
}

function getMenuIndex(menus: IMenu[], menuId: string): number {
    return findIndex(menus, { id: menuId });
}

function getMenuItemById(items: IMenuItem[], menuItemId: string): IMenuItem {
    return find(items, { id: menuItemId });
}

const getMenuItemIndex = (items: IMenuItem[], menuItemId: string): number => {
    return findIndex(items, { id: menuItemId });
};

function getPageById(pages: IPage[], pageId: number): IPage {
    return find(pages, { id: pageId });
}

const reorderList = (list, startIndex, endIndex) => {
    const result = cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
};

export {
    getMenuById,
    getMenuIndex,
    getMenuItemById,
    getMenuItemIndex,
    getPageById,
    reorderList,
}
