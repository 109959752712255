// @ts-ignore
import React from 'react';
import autoBind from 'react-autobind';
import ReactTable from 'react-table';
import Button from 'reactstrap/lib/Button';
// @ts-ignore
import { TrashablePromise } from 'trashable';
// @ts-ignore
import makeComponentTrashable from 'trashable-react';
import {
    extractGridFilters,
    extractGridSorters,
    GridDefaultPageSize,
    IGridState,
    IReactTableRequestParams
} from '../../Core/Grid/IDataGrid';
import PageService from '../../Core/Page/PageService';
import Router from '../../Core/Router';

interface IPage {
    id?: number;
    title: string;
}

interface IProps {
    onReady: Function;
    reload?: boolean;
    registerPromise?: TrashablePromise
}

class PageGrid extends React.Component<IProps, IGridState> {
    protected data: Array<IPage>;
    protected onReady: Function;
    protected pages: number;

    constructor(props: any) {
        super(props);
        autoBind(this);

        this.onReady = props.onReady;
        this.pages = null;

        this.state = {
            isReady      : false,
            reload       : false,
            pageSize     : GridDefaultPageSize,
            requestParams: {
                page    : 1,
                pageSize: GridDefaultPageSize,
                filtered: null,
                sorted  : null
            },
        };
    }

    componentDidUpdate(prevProps: IProps, prevState: IGridState) {
        if (this.state.reload && !prevState.reload) {
            this.getData(this.state.requestParams);
        }
    }

    render() {
        const columns = [
            {
                Header    : 'Title',
                accessor  : 'title',
                filterable: true,
                sortable  : true,
            },
            {
                Header    : '',
                accessor  : 'actions',
                filterable: false,
                sortable  : false,
                width     : 200,
                Cell      : ({ original }) => (
                    <>
                        <Button color="link" className="pb-0 pt-0" size="sm"
                                onClick={() => {
                                    this.onEdit(original);
                                }}
                        >Edit</Button>
                    </>
                ),
            },
        ];

        return (
            <ReactTable manual
                        columns={columns}
                        data={this.data}
                        defaultPageSize={10}
                        loading={!this.state.isReady}
                        pages={this.pages}
                        onFetchData={this.onFetchData}
            />
        );
    }

    protected onEdit(page) {
        Router.stateService.go('pages.edit', { id: page.id });
    }

    protected getData(params: any): void {
        this.setState({ isReady: false });

        this.props.registerPromise(
            PageService.pagesDs({
                filters : extractGridFilters(params.filtered),
                sorters : extractGridSorters(params.sorted),
                page    : params.page,
                pageSize: params.pageSize,
            }),
        ).then(({ data, pages }) => {
            this.data = data;
            this.pages = pages;
            this.setState({ isReady: true, reload: false });
            this.onReady();
        });
    }

    protected onFetchData(state): void {

        const requestParams = {
            page    : state.page + 1,
            pageSize: state.pageSize,
            filtered: state.filtered,
            sorted  : state.sorted,
        } as IReactTableRequestParams;

        this.setState({ requestParams: requestParams });
        this.getData(requestParams);
    }
}

export default makeComponentTrashable(PageGrid);
