import React from 'react';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthService from '../../Core/Security/AuthenticationService';

const UserMenu = () => {
    const user = AuthService.getUser();
    const displayName = user && user.email;
    const onLogout = () => {
        AuthService.logout();
    };

    return (
        <>
            <UncontrolledDropdown nav inNavbar className="dropdown-user">
                <DropdownToggle className="nav-link dropdown-toggle btn-rotate" nav>
                    <FontAwesomeIcon icon={faUser} className="nav__icon" />
                    <span className="d-lg-none">{displayName}</span>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem className="d-none d-lg-block" header>{displayName}</DropdownItem>
                    <DropdownItem onClick={onLogout}>Logout</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
};

export default UserMenu;
