import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import has from 'lodash/has';
import map from 'lodash/map';
import React, { useState } from 'react';
import Collapse from 'reactstrap/lib/Collapse';
import Container from 'reactstrap/lib/Container';
import ColorPicker from "./Properties/ColorPicker";
import SelectField from "./Properties/SelectField";
import TextField from "./Properties/TextField";
import RadioField from "./Properties/RadioField";
import OptionsField from "./Properties/OptionsField";
import AlignmentPicker from "./Properties/AlignmentPicker";
import Margin from "./Properties/Margin";
import Padding from "./Properties/Padding";
import CheckboxField from "./Properties/CheckboxField";

const propertyComponentMap = {
    text           : TextField,
    select         : SelectField,
    colorPicker    : ColorPicker,
    radio          : RadioField,
    options        : OptionsField,
    alignmentPicker: AlignmentPicker,
    margin         : Margin,
    padding        : Padding,
    checkbox       : CheckboxField,

    //TODO move old components
    // apiUrl         : ApiUrl,
    // columnsNumber  : ColumnsNumber,
    // formFieldsList : FormFields,
    // imageSource    : ImageUrl,
    // range          : Range,
    // textWithIcon   : TextFieldWithIcon,
    // textarea       : TextareaField
};

const componentForPropertyType = (propertyType: string) => {
    if (!has(propertyComponentMap, propertyType)) {
        throw new Error(`Property type "${propertyType}" has no associated component`);
    }

    return propertyComponentMap[propertyType];
};

interface IPropertyGroupProps {
    groupKey: string;
    items: any;
    label: string;
    position: number;
    valueItems: any;
}

const PropertyGroup = (props: IPropertyGroupProps) => {

    const [isOpen, setIsOpen] = useState(true);
    const collapsibleIndicator = isOpen ? faChevronUp : faChevronDown;

    const getProperties = () => {
        return <>
            {
                map(props.items, (field, index) => {
                    const ComponentName = componentForPropertyType(field.type);
                    let value = has(props.valueItems, field.id) ? props.valueItems[field.id] : '';

                    return (
                        <div key={index}>
                            <ComponentName
                                {...field}
                                group={props.groupKey}
                                value={value}
                            />
                        </div>
                    );
                })
            }
        </>;
    };

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="property-group mt-2 mb-2 pb-1" id={`toggle_${props.groupKey}`}>
            <div className="property-group__label mb-2 py-1" onClick={toggle}>
                <Container fluid className="d-flex align-items-center">
                    <h5 className="m-0">{props.label}</h5>
                    <FontAwesomeIcon icon={collapsibleIndicator} className="ml-auto" />
                </Container>
            </div>
            <Collapse isOpen={isOpen}>
                <Container fluid>
                    {getProperties()}
                </Container>
            </Collapse>
        </div>
    );
};

export default PropertyGroup;
